import { ref } from "vue";
import { RESIZE_IMAGE_TIME } from "@/constants/DefaultData";
let listFilesResize = ref([]);

const resizeImage = (file, imageRatio, useArr = true) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    let image_url = event.target.result;
    let image = document.createElement("img");
    image.src = image_url;
    image.onload = (e) => {
      for (const key in imageRatio) {
        let canvas = document.createElement("canvas");
        let ratio = imageRatio[key].width / image.width;
        canvas.width = imageRatio[key].width;
        canvas.height = image.height * ratio;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        let new_image_url = context.canvas.toDataURL("image/jpg", 90);
        let index = file.name.lastIndexOf(".");
        let fileName = [
          file.name.slice(0, index),
          `_${key}`,
          file.name.slice(index),
        ].join("");
        let newFile = urlToFile(new_image_url, fileName);
        listFilesResize.value.push(newFile);
      }
    };
  };
};

const urlToFile = (url, fileName) => {
  let arr = url.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let data = arr[1];

  let dataStr = atob(data);
  let n = dataStr.length;
  let dataArr = new Uint8Array(n);

  while (n--) {
    dataArr[n] = dataStr.charCodeAt(n);
  }

  let file = new File([dataArr], fileName, { type: mime });
  return file;
};

const getImageResizeUrl = (url, size, timestamp) => {
  if (timestamp < new Date(RESIZE_IMAGE_TIME).getTime()) {
    return url;
  }
  const urlTokenIndex = url.indexOf("&token");
  let newUrl = url.slice(0, urlTokenIndex);
  const lastDotIndex = newUrl.lastIndexOf(".");
  const resizeImageUrl = [
    newUrl.slice(0, lastDotIndex),
    `_${size}`,
    newUrl.slice(lastDotIndex),
  ].join("");
  return resizeImageUrl;
};

const errorImage = (event, type, originUrl) => {
  let image = document.createElement("img");
  image.src = originUrl;
  image.onload = loadImage(event.target, originUrl, type);
};

const loadImage = (target, originUrl, type) => {
  target.src = originUrl;
  target.addEventListener("error", (e) => {
    let backupUrl;
    if (type == "cover") {
      backupUrl =
        "https://thumbs.dreamstime.com/b/travel-background-banner-items-traveling-flights-tickets-passport-money-sunglasses-colored-rest-vacation-concept-170421886.jpg";
    } else if (type == "avatar") {
      backupUrl = require("@/assets/images/user/default-avatar.png");
    } else if (type === "post") {
      backupUrl = require("@/assets/images/error/errorImage.png");
      target.classList.add("error-image");
    }
    target.src = backupUrl;
    target.onerror = null;
  });
};

const removeFile = (fileName, index) => {
  listFilesResize.value = listFilesResize.value.filter(
    (file) => !file.name.includes(fileName.slice(0, index))
  );
};

export {
  resizeImage,
  listFilesResize,
  getImageResizeUrl,
  errorImage,
  removeFile,
};
