import { requiredAuth } from "@/router/routes/router-guards";

const postChildRoutes = (prop, mode = false) => [
  {
    path: ":id",
    name: prop + ".postdetails",
    meta: { auth: true, name: "Chi tiết bài viết" },
    beforeEnter: requiredAuth,
    component: () =>
      import(
        "@General/views/SocailMain/Market/components/PostDetails/PostDetails"
      ),
  },
];

export { postChildRoutes };
