<template>
  <div class="card mb-0 px-2 py-5 position-relative">
    <div class="card-body">
      <div class="modal-image text-center pb-3">
        <img
          src="@/assets/images/icon/error-file.svg"
          alt="Error File"
          style="width: 130px"
          loading="lazy"
        />
      </div>
      <div class="modal-text text-center">
        <h2 class="text-large fw-bold mt-2 mb-1">{{ alertTitle }}</h2>
        <p class="mb-0 mt-2">
          {{ alertText }}
        </p>
      </div>
      <div
        v-if="isShowAction"
        class="remove-file position-absolute cursor-pointer"
        @click="$emit('closeModal')"
      >
        <i class="material-symbols-outlined text-small remove-icon"> close</i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isShowAction: {
        type: Boolean,
        required: false,
        default() {
          return true;
        },
      },
      alertText: {
        type: String,
        required: false,
        default: "",
      },
      alertTitle: {
        type: String,
        required: false,
        default: "Lỗi tải file",
      },
    },
  };
</script>

<style lang="scss" scoped>
  .remove-file {
    top: 1rem;
    right: 1rem;
    @include transition(0.3s);
    width: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparentize($color: $darkGray, $amount: 0.6);
    border-radius: 50%;
    color: var(--bs-white);
  }
</style>
