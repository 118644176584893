import { firebaseAuth } from "@/firebase/config";

const requiredAuth = async (to, from, next) => {
  const userData = firebaseAuth.currentUser;
  if (userData && userData.emailVerified) next();
  else next({ name: "auth.sign-in", params: {} });
};

const checkAuth = (to, from, next) => {
  const userData = firebaseAuth.currentUser;
  if (userData && userData.emailVerified)
    next({ name: "social.list", params: {} });
  else next();
};

const checkAccountTest = (to, from, next) => {
  const userData = firebaseAuth.currentUser;
  if (userData && process.env.VUE_APP_USERS_TEST.includes(userData.uid)) next();
  else next({ name: "social.list", params: {} });
};

export { requiredAuth, checkAuth, checkAccountTest };
