const fileValidation = {
  max_size: 20 * 1024 * 1024,
  mime_type: [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
    // doc type
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // xls type
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // ppt type
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    // pdf type
    "application/pdf",
  ],
};

const FILE_EXT = {
  jpg: "image/jpg",
  jpeg: "image/jpeg",
  png: "image/png",
  webp: "image/webp",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  pdf: "application/pdf",
};

export { fileValidation, FILE_EXT };
