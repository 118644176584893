<template>
  <div>
    <div class="modal-header">
      <h5 class="modal-title fw-bold text-medium">Thông báo</h5>
    </div>
    <div class="modal-body">
      <div class="form-notification">
        <div class="new-tab-image text-center">
          <img
            src="@/assets/images/icon/warning.svg"
            alt="New tab icon"
            class="avatar-100"
          />
        </div>
        <div class="content text-center mt-3">
          <h4 class="text-medium fw-bold">
            Bạn đã mở ứng dụng trên một tab khác
          </h4>
          <p class="mb-0 mt-2">
            Vui lòng chỉ sử dụng ứng dụng của chúng tôi trên 1 tab duy nhất để
            có trải nghiệm tốt nhất.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style></style>
