import { requiredAuth } from "@/router/routes/router-guards";

const groupRoutes = (prop, mode) => [
  {
    path: "",
    name: prop + ".detail",
    meta: { auth: true, name: "Hội nhóm" },
    beforeEnter: requiredAuth,
    component: () => import("@General/views/SocailMain/Group/GroupDetail"),
  },
];

export { groupRoutes };
