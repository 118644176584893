import { firebaseStore, firebaseAuth } from "@/firebase/config";
import { checkUserToken } from "@/composables/userToken/user-token";

import {
	FOLLOW_KEY,
	AGENCY_KEY,
	MEMBERS_KEY,
	METHOD_PAGE,
	limitGet,
	limitLoadMore,
} from "@General/components/custom/business_page/CreatePost/method-page";

export default {
	namespaced: true,

	state() {
		return {
			listAgency: null,
			listOrgMember: null,
			endPoint: false,
			followPosts: null,
			agencyPosts: null,
			memberPosts: null,
			listPosts: null,
			pageStatus: null,
			endPointLoadMore: false,
		};
	},
	getters: {
		lastAgency: (state) => state.listAgency[state.listAgency.length - 1],
		lastOrgMember: (state) =>
			state.listOrgMember[state.listOrgMember.length - 1],
		lastFollowPost: (state) => state.followPosts[state.followPosts.length - 1],
		lastAgencyPost: (state) => state.agencyPosts[state.agencyPosts.length - 1],
		lastMemberPost: (state) => state.memberPosts[state.memberPosts.length - 1],
		lastPost: (state) => state.listPosts[state.listPosts.length - 1],
	},

	mutations: {
		SET_PAGE_STATUS(state, payload) {
			state.pageStatus = payload;
		},
		SET_PAGE_APPROACHER(state, { agency, orgMembers }) {
			state.listAgency = agency;
			state.listOrgMember = orgMembers;
		},
		SET_END_POINT(state, status) {
			state.endPoint = status;
		},
		SET_MORE_PAGE_APPROACHER(state, { agency, orgMembers }) {
			state.listAgency = [...state.listAgency, ...agency];
			state.listOrgMember = [...state.listOrgMember, ...orgMembers];
		},
		SET_POSTS(state, { follow_posts, agency_posts, member_posts }) {
			const arrPostId = [];
			state.followPosts = follow_posts;
			state.agencyPosts = agency_posts;
			state.memberPosts = member_posts;
			state.listPosts = [
				...(state.followPosts ?? []),
				...(state.agencyPosts ?? []),
				...(state.memberPosts ?? []),
			]
				.reduce((acc, post) => {
					if (!arrPostId.includes(post.id)) {
						arrPostId.push(post.id);
						acc.push(post);
					}
					return acc;
				}, [])
				.sort((a, b) => b.timestamp - a.timestamp);
		},
		SET_MORE_POSTS(state, { follow_posts, agency_posts, member_posts }) {
			const arrPostId = [];
			state.followPosts = [...state.followPosts, ...follow_posts];
			state.agencyPosts = [...state.agencyPosts, ...agency_posts];
			state.memberPosts = [...state.memberPosts, ...member_posts];
			state.listPosts = [
				...state.followPosts,
				...state.agencyPosts,
				...state.memberPosts,
			]
				.reduce((acc, post) => {
					if (!arrPostId.includes(post.id)) {
						arrPostId.push(post.id);
						acc.push(post);
					}
					return acc;
				}, [])
				.sort((a, b) => b.timestamp - a.timestamp);
		},
		SET_END_POINT_LOAD_MORE(state, status) {
			state.endPointLoadMore = status;
		},
		LIKE_POST(state, index) {
			state.listPosts[index].likescount += 1;
		},
		UNLIKE_POST(state, index) {
			state.listPosts[index].likescount -= 1;
		},
		SET_FEED_COMPANY(state, payload) {
			state.listPosts = payload;
		},
		SET_MORE_FEED_COMPANY(state, payload) {
			state.listPosts = [...state.listPosts, ...payload];
		},
		REMOVE_STORE(state) {
			state.listAgency = null;
			state.listOrgMember = null;
			state.followPosts = null;
			state.agencyPosts = null;
			state.memberPosts = null;
			state.listPosts = null;
			state.endPointLoadMore = false;
			state.endPoint = false;
			state.owner = false;
			state.pageStatus = null;
		},
	},
	actions: {
		// get page approachers
		async getPageApproacher({ commit }, { methodPage, pageId }) {
			const listPromise = [];
			Object.keys(METHOD_PAGE)
				.filter((method) => method !== FOLLOW_KEY)
				.forEach((method) => {
					listPromise.push(METHOD_PAGE[method].get(pageId));
				});
			const [agency, orgMembers] = (await Promise.all(listPromise)).map(
				(data) =>
					data.docs.map((doc) => {
						return {
							id: doc.id,
							...doc.data(),
						};
					})
			);
			const totalResults = [...(agency ?? []), ...(orgMembers ?? [])].length;
			if (!totalResults) {
				commit("SET_END_POINT", true);
			}
			commit("SET_PAGE_APPROACHER", {
				agency: agency ?? [],
				orgMembers: orgMembers ?? [],
			});
		},
		// load more page approachers
		async loadMorePageApproacher({ commit, getters }, { methodPage, pageId }) {
			const listPromise = [];
			let lastDoc;
			Object.keys(METHOD_PAGE)
				.filter((method) => method !== FOLLOW_KEY)
				.forEach(async (method) => {
					if (method === AGENCY_KEY) {
						lastDoc = getters.lastAgency;
					} else if (method === MEMBERS_KEY) {
						lastDoc = getters.lastOrgMember;
					}
					listPromise.push(
						lastDoc
							? METHOD_PAGE[method].load_more(pageId, lastDoc)
							: Promise.resolve({ docs: [] })
					);
				});
			const [agency, orgMembers] = (await Promise.all(listPromise)).map(
				(data) => {
					if (data.docs.length) {
						return data.docs.map((doc) => {
							return {
								id: doc.id,
								...doc.data(),
							};
						});
					}
					return [];
				}
			);
			const totalResults = [...(agency ?? []), ...(orgMembers ?? [])].length;
			if (!totalResults) {
				commit("SET_END_POINT", true);
			}
			commit("SET_MORE_PAGE_APPROACHER", {
				agency: agency ?? [],
				orgMembers: orgMembers ?? [],
			});
		},
		// get posts if user is page owners
		async getPosts({ commit, state }, { methodPage, pageId }) {
			const listPromise = [];
			Object.keys(METHOD_PAGE).forEach((method) => {
				listPromise.push(
					firebaseStore
						.collection("pages")
						.doc(pageId)
						.collection(METHOD_PAGE[method].collection)
						.orderBy("timestamp", "desc")
						.limit(limitGet)
						.get()
				);
			});
			const [followPosts, agencyPosts, memberPosts] = (
				await Promise.all(listPromise)
			).map((data) =>
				data.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				})
			);
			const totalResults = [
				...(followPosts ?? []),
				...(agencyPosts ?? []),
				...(memberPosts ?? []),
			].length;
			if (!totalResults) {
				commit("SET_END_POINT_LOAD_MORE", true);
			}
			commit("SET_POSTS", {
				follow_posts: followPosts ?? [],
				agency_posts: agencyPosts ?? [],
				member_posts: memberPosts ?? [],
			});
		},

		// load more posts if user is page owners
		async loadMorePosts({ commit, getters, state }, { methodPage, pageId }) {
			const listPromise = [];
			let lastDoc;
			Object.keys(METHOD_PAGE).forEach((method) => {
				if (method === FOLLOW_KEY) {
					lastDoc = getters.lastFollowPost;
				} else if (method === AGENCY_KEY) {
					lastDoc = getters.lastAgencyPost;
				} else if (method === MEMBERS_KEY) {
					lastDoc = getters.lastMemberPost;
				}
				listPromise.push(
					lastDoc
						? firebaseStore
								.collection("pages")
								.doc(pageId)
								.collection(METHOD_PAGE[method].collection)
								.orderBy("timestamp", "desc")
								.startAfter(lastDoc.timestamp)
								.limit(limitLoadMore)
								.get()
						: Promise.resolve({ docs: [] })
				);
			});
			const [followPosts, agencyPosts, memberPosts] = (
				await Promise.all(listPromise)
			).map((data) => {
				if (data.docs.length) {
					return data.docs.map((doc) => {
						return {
							id: doc.id,
							...doc.data(),
						};
					});
				}
				return [];
			});
			const totalResults = [
				...(followPosts ?? []),
				...(agencyPosts ?? []),
				...(memberPosts ?? []),
			].length;
			if (!totalResults) {
				commit("SET_END_POINT_LOAD_MORE", true);
			}
			commit("SET_MORE_POSTS", {
				follow_posts: followPosts ?? [],
				agency_posts: agencyPosts ?? [],
				member_posts: memberPosts ?? [],
			});
		},
		// get posts if user is not page owners
		async getPostsByMethod({ commit, state }, { methodPage, pageId }) {
			const listPromise = [];
			const arrStatusActive = [];
			// get follow posts
			const queryFollowPost = firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection(METHOD_PAGE[FOLLOW_KEY].collection)
				.orderBy("timestamp", "desc")
				.limit(limitGet);
			// agency - member method
			const methodPageFilter = Object.keys(METHOD_PAGE).filter(
				(method) => method !== FOLLOW_KEY
			);
			methodPageFilter.forEach((key) => {
				listPromise.push(
					state.pageStatus[key]
						? firebaseStore
								.collection("pages")
								.doc(pageId)
								.collection(METHOD_PAGE[key].collection)
								.orderBy("timestamp", "desc")
								.limit(limitGet)
								.get()
						: Promise.resolve({ docs: [] })
				);
				// total methods is active
				if (state.pageStatus[key]) {
					arrStatusActive.push(key);
				}
			});
			// get follow posts and only agency/member public post
			if (!arrStatusActive.length) {
				const arr = [];
				methodPageFilter.forEach((method) => {
					arr.push(
						firebaseStore
							.collection("pages")
							.doc(pageId)
							.collection(METHOD_PAGE[method].collection)
							.where("isPublic", "==", true)
							.orderBy("timestamp", "desc")
							.limit(limitGet)
							.get()
					);
				});
				const [followPosts, agencyPosts, memberPosts] = (
					await Promise.all([queryFollowPost.get(), ...arr])
				).map((data) =>
					data.docs.map((doc) => {
						return {
							id: doc.id,
							...doc.data(),
						};
					})
				);
				const totalResults = [
					...(followPosts ?? []),
					...(agencyPosts ?? []),
					...(memberPosts ?? []),
				].length;
				if (!totalResults) {
					commit("SET_END_POINT_LOAD_MORE", true);
				}
				commit("SET_POSTS", {
					follow_posts: followPosts ?? [],
					agency_posts: agencyPosts ?? [],
					member_posts: memberPosts ?? [],
				});
			}
			// get follow posts and agency/member post
			else {
				const [followPosts, agencyPosts, memberPosts] = (
					await Promise.all([queryFollowPost.get(), ...listPromise])
				).map((data) =>
					data.docs.map((doc) => {
						return {
							id: doc.id,
							...doc.data(),
						};
					})
				);
				const totalResults = [
					...(followPosts ?? []),
					...(agencyPosts ?? []),
					...(memberPosts ?? []),
				].length;
				if (!totalResults) {
					commit("SET_END_POINT_LOAD_MORE", true);
				}
				commit("SET_POSTS", {
					follow_posts: followPosts ?? [],
					agency_posts: agencyPosts ?? [],
					member_posts: memberPosts ?? [],
				});
			}
		},
		// load more posts if user is not page owners
		async loadMorePostsByMethod(
			{ commit, getters, state },
			{ methodPage, pageId }
		) {
			const listPromise = [];
			let lastDoc;
			const arrStatusActive = [];
			// get follow posts
			const queryFollowPost = firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection(METHOD_PAGE[FOLLOW_KEY].collection)
				.orderBy("timestamp", "desc")
				.startAfter(getters.lastFollowPost.timestamp)
				.limit(limitLoadMore);
			// agency - member method
			const methodPageFilter = Object.keys(METHOD_PAGE).filter(
				(method) => method !== FOLLOW_KEY
			);
			methodPageFilter.forEach((key) => {
				if (key === AGENCY_KEY) {
					lastDoc = getters.lastAgencyPost;
				} else if (key === MEMBERS_KEY) {
					lastDoc = getters.lastMemberPost;
				}
				listPromise.push(
					state.pageStatus[key] && lastDoc
						? firebaseStore
								.collection("pages")
								.doc(pageId)
								.collection(METHOD_PAGE[key].collection)
								.orderBy("timestamp", "desc")
								.startAfter(lastDoc.timestamp)
								.limit(limitLoadMore)
								.get()
						: Promise.resolve({ docs: [] })
				);
				if (state.pageStatus[key]) {
					arrStatusActive.push(key);
				}
			});
			if (!arrStatusActive.length) {
				const arr = [];
				methodPageFilter.forEach((method) => {
					if (state.pageStatus[method] === AGENCY_KEY) {
						lastDoc = getters.lastAgencyPost;
					} else if (state.pageStatus[method] === MEMBERS_KEY) {
						lastDoc = getters.lastMemberPost;
					}
					arr.push(
						lastDoc
							? firebaseStore
									.collection("pages")
									.doc(pageId)
									.collection(METHOD_PAGE[method].collection)
									.where("isPublic", "==", true)
									.orderBy("timestamp", "desc")
									.startAfter(lastDoc.timestamp)
									.limit(limitGet)
									.get()
							: Promise.resolve({ docs: [] })
					);
				});
				const [followPosts, agencyPosts, memberPosts] = (
					await Promise.all([queryFollowPost.get(), ...arr])
				).map((data) => {
					if (data.docs.length) {
						return data.docs.map((doc) => {
							return {
								id: doc.id,
								...doc.data(),
							};
						});
					}
					return [];
				});
				const totalResults = [
					...(followPosts ?? []),
					...(agencyPosts ?? []),
					...(memberPosts ?? []),
				].length;
				if (!totalResults) {
					commit("SET_END_POINT_LOAD_MORE", true);
				}
				commit("SET_MORE_POSTS", {
					follow_posts: followPosts ?? [],
					agency_posts: agencyPosts ?? [],
					member_posts: memberPosts ?? [],
				});
			} else {
				const [followPosts, agencyPosts, memberPosts] = (
					await Promise.all([queryFollowPost.get(), ...listPromise])
				).map((data) => {
					if (data.docs.length) {
						return data.docs.map((doc) => {
							return {
								id: doc.id,
								...doc.data(),
							};
						});
					}
					return [];
				});
				const totalResults = [
					...(followPosts ?? []),
					...(agencyPosts ?? []),
					...(memberPosts ?? []),
				].length;
				if (!totalResults) {
					commit("SET_END_POINT_LOAD_MORE", true);
				}
				commit("SET_MORE_POSTS", {
					follow_posts: followPosts ?? [],
					agency_posts: agencyPosts ?? [],
					member_posts: memberPosts ?? [],
				});
			}
		},
		// get feed company
		async getFeedCompany({ commit, state }, pageId) {
			let posts = [];
			const queryDb = firebaseStore
				.collection("feedCompany")
				.doc(pageId)
				.collection("posts")
				.orderBy("timestamp", "desc")
				.limit(limitGet);

			const res = await queryDb.get();
			if (res.size < limitGet) {
				commit("SET_END_POINT_LOAD_MORE", true);
			}
			if (res.size) {
				posts = [
					...res.docs.map((doc) => {
						return {
							id: doc.id,
							...doc.data(),
						};
					}),
				];
			}
			commit("SET_FEED_COMPANY", posts);
		},
		// get feed company
		async loadMoreFeedCompany({ commit, state, getters }, pageId) {
			let posts = [];
			const queryDb = firebaseStore
				.collection("feedCompany")
				.doc(pageId)
				.collection("posts")
				.orderBy("timestamp", "desc")
				.startAfter(getters.lastPost.timestamp)
				.limit(limitLoadMore);

			const res = await queryDb.get();
			if (res.size < limitLoadMore) {
				commit("SET_END_POINT_LOAD_MORE", true);
			}
			if (res.size) {
				posts = [
					...res.docs.map((doc) => {
						return {
							id: doc.id,
							...doc.data(),
						};
					}),
				];
			}
			commit("SET_MORE_FEED_COMPANY", posts);
		},
	},
};
