const SLACK_URL =
  process.env.VUE_APP_ENV === "local"
    ? "https://hooks.slack.com/services/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"
    : "https://hooks.slack.com/services/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";
const IPFY_URL = "https://api.ipify.org?format=json";
import axios from "axios";
import { firebaseAuth } from "@/firebase/config";

const getErrorObject = () => {
  try {
    throw Error("");
  } catch (err) {
    return err;
  }
};

const getErrorLine = () => {
  const errorArray = getErrorObject().stack.split("\n");
  const errorLine = errorArray[errorArray.length - 1];

  return errorLine;
};

const sendErrorToSlack = async (
  type = "warning",
  error = { message: "Đã xảy ra sự cố" },
  line = "",
  user = ""
) => {
  const preText =
    type === "error"
      ? "ERROR: :sos: :sos: :sos: \n"
      : "WARNING: :warning: :warning: :warning: \n";
  const userEmail = firebaseAuth?.currentUser?.email ?? "default";
  let ip = "";
  try {
    const ipRes = await axios.get(IPFY_URL);
    ip = ipRes.data.ip;
    const message = {
      text: "ERROR:",
      blocks: [
        {
          type: "section",
          block_id: "section0",
          text: {
            type: "mrkdwn",
            text: preText + "\n" + "Message: " + error?.message,
          },
        },
        {
          type: "section",
          block_id: "section1",
          text: {
            type: "mrkdwn",
            text: "Link: " + window.location.href,
          },
        },
        {
          type: "section",
          block_id: "section2",
          text: {
            type: "mrkdwn",
            text: "File:" + line,
          },
        },
        {
          type: "section",
          block_id: "section3",
          text: {
            type: "mrkdwn",
            text: "Device: " + window.navigator.userAgent,
          },
        },
        {
          type: "section",
          block_id: "section4",
          text: {
            type: "mrkdwn",
            text: !user ? "Username: " + userEmail : "Username: " + user,
          },
        },
        {
          type: "section",
          block_id: "section5",
          text: {
            type: "mrkdwn",
            text: "IPv4: " + ip,
          },
        },
      ],
    };
    const payload = JSON.stringify(message);

    await axios.post(SLACK_URL, payload);
  } catch (error) {
    const message = {
      text: "ERROR:",
      blocks: [
        {
          type: "section",
          block_id: "section0",
          text: {
            type: "mrkdwn",
            text: preText + "\n" + "Message: " + error?.message,
          },
        },
        {
          type: "section",
          block_id: "section1",
          text: {
            type: "mrkdwn",
            text: "Link: " + window.location.href,
          },
        },
        {
          type: "section",
          block_id: "section2",
          text: {
            type: "mrkdwn",
            text: "File:" + line,
          },
        },
        {
          type: "section",
          block_id: "section3",
          text: {
            type: "mrkdwn",
            text: "Device: " + window.navigator.userAgent,
          },
        },
        {
          type: "section",
          block_id: "section4",
          text: {
            type: "mrkdwn",
            text: !user ? "Username: " + userEmail : "Username: " + user,
          },
        },
      ],
    };
    const payload = JSON.stringify(message);

    if (process.env.VUE_APP_ENV === "local") {
      return;
    }

    await axios.post(SLACK_URL, payload);
  }
};

export { sendErrorToSlack, getErrorLine };
