import { requiredAuth, checkAccountTest } from "./router-guards";
import { profileChildRoutes } from "@General/router/";

const profileRoutes = (prop, mode) => [
  ...profileChildRoutes(prop, mode),
  {
    path: "personal-profile",
    name: prop + ".personal-profile",
    meta: { auth: true, name: "Trang Cá Nhân" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Profile/PersonalProfile"),
  },
];

export default [
  // *===============================================---*
  // *--------- PROFILE -------------------------------*
  // *===============================================---*
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/layouts/Default.vue"),
    children: profileRoutes("profile"),
    beforeEnter: requiredAuth,
    redirect: { name: "profile.personal-profile" },
  },
];
