import { firebaseStore, firebaseAuth } from "@/firebase/config";

export default {
	namespaced: true,
	state() {
		return {
			post: null,
			postId: null,
		};
	},
	getters: {},
	mutations: {
		SET_POST_SHARE(state, _post) {
			state.post = _post;
		},
		SET_POST_ID(state, _id) {
			state.postId = _id;
		},
		REMOVE_POST_SHARE(state) {
			state.post = state.postId = null;
		},
		LIKE_POST(state) {
			state.post.likescount += 1;
		},
		UNLIKE_POST(state) {
			state.post.likescount -= 1;
		},
	},
	actions: {
		async getPost({ commit }, { target, id }) {
			let post = null;
			const res = await firebaseStore.collection(target).doc(id).get();
			if (!res.exists) {
				throw new Error("Post not found!");
			}
			post = {
				id: res.id,
				...res.data(),
			};
			if (firebaseAuth.currentUser) {
				if (target == "zalofeed") {
					const postContacts = await firebaseStore
						.collection("zalofeed")
						.doc(id)
						.collection("preferences")
						.doc("contacts")
						.get();
					if (postContacts) {
						post = {
							id: res.id,
							...res.data(),
							...postContacts.data(),
						};
					}
				}
			}
			commit("SET_POST_SHARE", post);
			return true;
		},
	},
};
