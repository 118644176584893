import store from "@/store/index";
import { firebaseAuth } from "@/firebase/config";
import { computed } from "vue";
import { checkUserToken } from "@/composables/userToken/user-token";
import { useRouter } from "vue-router";
import { registerPushy } from "@/registerPushy";
const errorValues = [undefined, null, true];
const user = computed(() => store.state.auth.userRef);
const userPoint = computed(() => store.state.snapshots.userPoint);

const signOut = async () => {
  store.commit("auth/REMOVE_CURRENT_USER");
  store.commit("marketSocial/REMOVE_MARKET_FEED_DATA");
  store.commit("marketSocial/REMOVE_HIDDEN_POSTS");
  store.commit("personalSocial/REMOVE_FEED_DATA");
  store.commit("business/REMOVE_BUSINESS_DATA");
  store.commit("public_share/REMOVE_POST_SHARE");
  store.commit("snapshots/REMOVE_SNAPSHOT_LISTENER");
  store.commit("profile_management/RESET_STORE");
  store.commit("search_data/RESET_STATE");
  store.commit("my_products/REMOVE_STORE");
  store.commit("business_post/REMOVE_STORE");
  store.commit("feedConfig/RESET_STATE");
  await store.dispatch("auth/signOut");
};

const getUserData = async () => {
  const router = useRouter();
  if (process.env.VUE_APP_USERS_TEST.includes(firebaseAuth.currentUser.uid)) {
    store.commit("auth/SET_ACCOUNT_TEST", true);
  }
  try {
    if (!user.value) {
      await Promise.all([
        store.dispatch("auth/getUserProfileData", firebaseAuth.currentUser.uid),
        store.dispatch("feedConfig/getFeedConfigs"),
      ]);
    }
    if (errorValues.includes(user.value.setupProfile)) {
      router.push({ name: "auth.wizard", params: {} });
    }
    await checkUserToken();
    if (!userPoint.value) {
      await store.dispatch("snapshots/getUserPoint");
    }
    // if ("Notification" in window) {
    //   Notification.requestPermission().then(function (permission) {
    //     if (permission === "denied" || permission === "default") {
    //       alert(
    //         "Vui lòng bật thông báo để có không bỏ lỡ bất kỳ thông tin quan trọng nào..."
    //       );
    //       return;
    //     }
    //     registerPushy();
    //   });
    // }
    store.commit(
      "marketSocial/SET_HIDDEN_POSTS",
      user.value.hidden_posts ?? {}
    );
    store.commit("user_activities/SET_USER_COMPANY_DATA", {
      user_key: user.value.id,
      user_company_key: user.value.companyId,
      user_company_type: user.value.categoryId,
    });
  } catch (error) {
    console.log(error);
  }
};

export { signOut, getUserData, user };
