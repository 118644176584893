//IMPRESSIONS
const IMPRESSION_TYPE_COMPANY_IN_AD_POSTS = 1;
const IMPRESSION_TYPE_POST_IN_AD_POSTS = 3;

// ACTIONS
const ACTION_VIEW_POST = "default";
const ACTION_VIEW_IMAGE = "image";
const ACTION_WATCH_VIDEO = "video";
const ACTION_OPEN_CONTACT = "contact";
const ACTION_SAVE_POST = "bookmark";
const ACTION_VERIFICATION = "verification";
const ACTION_SHARE = "share";
const ACTION_DOWNLOAD_PRIVATE = "download_private_file";
const ACTION_DOWNLOAD_PUBLIC = "download_public_file";

const USER_VIEW_CONTACT = "view_post_contacts";
const USER_SHARE_POST = "share_post";
const USER_REUP_POST = "reup_post";
// TYPES
const VIEWS_TYPE = "views";
const INTERACTIONS_TYPE = "interactions";

const viewType = [ACTION_VIEW_POST, ACTION_VIEW_IMAGE, ACTION_WATCH_VIDEO];
const interactionType = [
  ACTION_OPEN_CONTACT,
  ACTION_SAVE_POST,
  ACTION_VERIFICATION,
  ACTION_SHARE,
  ACTION_DOWNLOAD_PRIVATE,
  ACTION_DOWNLOAD_PUBLIC,
];
// GET TYPE BY ACTION
const getActionType = (action) => {
  if (viewType.includes(action)) {
    return VIEWS_TYPE;
  }
  if (interactionType.includes(action)) {
    return INTERACTIONS_TYPE;
  }
};

export {
  ACTION_VIEW_POST,
  ACTION_VIEW_IMAGE,
  ACTION_WATCH_VIDEO,
  ACTION_OPEN_CONTACT,
  ACTION_SAVE_POST,
  ACTION_VERIFICATION,
  ACTION_SHARE,
  ACTION_DOWNLOAD_PRIVATE,
  ACTION_DOWNLOAD_PUBLIC,
  VIEWS_TYPE,
  INTERACTIONS_TYPE,
  USER_VIEW_CONTACT,
  USER_SHARE_POST,
  USER_REUP_POST,
  getActionType,
  IMPRESSION_TYPE_COMPANY_IN_AD_POSTS,
  IMPRESSION_TYPE_POST_IN_AD_POSTS,
};
