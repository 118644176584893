import { requiredAuth } from "@/router/routes/router-guards";

const b2bTransactionRoutes = (prop, mode = false) => [
    // Request Seller
    {
        path: "seller",
        name: prop + ".seller",
        meta: {
            auth: true,
            name: "Giao dịch bán",
            breadcrumb: [{ name: "Chợ B2B" }, { name: "Giao dịch bán" }],
        },
        beforeEnter: requiredAuth,
        component: () => import("@B2B/views/RequestSeller"),
    },
    // Request seller - chat
    {
        path: "seller/product/:productId/chat",
        name: prop + ".seller_product",
        meta: {
            auth: true,
            name: "Trao đổi",
            breadcrumb: [{ name: "Chợ B2B" }, { name: "Trao đổi" }],
        },
        beforeEnter: requiredAuth,
        redirect: { name: "b2b_transactions.seller_product_all" },
        children: [
            {
                path: "",
                name: prop + ".seller_product_all",
                meta: {
                    auth: true,
                    name: "Giao dịch bán",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch bán" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestSeller/ListChat/ListAll"
                    ),
            },
            {
                path: "waiting",
                name: prop + ".seller_product_waiting",
                meta: {
                    auth: true,
                    name: "Chưa báo giá",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch bán" },
                        { name: "Chưa báo giá" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestSeller/ListChat/ListWaiting"
                    ),
            },
            {
                path: "process",
                name: prop + ".seller_product_process",
                meta: {
                    auth: true,
                    name: "Đã báo giá",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch bán" },
                        { name: "Đã báo giá" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestSeller/ListChat/ListProcess"
                    ),
            },
            {
                path: "confirmed",
                name: prop + ".seller_product_confirmed",
                meta: {
                    auth: true,
                    name: "Đã xác nhận",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch bán" },
                        { name: "Đã xác nhận" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestSeller/ListChat/ListConfirmed"
                    ),
            },
            {
                path: "canceled",
                name: prop + ".seller_product_canceled",
                meta: {
                    auth: true,
                    name: "Đã hủy bỏ",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch bán" },
                        { name: "Đã hủy bỏ" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestSeller/ListChat/ListCanceled"
                    ),
            },
            {
                path: "expired",
                name: prop + ".seller_product_expired",
                meta: {
                    auth: true,
                    name: "Đã hết hạn",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch bán" },
                        { name: "Đã hết hạn" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestSeller/ListChat/ListExpired"
                    ),
            },
        ],
        component: () =>
            import("@B2B/views/Requests/RequestSeller/ListChat/Container"),
    },
    // Request seller - chat detail
    {
        path: "seller/product/:productId/chat/:chatId",
        name: prop + ".seller_chat_detail",
        meta: {
            auth: true,
            name: "Chat",
            breadcrumb: [
                { name: "Chợ B2B" },
                { name: "Giao dịch bán" },
                { name: "Chat" },
            ],
        },
        beforeEnter: requiredAuth,
        component: () =>
            import("@B2B/views/Requests/RequestSeller/Chat/ChatContainer"),
    },
    // Request Buyer
    {
        path: "buyer",
        name: prop + ".buyer",
        meta: {
            auth: true,
            name: "Giao dịch mua",
            breadcrumb: [{ name: "Chợ B2B" }, { name: "Giao dịch mua" }],
        },
        redirect: { name: "b2b_transactions.buyer_all" },
        children: [
            {
                path: "",
                name: prop + ".buyer_all",
                meta: {
                    auth: true,
                    name: "Giao dịch mua",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch mua" },
                    ],
                },
                component: () =>
                    import("@B2B/views/Requests/RequestBuyer/ListChat/ListAll"),
            },
            {
                path: "waiting",
                name: prop + ".buyer_waiting",
                meta: {
                    auth: true,
                    name: "Chưa báo giá",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch mua" },
                        { name: "Chưa báo giá" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestBuyer/ListChat/ListWaiting"
                    ),
            },
            {
                path: "process",
                name: prop + ".buyer_process",
                meta: {
                    auth: true,
                    name: "Đã báo giá",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch mua" },
                        { name: "Đã báo giá" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestBuyer/ListChat/ListProcess"
                    ),
            },
            {
                path: "confirmed",
                name: prop + ".buyer_confirmed",
                meta: {
                    auth: true,
                    name: "Đã xác nhận",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch mua" },
                        { name: "Đã xác nhận" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestBuyer/ListChat/ListConfirmed"
                    ),
            },
            {
                path: "canceled",
                name: prop + ".buyer_canceled",
                meta: {
                    auth: true,
                    name: "Đã hủy bỏ",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch mua" },
                        { name: "Đã hủy bỏ" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestBuyer/ListChat/ListCanceled"
                    ),
            },
            {
                path: "expired",
                name: prop + ".buyer_expired",
                meta: {
                    auth: true,
                    name: "Đã hết hạn",
                    breadcrumb: [
                        { name: "Chợ B2B" },
                        { name: "Giao dịch mua" },
                        { name: "Đã hết hạn" },
                    ],
                },
                component: () =>
                    import(
                        "@B2B/views/Requests/RequestBuyer/ListChat/ListExpired"
                    ),
            },
        ],
        beforeEnter: requiredAuth,
        component: () => import("@B2B/views/RequestBuyer"),
    },
    // Request buyer - chat detail
    {
        path: "buyer/chat/:chatId",
        name: prop + ".buyer_chat_detail",
        meta: {
            auth: true,
            name: "Chat",
            breadcrumb: [
                { name: "Chợ B2B" },
                { name: "Giao dịch mua" },
                { name: "Chat" },
            ],
        },
        beforeEnter: requiredAuth,
        component: () =>
            import("@B2B/views/Requests/RequestBuyer/Chat/ChatContainer"),
    },
];

export default [
    // *===============================================---*
    // *--------- B2B TRANSACTION -------------------------------*
    // *===============================================---*
    {
        path: "/b2b/transactions",
        name: "b2b/transactions",
        beforeEnter: requiredAuth,
        component: () => import("@/layouts/Default"),
        children: b2bTransactionRoutes("b2b_transactions"),
    },
];
