import { firebaseAuth, firebaseStore, timestamp } from "@/firebase/config";
import { checkUserToken } from "@/composables/userToken/user-token";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";

export default {
  namespaced: true,

  state() {
    return {
      userBusinessProfile: null,
      businessProfile: null,
      businessProfileDetail: null,
      listRecommends: null,
      listCategoryRecommends: null,
    };
  },
  getters: {
    lastRecommend: (state) =>
      state.listRecommends[state.listRecommends.length - 1],
  },
  mutations: {
    // get list business
    SET_BUSINESS_PROFILE(state, listBusinessProfile) {
      state.businessProfile = listBusinessProfile;
    },
    // get user business profile only
    SET_USER_BUSINESS(state, profileDetail) {
      state.userBusinessProfile = profileDetail;
    },
    //  get business profile
    SET_BUSINESS_PROFILE_DETAIL(state, profileDetail) {
      state.businessProfileDetail = profileDetail;
    },
    SET_PAGE_RECOMMENDS(state, _recommends) {
      state.listRecommends = _recommends;
    },
    SET_LIST_CATEGORY_RECOMMENDS(state, _list) {
      state.listCategoryRecommends = Object.assign(
        state.listCategoryRecommends ? state.listCategoryRecommends : {},
        _list
      );
    },
    REMOVE_BUSINESS_DATA(state) {
      state.businessProfile =
        state.businessProfileDetail =
        state.listRecommends =
        state.listCategoryRecommends =
        state.userBusinessProfile =
          null;
      state.endPoint = false;
    },
  },
  actions: {
    async getBusinessProfile({ commit }) {
      await checkUserToken();
      let businssPage = [];
      const res = await firebaseStore
        .collection("pages")
        .orderBy("timestamp", "desc")
        .get();
      if (res) {
        businssPage = [
          ...res.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
        ];
        commit("SET_BUSINESS_PROFILE", businssPage);
      }
    },
    async getBusinessProfileDetails({ commit }, pageId) {
      await checkUserToken();
      let businessPageDetail;
      const res = await firebaseStore.collection("pages").doc(pageId).get();
      if (!res.exists) {
        commit("SET_BUSINESS_PROFILE_DETAIL", null);
      } else {
        // const pageStatics = await firebaseStore
        //   .collection("pages")
        //   .doc(pageId)
        //   .collection("preferences")
        //   .doc("statics")
        //   .get();
        businessPageDetail = {
          id: res.id,
          ...res.data(),
        };
        commit("SET_BUSINESS_PROFILE_DETAIL", businessPageDetail);
      }
    },
    async getUserBusinessProfile({ commit }, pageId) {
      await checkUserToken();
      let businessPageDetail;
      const res = await firebaseStore.collection("pages").doc(pageId).get();
      if (!res.exists) {
        commit("SET_BUSINESS_PROFILE_DETAIL", null);
      } else {
        businessPageDetail = {
          id: res.id,
          ...res.data(),
        };
        commit("SET_USER_BUSINESS", businessPageDetail);
      }
    },
    async filterBusinessNumber({ commit }, { searchValue }) {
      await checkUserToken();
      let pages = [];
      const response = await firebaseStore
        .collection("pages")
        .where("businessNumber", ">=", searchValue)
        .get();

      if (response) {
        pages = [
          ...response.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
        ];

        commit("SET_BUSINESS_PROFILE", pages);
      }
    },
    async filterBusinessName({ commit }, { searchValue }) {
      await checkUserToken();
      let pages = [];
      const res = await firebaseStore
        .collection("pages")
        .where(`companyName`, ">=", searchValue)
        .get();
      if (res) {
        pages = [
          ...res.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
        ];
        commit("SET_BUSINESS_PROFILE", pages);
      }
    },
    async updatePage({ commit }, option) {
      await checkUserToken();
      const userDoc = firebaseStore.collection("userRef");
      const userDocPage = userDoc
        .doc(option.data.ownerId)
        .collection("pages")
        .doc(option.data.pageId);
      if (option.action === "update-photo") {
        if (option.data.cover) {
          await userDocPage.update({
            coverUrl: option.data.cover,
          });
        }
        if (option.data.avatar) {
          await userDocPage.update({
            logoUrl: option.data.avatar,
          });
        }
      } else if (option.action === "update-bio") {
        await userDocPage.update({ bio: option.data.bio });
      } else if (option.action === "update-contact-info") {
        await userDocPage.update(option.data.info);
      } else if (option.action === "update-social-link") {
        await userDocPage.update({ linkSocial: option.data.socialLinks });
      } else if (option.action === "update-legal-info") {
        await userDocPage.update(option.data.info);
      } else if (option.action === "update-page-name") {
        await userDocPage.set(
          { pageName: option.data.pageName },
          { merge: true }
        );
      }
    },
    async recommendPage({ commit }, pageId) {
      await checkUserToken();
      await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("setRecommend")
        .doc(pageId)
        .set({
          pageId: pageId,
          created: timestamp(),
        });
    },
    duplicateItemsCounter({ commit }, { arr, key }) {
      const arrKey = arr.map((item) => item[key]);
      let counter = arrKey.reduce((acc, item) => {
        if (!acc[item]) {
          acc[item] = 1;
        } else {
          acc[item]++;
        }
        return acc;
      }, {});
      commit("SET_LIST_CATEGORY_RECOMMENDS", counter);
    },
    async getPageRecommends({ commit, dispatch }, pageId) {
      await checkUserToken();
      let listRecommends = [];
      const pageRecommends = await firebaseStore
        .collection("pages")
        .doc(pageId)
        .collection("recommends")
        .orderBy("createTime", "desc")
        .get();
      if (pageRecommends.size > 0) {
        listRecommends = [
          ...pageRecommends.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
        ];
      }
      commit("SET_PAGE_RECOMMENDS", listRecommends);
      dispatch("duplicateItemsCounter", {
        arr: listRecommends,
        key: "pageCategoryId",
      });
    },
    async getUserPageSetRecommend({ commit }, pageId) {
      await checkUserToken();
      try {
        const res = await firebaseStore
          .collection("userRef")
          .doc(firebaseAuth.currentUser.uid)
          .collection("setRecommend")
          .doc(pageId)
          .get();
        if (res.exists) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
        return false;
      }
    },
  },
};
