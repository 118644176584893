import { useRouter } from "vue-router";
import { firebaseAuth } from "@/firebase/config";
import { signOut } from "./useStore";

function activityWatcher(isShowTimeOutModal) {
  const router = useRouter();
  //The number of seconds that have passed
  //since the user was active.
  let secondsSinceLastActivity = 0;

  //Five minutes. 60 x 5 = 300 seconds.
  const MAX_INACTIVITY = 2 * 60 * 60;

  //Setup the setInterval method to run
  //every second. 1000 milliseconds = 1 second.
  firebaseAuth.onAuthStateChanged(async (user) => {
    if (!user) {
      return;
    }
    let timeCounter = setInterval(async function () {
      secondsSinceLastActivity++;
      if (secondsSinceLastActivity > MAX_INACTIVITY) {
        clearInterval(timeCounter);
        user.value = null;
        await signOut();
        isShowTimeOutModal.value = true;
        router.push({ name: "auth.sign-in" });
      }
    }, 1000);
  });

  //The function that will be called whenever a user is active
  function activity() {
    //reset the secondsSinceLastActivity variable
    //back to 0
    secondsSinceLastActivity = 0;
  }

  //An array of DOM events that should be interpreted as
  //user activity.
  var activityEvents = [
    "mousedown",
    "mousemove",
    "keydown",
    "scroll",
    "touchstart",
  ];

  //add these events to the document.
  //register the activity function as the listener parameter.
  activityEvents.forEach(function (eventName) {
    document.addEventListener(eventName, activity, true);
  });
}

export { activityWatcher };
