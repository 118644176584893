import { requiredAuth } from "./router-guards";
import { groupChildRoutes } from "@General/router/";

const groupRoutes = (prop, mode) => [...groupChildRoutes(prop, mode)];

export default [
  // *===============================================---*
  // *--------- GROUP -------------------------------*
  // *===============================================---*
  {
    path: "/group",
    name: "group",
    beforeEnter: requiredAuth,
    component: () => import("@/layouts/Default"),
    children: groupRoutes("group"),
  },
];
