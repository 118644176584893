import { firebaseAuth, firebaseStore, timestamp } from "@/firebase/config";
import { checkAssetType } from "@/constants/SetupProfile";
import { ORGANIZATIONs_ASSOCIATIONS_KEY } from "@/constants/SetupProfile";
import { v4 as uuid } from "uuid";
import { createRandomString } from "@/composables/helper/helper";
import { uploadMultipleFiles } from "@General/composables/uploads/useUpload";

export default {
	namespaced: true,
	state() {
		return {
			rolePicker: null,
			action: null,
			businessNumber: null,
			legalInfo: null,
			accommodationInfo: null,
			restaurantInfo: null,
			personalInfo: null,
			listImages: null,
			page: null,
			pageId: null,
			userHasPage: null,
		};
	},
	getters: {},
	mutations: {
		SET_ROLE(state, role) {
			state.rolePicker = role;
		},
		SET_ACTION(state, { action, businessNumber }) {
			state.action = action;
			state.businessNumber = businessNumber;
		},
		SET_LEGAL_INFO(state, info) {
			state.legalInfo = info;
		},
		SET_ACCOMMODATION_INFO(state, info) {
			state.accommodationInfo = info;
		},
		SET_PERSONAL_INFO(state, info) {
			state.personalInfo = info;
		},
		SET_RESTAURANT_INFO(state, info) {
			state.restaurantInfo = info;
		},
		UPDATE_BUSINESS_NUMBER(state, _bNumber) {
			state.businessNumber = _bNumber;
		},
		RESET_STORE(state) {
			state.rolePicker = null;
			state.action = null;
			state.businessNumber = null;
			state.legalInfo = null;
			state.accommodationInfo = null;
			state.restaurantInfo = null;
			state.personalInfo = null;
			state.page = null;
			state.pageId = null;
			state.userHasPage = null;
			state.listImages = null;
		},
		SET_PAGE_DATA(state, _page) {
			state.page = _page;
		},
		SET_PAGE_ID(state, _pageId) {
			state.pageId = _pageId;
		},
		SET_USER_PAGE_STATUS(state, status) {
			state.userHasPage = status;
		},
		SET_IMAGES_UPLOAD(state, payload) {
			state.listImages = [...payload];
		},
	},
	actions: {
		async createNewBusinessPage({ state, commit }, pageLimit) {
			let userInfo, userContactsInfo;
			// user doc
			const userQuery = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid);
			// user docPage
			const userDocPage = await userQuery.collection("pages").get();
			const userTotalPages = userDocPage.size;
			if (userTotalPages >= pageLimit) {
				return false;
			}
			// update user contacts info
			await userQuery.collection("preferences").doc("contacts").update({
				email: state.personalInfo.email,
				phone: state.personalInfo.phone,
			});
			// get user info
			const userRef = await userQuery.get();
			// get user contacts info
			const userContacts = await userQuery
				.collection("preferences")
				.doc("contacts")
				.get();
			// set user info
			userInfo = userRef.data();
			userContactsInfo = userContacts.data();
			// create new page
			if (state.action === "create-new") {
				let listImagesSrc = [];
				const pageId = uuid() + createRandomString(3);
				if (Array.isArray(state.listImages) && state.listImages.length) {
					listImagesSrc = await uploadMultipleFiles(
						state.listImages,
						"pages/photos",
						pageId
					);
				}
				const newPage = {
					pageId: pageId,
					// page name
					pageName: state.legalInfo.pageName,
					// business info
					businessNumber: state.businessNumber,
					established: state.legalInfo.dateOfEstablishment,
					// owner info
					ownerId: userInfo.id,
					ownerName: userInfo.displayName
						? userInfo.displayName
						: state.personalInfo.name,
					ownerJob: userInfo.jobTitle
						? userInfo.jobTitle
						: state.personalInfo.jobTitle,
					ownerPhoto: userInfo.photoUrl,
					shortName: state.legalInfo.shortName,
					// category
					categoryId: state.legalInfo.categoryId,
					categoryName: state.legalInfo.categoryName,
					// company location info
					companyName: state.legalInfo.businessName,
					companyEngName: state.legalInfo.englishName,
					companyAddress: state.legalInfo.companyAddress,
					companyAddressId: state.legalInfo.companyAddressId,
					companyAddressText: state.legalInfo.companyAddressText,
					companyLocationGeopoint: state.legalInfo.companyLocationGeopoint,
					linkSocial: {},
					slides: [...listImagesSrc],
					// contact info
					contactName: state.personalInfo.name,
					contactTitle: checkAssetType(state.personalInfo.jobTitle),
					contactAddress: "",
					contactPhone: userContactsInfo.phone
						? userContactsInfo.phone
						: state.personalInfo.phone,
					contactEmail: userContactsInfo.email
						? userContactsInfo.email
						: state.personalInfo.email,
					...(state.accommodationInfo ? state.accommodationInfo : {}),
					...(state.restaurantInfo ? state.restaurantInfo : {}),
					timestamp: timestamp(),
					createTime: timestamp(),
					updateTime: new Date().getTime(),
					receiver_id: 0,
					reupdate: false,
					verified: false,
				};

				const listPromise = [
					userQuery
						.collection("pages")
						.doc(pageId)
						.set(newPage, { merge: true }),
				];
				if (state.rolePicker === ORGANIZATIONs_ASSOCIATIONS_KEY) {
					listPromise.push(
						await firebaseStore
							.collection("pendingPages")
							.doc(pageId)
							.set(newPage, { merge: true })
					);
				}
				await Promise.all(listPromise);
			}
		},
		async checkUserPage({ commit }) {
			// user doc
			const userQuery = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid);
			// user docPage
			const userDocPage = await userQuery.collection("pages").get();
			const userTotalPages = userDocPage.size;
			if (userTotalPages > 0) {
				commit("SET_USER_PAGE_STATUS", true);
			} else {
				commit("SET_USER_PAGE_STATUS", false);
			}
		},
		async getPage({ commit, dispatch }) {
			let page;
			// user doc
			const userQuery = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid);
			const res = await userQuery
				.collection("pages")
				.orderBy("timestamp", "asc")
				.limit(1)
				.get();
			if (res) {
				page = res.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				})[0];
				if (page) {
					const pageDetails = await firebaseStore
						.collection("pages")
						.doc(page.id)
						.get();
					page = {
						...page,
						...(pageDetails ? pageDetails.data() : {}),
					};

					commit("SET_PAGE_DATA", page);
				}
			} else {
				return false;
			}
		},
		async removeUserPage({ commit }, pageId) {
			await firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("pages")
				.doc(pageId)
				.delete();
		},
	},
};
