export default {
  getAdsPost: (state) => {
    if (!state.ads_post.data) {
      return {};
    } //end if

    return state.ads_post.data;
  },
  getProcessState: (state) => {
    return state.ads_post.status;
  },
};
