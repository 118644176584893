import profile_management from "@/app_modules/General/store/profile_management/profile_management";

export default {
  namespaced: true,
  state() {
    return {
      ...profile_management.state(),
    };
  },
  getters: { ...profile_management.getters },
  mutations: {
    ...profile_management.mutations,
  },
  actions: {
    ...profile_management.actions,
  },
};
