import { upload_progress } from "@General/store/";

export default {
  namespaced: true,
  state() {
    return {
      ...upload_progress.state(),
    };
  },
  getters: {
    ...upload_progress.getters,
  },
  mutations: {
    ...upload_progress.mutations,
  },
};
