import { createRouter, createWebHistory } from "vue-router";
import "@/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/vendor/line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "@/assets/vendor/remixicon/fonts/remixicon.css";
import { listFilesResize } from "@/composables/helper/resizeImage";
// *===============================================---*
// *--------- ROUTES CHILD -------------------------------*
// *===============================================---*
import social from "./routes/social";
import authPages from "./routes/auth-pages";
import pages from "./routes/pages";
import market from "./routes/market";
import forms from "./routes/forms";
import user from "./routes/user";
import profile from "./routes/profile";
import manage from "./routes/manage";
import settings from "./routes/settings";
import _public from "./routes/public";
import group from "./routes/group";
import post from "./routes/post";
import guide from "./routes/guide";
import ota_management from "./routes/ota_management";
import { bidding, b2b_market, b2b_transaction } from "@B2B/router/";
// *===============================================---*
// *--------- ROUTES -------------------------------*
// *===============================================---*
const routes = [
  ...social,
  ...authPages,
  ...pages,
  ...market,
  ...forms,
  ...user,
  ...profile,
  ...settings,
  ...manage,
  ...post,
  ..._public,
  ...group,
  ...guide,
  ...ota_management,
  ...bidding,
  ...b2b_market,
  ...b2b_transaction,
  {
    path: "/:pathMatch(.*)*",
    name: "errorPage",
    meta: {
      name: "404 Not Found",
    },
    component: () => import("@/views/Pages/Error404"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});
// *===============================================---*
// *--------- SET PAGE TITLE BY ROUTER NAME -------------------------------*
// *===============================================---*
router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE}  -  ${
    to.meta.name[0].toUpperCase() + to.meta.name.substring(1)
  }`;
  listFilesResize.value = [];
  next();
  window.scrollTo({ top: 0 });
});

export default router;
