import { requiredAuth } from "./router-guards";
const pagesChildRoutes = (prop, mode = false) => [
  {
    path: "error/:code",
    name: prop + ".error",
    meta: { name: "Page Not Found" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/ErrorPage"),
  },
  {
    path: "error404",
    name: prop + ".error404",
    meta: { name: "Page Not Found" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/Error404"),
  },
  {
    path: "error500",
    name: prop + ".error500",
    meta: { name: "Error Page " },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/Error500"),
  },
  {
    path: "coming-soon",
    name: prop + ".coming-soon",
    meta: { name: "Coming Soon" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/ComingSoon"),
  },
  {
    path: "maintenance",
    name: prop + ".maintenance",
    meta: { name: "Maintenance" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/Maintenance"),
  },
];
export default [
  // *===============================================---*
  // *--------- PAGES -------------------------------*
  // *===============================================---*
  {
    path: "/pages",
    name: "pages",
    component: () => import("@/layouts/Empty"),
    children: pagesChildRoutes("default"),
    beforeEnter: requiredAuth,
  },
];
