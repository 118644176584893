import { checkAccountTest } from "@/router/routes/router-guards";

const otaChildRoute = (prop, mode = false) => [
  {
    path: "requests_received",
    beforeEnter: checkAccountTest,
    name: prop + ".requests_received",
    meta: {
      auth: true,
      name: "Yêu cầu gửi đến",
      breadcrumb: [
        { name: "Quản lý kênh phân phối" },
        { name: "Yêu cầu gửi đến" },
      ],
    },
    component: () =>
      import(
        "@General/views/ota_management/requests/requests_received/RequestReceived"
      ),
  },
  {
    path: "orders",
    beforeEnter: checkAccountTest,
    name: prop + ".orders",
    meta: {
      auth: true,
      name: "Quản lý đơn hàng",
      breadcrumb: [
        { name: "Quản lý kênh phân phối" },
        { name: "Quản lý đơn hàng" },
      ],
    },
    component: () =>
      import("@General/views/ota_management/orders/OrderManagement"),
  },
];

export { otaChildRoute };
