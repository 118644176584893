import { requiredAuth } from "@/router/routes/router-guards";

const marketChildRoutes = (prop, mode = false) => [
	{
		path: "social",
		name: prop + ".ads",
		meta: {
			auth: true,
			name: "Tin rao vặt",
			breadcrumb: [{ name: "Mua bán" }, { name: "Tin rao vặt" }],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/SocailMain/Market/SocialMarket"),
	},
];

export { marketChildRoutes };
