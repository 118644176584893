import { checkAuth, requiredAuth } from "./router-guards";
const blankchildRoutes = (prop, mode) => [
  {
    path: "signin",
    name: prop + ".sign-in",
    meta: {
      name: "Đăng Nhập",
    },
    beforeEnter: checkAuth,
    component: () => import("@/views/AuthPages/LoginPage/SignIn"),
  },
  {
    path: "signup",
    name: prop + ".sign-up",
    meta: {
      name: "Đăng Ký",
    },
    component: () => import("@/views/AuthPages/RegisterPage/SignUp"),
  },
  {
    path: "recoverpassword",
    name: prop + ".recoverpassword1",
    meta: {
      name: "Recover Password",
    },
    beforeEnter: checkAuth,
    component: () => import("@/views/AuthPages/Default/RecoverPassword"),
  },
  {
    path: "lockscreen",
    name: prop + ".lockscreen1",
    meta: {
      name: "Lock Screen",
    },
    beforeEnter: checkAuth,
    component: () => import("@/views/AuthPages/Default/LockScreen"),
  },
  {
    path: "welcome",
    name: prop + ".wizard",
    meta: {
      name: "Cập nhật thông tin hồ sơ",
    },
    beforeEnter: requiredAuth,
    component: () => import("@/views/AuthPages/Default/FormWizard"),
  },
];

export default [
  // *===============================================---*
  // *--------- AUTH -------------------------------*
  // *===============================================---*
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/layouts/Empty"),
    children: blankchildRoutes("auth"),
  },
];
