<template>
  <div class="timeout-modal p-4">
    <div class="timeout-modal--header text-center">
      <h5 class="timeout-modal--header--title fw-bold text-medium">Phiên đăng nhập của bạn đã hết hạn</h5>
    </div>
    <div class="timeout-modal--body">
      <div class="form-notification">
        <div class="content text-center mt-3">
          <p class="mb-0 mt-2">
            Vui lòng đăng nhập lại để làm mới phiên đăng nhập của bạn.
          </p>
        </div>
      </div>
    </div>
    <div class="timeout-modal--footer pt-2 d-flex justify-content-center">
      <button class="btn" @click="closeModal">OK</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ModalTimeOut",
    emits: ['closeTimeOutModal'],
    setup(props, { emit }) {
      const closeModal = () => {
        emit('closeTimeOutModal');
      }

      return { closeModal }
    }
  }
</script>

<style scoped lang="scss">
  .timeout-modal {
    & button {
      border: 1px solid $darkGray;
      background: $darkGray;
      color: #ffffff;

      &:hover, &:focus {
        color: $darkGray;
        background: #ffffff;
      }
    }
  }
</style>