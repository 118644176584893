/* eslint-disable no-console */

import { register } from "register-service-worker";

const appVersion = process.env.VUE_APP_VERSION;
// const appEnv = process.env.VUE_APP_ENV;
const useFCM = true;

const logBadgeName = "%c ⚙ TC APP ";
const logBadgeColor =
  "background: #222; color: #bada55; border-radius: 4px; padding: 2px";
let msg = "";

const serviceWorkerFile = "service-worker.js";
// const pushyServiceWorkerFile = "pushy-service-worker.js";
// const serviceWorkerFile = useFCM ? "firebase-messaging-sw.js" : "service-worker.js";
// register('./firebase-messaging-sw.js')
// named cache in Cache Storage
const CACHE_NAME = "devtools-tips-v3";

// list of requests whose responses will be pre-cached at install
const INITIAL_CACHED_RESOURCES = [
  "/",
  "/offline/",
  "/all/",
  "/browser/edge/",
  "/browser/safari/",
  "/browser/firefox/",
  "/browser/chrome/",
  "/assets/style.css",
  "/assets/filter-tip-list.js",
  "/assets/share.js",
  "/assets/logo.png",
  "https://unpkg.com/prismjs@1.20.0/themes/prism-okaidia.css",
  "/assets/localforage-1.10.0.min.js",
];

// if (process.env.APP_ENV === 'production') {
register(process.env.BASE_URL + serviceWorkerFile, {
  // register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    msg = "Ứng dụng đã sẵn sàng trên trình duyệt của bạn";
    console.log(logBadgeName, logBadgeColor, msg);
  },
  registered() {
    msg = "Ứng dụng đã được đăng ký.";
    console.log(logBadgeName, logBadgeColor, msg);
  },
  cached() {
    msg = "Nội dung đã được lưu trữ để sử dụng ngoại tuyến.";
    console.log(logBadgeName, logBadgeColor, msg);
  },
  updatefound() {
    msg = `Đã tìm thấy phiên bản mới ! Hệ thống tự động cập nhật ứng dụng xin vui lòng chờ trong giây lát...`;
    alert(msg);
    console.log(logBadgeName, logBadgeColor, msg);
  },
  updated() {
    msg = `Đã có phiên bản mới ${appVersion}, Bạn có muốn khởi động lại ứng dụng ?`;
    let isConfirm = confirm(msg);
    if (isConfirm) {
      Promise.resolve().then(() => {
        window.location.reload(true);
      });
    }
    console.log(logBadgeName, logBadgeColor, msg);
  },
  offline() {
    msg =
      "Không tìm thấy kết nối internet. Ứng dụng đang chạy ở chế độ ngoại tuyến.";
    console.log(logBadgeName, logBadgeColor, msg);
  },
  error(error) {
    msg = "Lỗi trong quá trình khởi tạo ứng dụng:";
    console.error(logBadgeName, logBadgeColor, msg, error);
  },
});
