import { personal } from "@General/store/";

export default {
  namespaced: true,
  state() {
    return {
      ...personal.state(),
    };
  },
  getters: {
    ...personal.getters,
  },
  mutations: {
    ...personal.mutations,
  },
  actions: {
    ...personal.actions,
  },
};
