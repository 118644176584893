import { requiredAuth } from "./router-guards";
import { marketChildRoutes as adPostRoute } from "@General/router/";

const marketchildRoutes = (prop, mode = false) => [...adPostRoute(prop, mode)];
export default [
  // *===============================================---*
  // *--------- STORE -------------------------------*
  // *===============================================---*
  {
    path: "/market",
    name: "market",
    component: () => import("@/layouts/Default"),
    children: marketchildRoutes("market"),
    beforeEnter: requiredAuth,
  },
];
