<template>
  <div>
    <div class="modal-header">
      <h5 class="modal-title fw-bold text-medium">Hỏi giá sản phẩm</h5>
      <button
        type="button"
        class="btn-close"
        @click="$emit('closeModal')"
        aria-label="Close"
      ></button
      ><!--v-if-->
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <p class="fw-bold mb-1">Loại sản phẩm</p>
          <select name="" id="products" class="form-control form-hover">
            <option value="" disabled selected>---Chọn loại sản phẩm---</option>
            <option value="landtour">Landtour</option>
            <option value="grouptour">Grouptour</option>
          </select>
        </div>
        <div class="col-12 mt-2">
          <p class="fw-bold mb-1">Nội dung</p>
          <textarea
            name=""
            id=""
            class="form-control form-hover"
            rows="6"
            placeholder="Nội dung yêu cầu"
          ></textarea>
        </div>
        <!-- <div class="text-light-red mt-2 text-small" v-if="errorMessage">
          *{{ errorMessage }}
        </div> -->
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-light" @click="$emit('closeModal')">
        Hủy</button
      ><button
        @click="sendRequestQuote"
        type="button"
        class="btn text-white bg-primary border-0"
      >
        <span>Gửi yêu cầu</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { inject } from "vue";

  export default {
    setup(props, { emit }) {
      const swal = inject("$swal");
      const sendRequestQuote = () => {
        document.body.classList.add("no-scrolling");
        emit("closeModal");
        swal.fire({
          icon: "success",
          title: "Đã gửi yêu cầu báo giá thành công",
          confirmButtonText: "Ok",
          willClose: () => {
            document.body.classList.remove("no-scrolling");
          },
        });
      };

      return { sendRequestQuote };
    },
  };
</script>

<style></style>
