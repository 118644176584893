import mutations from "./ads_posts/mutations";
import actions from "./ads_posts/actions"
import getters from "./ads_posts/getters"
import { STATUS_LOADING } from "@General/constants/ReqStatuses"

const defaultAdsPost = {
  status: STATUS_LOADING,
  data: {},
};

export default {
  namespaced: true,
  state() {
    return {
      ads_post: defaultAdsPost,
    };
  },
  getters,
  mutations,
  actions,
};
