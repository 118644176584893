<template>
  <div class="back-to-top" @click="backToTop">
    <i class="material-symbols-outlined text-large arrow-icon"> arrow_upward</i>
  </div>
</template>

<script>
export default {
  setup() {
    // back to top
    const backToTop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Oper
    };
    return { backToTop };
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  z-index: 98;
}
</style>
