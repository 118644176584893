import axios from "axios";
const apiURL = process.env.VUE_APP_URL_BACKEND;

const sendUserActivities = async (userActivities) => {
  await axios.post(apiURL + "/users/activities", userActivities, {
    headers: {
      "content-type": "application/json",
    },
  });
};
const sendCounterImpressions = async (impressions) => {
  await axios.post(apiURL + "/counters/impressions", impressions, {
    headers: {
      "content-type": "application/json",
    },
  });
};

const sendUserRegisterData = async (data) => {
  await axios.post(apiURL + "/users/statistics/register", data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export { sendUserActivities, sendUserRegisterData, sendCounterImpressions };
