import { firebaseStore } from "@/firebase/config";

const state = () => {
    return {
        listCompanyRecommend: null
    };
};

const mutations = {
    getListCompanyRecommendMutation(state, payload) {
        state.listCompanyRecommend = payload
    }
};

const getters = {

};

const actions = {
    // Danh sách cty
    async getListCompanyRecommendAction({ commit }, { loading }) {
        let arr;
        await firebaseStore.collection("boxInfo")
            .doc('recommendList')
            .get()
            .then((res) => {
                arr = res.data();
            })
            .catch((err) => console.log(err));
        // Chi tiết cty từ id
        const getDetailCompany = async (id) => {
            const res = await firebaseStore
                .collection("pages")
                .doc(id)
                .get()
            return res.data()
        }
        // Danh sách cty theo loại
        const getDetailListCompany = async (ids) => {
            const requests = ids.map((id) => {
                return getDetailCompany(id)
                    .then((a) => {
                        return a
                    })
            })
            return Promise.all(requests)
        }
        // Danh sách cty theo từng loại
        const listAll = (keys) => {
            const res = keys.map((key) => {
                return getDetailListCompany(arr[key])
                    .then((a) => {
                        return [key, a]
                    })
            })
            return Promise.all(res)
        }
        listAll(Object.keys(arr))
            .then((res) => {
                const arrayFinal = Object.fromEntries(res);
                commit('getListCompanyRecommendMutation', arrayFinal);
            })
            .catch((err) => console.log(err))
            .finally(() => { loading() })

    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
