import { firebaseAuth, firebaseStore } from "@/firebase/config";
import { v4 as uuid } from "uuid";
import { createRandomString } from "@/composables/helper/helper";
import { STATUS_PENDING } from "@B2B/constants/b2b-config";
import _ from "lodash";

export default {
	namespaced: true,
	state() {
		return {
			tickets: [],
			ticketInfo: null,
			price: null,
		};
	},
	getters: {},
	mutations: {
		ADD_TICKET(state, payload) {
			state.tickets = [...state.tickets, payload];
		},
		REMOVE_TICKET_ITEM(state, index) {
			state.tickets.splice(index, 1);
		},
		RESET_TICKETS(state) {
			state.tickets = [];
		},
		SET_TICKET_INFO(state, payload) {
			state.ticketInfo = payload;
		},
		SET_PRODUCT_PRICE(state, payload) {
			state.price = payload;
		},
		REMOVE_DATA(state) {
			state.price = null;
			state.tickets = [];
			state.ticketInfo = null;
		},
	},
	actions: {
		// Check schedule data length
		checkSchedulesDataLength({ state }) {
			if (state.tickets.length) {
				return true;
			}
			return false;
		},
		// GENERATE SCHEDULES
		async generateDate({ state }, { id }) {
			const db = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("sellPosts")
				.doc(id)
				.collection("schedules");
			const batch = firebaseStore.batch();
			// generate specific date & schedule
			const listDatesDeparture = [];
			const listDatesArrival = [];
			const listFlightNumber = [];
			// batch set ref schedule items
			state.tickets.map((schedule) => {
				const { departure_time, arrival_time, flight_number } = schedule;
				listDatesDeparture.push(departure_time);
				listDatesArrival.push(arrival_time);
				listFlightNumber.push(flight_number);
				const scheduleId = `${uuid()}-${createRandomString(3)}`;
				const ref = db.doc(scheduleId);
				batch.set(
					ref,
					{ ...schedule, id: scheduleId, sell_post_id: id },
					{ merge: true }
				);
			});
			await batch.commit();
			return {
				listDatesDeparture,
				listDatesArrival,
				listFlightNumber,
				mapSchedules: [
					...(state.tickets.length <= 5
						? state.tickets
						: _.takeRight(state.tickets, 5)),
				],
			};
		},
		// CREATE POST
		async createPost({ commit, state, dispatch }, payload) {
			const id = `${uuid()}-${createRandomString(3)}`;
			const db = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("sellPosts");
			// get array schedule dates
			const {
				listDatesDeparture,
				listDatesArrival,
				listFlightNumber,
				mapSchedules,
			} = await dispatch("generateDate", { id });
			// post data
			const sellPost = {
				postId: id,
				...payload,
				...state.price,
				...state.ticketInfo,
				hashTagServices: [],
				hashTags: [...payload.hashTags],
				schedule_dates_departure: listDatesDeparture,
				schedule_dates_arrival: listDatesArrival,
				schedule_flight_number: listFlightNumber,
				mapSchedules: mapSchedules,
			};
			await db.doc(id).set(sellPost, { merge: true });
			await db
				.doc(id)
				.set({ ...sellPost, status: STATUS_PENDING }, { merge: true });
		},
	},
};
