import { firebaseAuth, firebaseStore } from "@/firebase/config";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";
export default {
	namespaced: true,
	state() {
		return {
			postCategory: null,
		};
	},
	getters: {},
	mutations: {
		SET_PRODUCT_CATEGORY(state, payload) {
			state.postCategory = payload;
		},
		REMOVE_STATE(state) {
			state.postCategory = null;
		},
	},
	actions: {},
};
