import { POST_OPTION, PAGE_OPTION } from "@/constants/DefaultData";
import {
  sendUserActivities,
  sendCounterImpressions,
} from "@General/composables/API/PrivateAPI/sendUserActivities";
export default {
  namespaced: true,
  state() {
    return {
      userActivities: null,
      userCompanyData: null,
      impressionIds: [],
      counterImpressions: { impressions: [] },
    };
  },
  getters: {},
  mutations: {
    SET_USER_ACTIVITIES(state, { option, action, company_key, postId }) {
      if (option === PAGE_OPTION) {
        if (!state.userActivities) {
          state.userActivities = {
            ...state.userCompanyData,
            target_company: {
              company_key,
              ...action,
            },
          };
        } else {
          state.userActivities.target_company = {
            ...state.userActivities.target_company,
            ...action,
          };
        }
      } else if (option === POST_OPTION) {
        if (!state.userActivities) {
          state.userActivities = {
            ...state.userCompanyData,
            target_posts: {
              [postId]: {
                ...action,
              },
            },
          };
        } else {
          if (state.userActivities.target_posts[postId]) {
            state.userActivities.target_posts[postId] = {
              ...state.userActivities.target_posts[postId],
              ...action,
            };
          } else {
            state.userActivities.target_posts[postId] = {
              ...action,
            };
          }
        }
      }
    },
    SET_USER_COMPANY_DATA(state, data) {
      state.userCompanyData = data;
      state.counterImpressions = { ...data, ...state.counterImpressions };
    },
    SET_POST_IMPRESSION(state, post) {
      state.counterImpressions.impressions.push(post);
    },
    RESET_USER_ACTIVITIES(state) {
      state.userActivities = null;
    },
    RESET_COUNTER_IMPRESSIONS(state) {
      state.counterImpressions.impressions = [];
    },
  },
  actions: {
    async sendActivities({ commit, state }) {
      await sendUserActivities(state.userActivities);
      commit("RESET_USER_ACTIVITIES");
    },
    async sendImpressions({ commit, state }) {
      if (state.counterImpressions.impressions.length > 0) {
        await sendCounterImpressions(state.counterImpressions);
        commit("RESET_COUNTER_IMPRESSIONS");
      }
    },
  },
};
