import { calculator_score_market } from "@General/store/";

export default {
  namespaced: true,
  state() {
    return {
      ...calculator_score_market.state(),
    };
  },
  getters: { ...calculator_score_market.getters },
  mutations: {
    ...calculator_score_market.mutations,
  },
  actions: {
    ...calculator_score_market.actions,
  },
};
