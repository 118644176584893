import { firebaseAuth } from "@/firebase/config";
import axios from "axios";
const user = firebaseAuth.currentUser;
const apiKey = process.env.VUE_APP_FIREBASE_KEY;

// refresh token
const getAndSetToken = async () => {
  const refreshToken = user.refreshToken;
  const res = await axios.post(
    "https://securetoken.googleapis.com/v1/token?key=" + apiKey,
    "grant_type=refresh_token&refresh_token=" + refreshToken
  );
  return res;
};

const asyncForEach = (array, callback, done) => {
  const runAndWait = (i) => {
    if (i === array.length) return done();
    return callback(array[i], () => runAndWait(i + 1));
  };
  return runAndWait(0);
};

const checkUserToken = async () => {
  if (!user) {
    return;
  }
  let userData = {};
  // get user data from indexedDb
  const dbRequest = window.indexedDB.open("firebaseLocalStorageDb");
  dbRequest.onsuccess = (e) => {
    const db = dbRequest.result;
    const stores = ["firebaseLocalStorage"];
    const tx = db.transaction(stores);
    asyncForEach(
      stores,
      (store, next) => {
        const req = tx.objectStore(store).getAll();
        req.onsuccess = () => {
          userData[store] = req.result;
          next();
        };
      },
      //   check token expiration time
      async () => {
        const userRef =
          userData.firebaseLocalStorage[
            userData.firebaseLocalStorage.length - 1
          ];
        const currentTime = new Date().getTime();
        const { expirationTime } = userRef.value.stsTokenManager;
        if (expirationTime > currentTime) {
          return true;
        }
        await getAndSetToken();
      }
    );
  };
  dbRequest.onerror = (e) => {
    return false;
  };
};
export { checkUserToken };
