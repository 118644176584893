export default {
  namespaced: true,
  state() {
    return {
      totalImages: 0,
      totalFiles: 0,
      progress: 0,
    };
  },
  getters: {
    totalUploadFiles: (state) => state.totalFiles + state.totalImages,
  },
  mutations: {
    SET_TOTAL_IMAGES(state, payload) {
      state.totalImages = payload;
    },
    SET_TOTAL_FILES(state, payload) {
      state.totalFiles = payload;
    },
    SET_PROGRESS(state, payload) {
      state.progress += payload;
    },
    RESET_UPLOAD_STATE(state) {
      state.totalImages = 0;
      state.totalFiles = 0;
      state.progress = 0;
    },
  },
};
