import { requiredAuth } from "./router-guards";
const appChildRoute = (prop, mode = false) => [
  {
    path: "",
    name: prop + ".general",
    meta: {
      auth: true,
      name: "Cài đặt chung",
      img: require("@/assets/images/page-img/profile-bg6.jpg"),
      breadcrumb: [{ name: "Cài đặt chung" }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Settings/Settings"),
  },
];

export default [
  // *===============================================---*
  // *--------- APPS -------------------------------*
  // *===============================================---*
  {
    path: "/settings",
    name: "settings",
    beforeEnter: requiredAuth,
    component: () => import("@/layouts/Manage"),
    meta: { auth: true },
    children: appChildRoute("settings"),
  },
];
