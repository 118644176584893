import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import registerGlobalComponent from "@/utilities/defineGlobalComponents";
import VueSocialSharing from "vue-social-sharing";
import VueEasyLightbox from "vue-easy-lightbox";
import "./registerServiceWorker";
import "./plugins";
import "@/assets/css/config.css";
import vueSweetAlert from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { GOOGLE_MAP_KEY } from "@/constants/DefaultData";
import { firebaseAuth, firebaseMessaging } from "./firebase/config";
import VueMixpanel from "vue-mixpanel";

// import VueApexCharts from "vue3-apexcharts";
/**
 *  === BEGIN: init Firebase Cloud Message ===
 */

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const logBadgeName = "%c ⚙ TC APP ";
const logBadgeColor =
  "background: #222; color: #bada55; border-radius: 4px; padding: 2px";
const browserNotification = {
  async init(firebaseMessaging) {
    let _logBadgeMsg = "This browser does not support system notifications";

    if (!this.funcs.checkSupportSystemNotifcations()) {
      return console.log(logBadgeName, logBadgeColor, _logBadgeMsg);
    } //end if

    if (!firebaseMessaging) {
      _logBadgeMsg = "Firebase messaging is not supported in your browser";
      return console.log(logBadgeName, logBadgeColor, _logBadgeMsg);
    } //end if

    if (!this.funcs.requestAndCheckPermission()) {
      _logBadgeMsg = "User blocked notifications";
      return console.log(logBadgeName, logBadgeColor, _logBadgeMsg);
    } //end if

    const token = await this.funcs.getToken(firebaseMessaging);
  },
  funcs: {
    checkSupportSystemNotifcations() {
      return "Notification" in window;
    },

    requestAndCheckPermission() {
      if (Notification["permission"] === "granted") {
        return true;
      }

      Notification.requestPermission()
        .then(function (p) {
          if (p === "granted") {
            return true;
          } else {
            return false;
          } //end if
        })
        .catch(function (err) {
          console.error(err);
          return false;
        });
    },

    async getToken(firebaseMessaging) {
      const vapidKey = process.env.VUE_APP_VAPIDKEY;
      let _logBadgeMsg =
        "No registration token available. Request permission to generate one.";
      return await firebaseMessaging
        .getToken({
          vapidKey: vapidKey,
        })
        .then((currentToken) => {
          if (!currentToken) {
            console.log(logBadgeName, logBadgeColor, _logBadgeMsg);
            return null;
          } //end if

          return currentToken;
        })
        .catch((err) => {
          _logBadgeMsg = "error occurred while retrieving token";
          console.log(logBadgeName, logBadgeColor, _logBadgeMsg, err);
          return null;
        });
    },
  },
};

browserNotification.init(firebaseMessaging);

/**
 *  === END: init Firebase Cloud Message ===
 */

// directive click out side event
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

let app;
firebaseAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    registerGlobalComponent(app);
    app.use(BootstrapVue3);
    app.use(VueGoogleMaps, {
      load: {
        key: GOOGLE_MAP_KEY,
        libraries: "places",
      },
    });
    // app.use(VueApexCharts);
    app.use(vueSweetAlert);
    app.use(VueSocialSharing);
    app.use(VueEasyLightbox);
    app.use(VueMixpanel, {
      token: process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN,
      config: {
        debug: true,
      },
    });
    app
      .use(store)
      .use(router)
      .directive("clickOut", clickOutside)
      .mount("#app");
  }
});

export { app };
