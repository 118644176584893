/* eslint-disable no-undef */
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";

const registerPushy = () => {
  if (!Pushy.isRegistered()) {
    Pushy.register({ appId: process.env.VUE_APP_PUSHY_ID })
      .then(function (deviceToken) {
        console.log("Pushy device token: " + deviceToken);
      })
      .catch(function (err) {
        const line = getErrorLine();
        sendErrorToSlack("error", err, line);
      });
  }
  Pushy.setNotificationListener(function (data) {
    console.log("Received notification: " + JSON.stringify(data));
  });
};

export { registerPushy };
