<template>
  <div>
    <div class="modal-header">
      <h5 class="modal-title fw-bold">Thông tin liên hệ</h5>
      <button
        type="button"
        class="btn-close"
        @click="$emit('closeModal')"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div v-if="contactEmail || contactPhone">
        <p>
          <span> {{ content }} </span>
        </p>
        <div v-for="(contact, index) in contacts" :key="index">
          <div class="contact-item" v-if="contact.contactInfo">
            <h2 class="fs-5 fw-bold d-inline-flex align-items-center">
              <img
                :src="contact.icon"
                alt="Contact icon"
                loading="lazy"
                class="avatar-20 me-2"
              />
              {{ contact.contactName }}
            </h2>
            <p>
              <a :href="contact.contactLink" target="_blank">{{
                contact.contactInfo
              }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <p class="text-gray text-center text-small fst-italic">
          Người bán chưa cập nhật thông tin liên hệ
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import { computed, ref } from "vue";
  import { checkPhoneNumber } from "@/composables/helper/helper";

  export default {
    props: {
      content: {
        type: String,
        required: false,
        default: "Thông tin liên hệ với người đăng",
      },
      category: {
        type: String,
        required: false,
        default: "",
      },
      hashTags: {
        type: String,
        required: false,
        default: "",
      },
    },
    setup() {
      const store = useStore();
      const contactEmail = computed(() => store.state.marketSocial.contactEmail);
      const contactPhone = computed(() => store.state.marketSocial.contactPhone);

      const contacts = ref([
        {
          icon: require("@/assets/images/icon/zalo.svg"),
          contactName: "Zalo",
          contactInfo: contactPhone.value,
          contactLink: "https://zalo.me/" + contactPhone.value,
        },
        {
          icon: require("@/assets/images/icon/telephone-call.svg"),
          contactName: "Số điện thoại",
          contactInfo: checkPhoneNumber(contactPhone.value),
          contactLink: "tel:" + checkPhoneNumber(contactPhone.value),
        },
        {
          icon: require("@/assets/images/icon/mail.svg"),
          contactName: "Email",
          contactInfo: contactEmail.value,
          contactLink: "mailto:" + contactEmail.value,
        },
      ]);
      return { store, contacts, contactEmail, contactPhone };
    },
  };
</script>

<style lang="scss" scoped></style>
