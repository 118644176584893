import {
  requiredAuth
} from "@/router/routes/router-guards";

const b2bMarketRoutes = (prop, mode = false) => [
  // marketplace
  {
    path: "",
    name: prop + ".listing",
    beforeEnter: requiredAuth,
    meta: {
      auth: true,
      name: "Chợ B2B",
      breadcrumb: [{
        name: "Chợ B2B"
      }],
    },
    children: [{
      path: ":type",
      name: prop + ".type",
      meta: {
        auth: true,
        name: "Danh sách sản phẩm",
        breadcrumb: [{
          name: "Chợ B2B"
        }, {
          name: "Danh sách sản phẩm"
        }],
      },
      component: () =>
        import("@B2B/views/ProductListing/ProductItemList.vue"),
    }, ],
    component: () => import("@B2B/views/ProductListing"),
  },
  {
    path: "global-land-tour-detail/:id",
    name: prop + ".global-land-tour",
    meta: {
      auth: true,
      name: "Land Tour quốc tế",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Land Tour quốc tế"
      }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/ProductDetail/LandTour/GlobalLandTour"),
  },
  {
    path: "domestic-land-tour-detail/:id",
    name: prop + ".domestic-land-tour",
    meta: {
      auth: true,
      name: "Land Tour nội địa",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Land Tour nội địa"
      }],
    },
    beforeEnter: requiredAuth,
    component: () =>
      import("@B2B/views/ProductDetail/LandTour/DomesticLandTour"),
  },
  {
    path: "global-group-tour-detail/:id",
    name: prop + ".global-group-tour",
    meta: {
      auth: true,
      name: "Group Tour quốc tế",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Group Tour quốc tế"
      }],
    },
    beforeEnter: requiredAuth,
    component: () =>
      import("@B2B/views/ProductDetail/GroupTour/GlobalGroupTour"),
  },
  {
    path: "domestic-group-tour-detail/:id",
    name: prop + ".domestic-group-tour",
    meta: {
      auth: true,
      name: "Group Tour nội địa",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Group Tour nội địa"
      }],
    },
    beforeEnter: requiredAuth,
    component: () =>
      import("@B2B/views/ProductDetail/GroupTour/DomesticGroupTour"),
  },
  {
    path: "series-tickets-detail/:id",
    name: prop + ".series-tickets",
    meta: {
      auth: true,
      name: "Series Vé",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Series Vé"
      }],
    },
    beforeEnter: requiredAuth,
    component: () =>
      import("@B2B/views/ProductDetail/TicketSeries/TicketSeries"),
  },
  {
    path: "accommodation-detail/:id",
    name: prop + ".accommodation",
    meta: {
      auth: true,
      name: "Cơ sở lưu trú",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Cơ sở lưu trú"
      }],
    },
    beforeEnter: requiredAuth,
    component: () =>
      import("@B2B/views/ProductDetail/Accommodation/Accommodation"),
  },
  {
    path: "transport-detail",
    name: prop + ".transport",
    meta: {
      auth: true,
      name: "Di chuyển",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Di chuyển"
      }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/ProductDetail/Transport/Transport"),
  },
  {
    path: "combo-detail",
    name: prop + ".combo",
    meta: {
      auth: true,
      name: "Combo",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Combo"
      }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/ProductDetail/Combo/Combo"),
  },
  {
    path: "voucher-detail",
    name: prop + ".voucher",
    meta: {
      auth: true,
      name: "Voucher",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Voucher"
      }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/ProductDetail/Voucher/Voucher"),
  },
  // My Products
  {
    path: "storage",
    name: prop + ".storage",
    meta: {
      auth: true,
      name: "Sản phẩm của tôi",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Sản phẩm của tôi"
      }],
    },
    redirect: {
      name: "b2b.storage_on_sell"
    },
    children: [{
        path: "",
        name: prop + ".storage_on_sell",
        meta: {
          auth: true,
          name: "Sản phẩm của tôi",
          breadcrumb: [{
              name: "Chợ B2B"
            },
            {
              name: "Kho sản phẩm"
            },
            {
              name: "Sản phẩm của tôi"
            },
          ],
        },
        component: () => import("@B2B/views/MyProducts/ProductsOnSell"),
      },
      {
        path: "pending",
        name: prop + ".storage_pending",
        meta: {
          auth: true,
          name: "Chờ xét duyệt",
          breadcrumb: [{
              name: "Chợ B2B"
            },
            {
              name: "Kho sản phẩm"
            },
            {
              name: "Chờ xét duyệt"
            },
          ],
        },
        component: () => import("@B2B/views/MyProducts/ProductsPending"),
      },
      {
        path: "expired",
        name: prop + ".storage_expired",
        meta: {
          auth: true,
          name: "Đã hết hạn",
          breadcrumb: [{
              name: "Chợ B2B"
            },
            {
              name: "Kho sản phẩm"
            },
            {
              name: "Đã hết hạn"
            },
          ],
        },
        component: () => import("@B2B/views/MyProducts/ProductsExpired"),
      },
      {
        path: "canceled",
        name: prop + ".storage_canceled",
        meta: {
          auth: true,
          name: "Ngừng bán",
          breadcrumb: [{
              name: "Chợ B2B"
            },
            {
              name: "Kho sản phẩm"
            },
            {
              name: "Ngừng bán"
            },
          ],
        },
        component: () => import("@B2B/views/MyProducts/ProductsCanceled"),
      },
      {
        path: "warning",
        name: prop + ".storage_warning",
        meta: {
          auth: true,
          name: "Cảnh báo",
          breadcrumb: [{
              name: "Chợ B2B"
            },
            {
              name: "Kho sản phẩm"
            },
            {
              name: "Cảnh báo"
            },
          ],
        },
        component: () => import("@B2B/views/MyProducts/ProductsWarning"),
      },
    ],
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/MyProducts"),
  },
  // Product Create
  {
    path: "storage/create",
    name: prop + ".create-product",
    meta: {
      auth: true,
      name: "Tạo sản phẩm",
      breadcrumb: [{
        name: "Chợ B2B"
      }, {
        name: "Tạo sản phẩm"
      }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/ProductCreate/ProductCreate"),
  },
  {
    path: "storage/:productId/edit",
    name: prop + ".edit",
    meta: {
      auth: true,
      name: "Cập nhật sản phẩm",
      breadcrumb: [{
          name: "Chợ B2B"
        },
        {
          name: "Kho sản phẩm"
        },
        {
          name: "Cập nhật sản phẩm"
        },
      ],
    },
    beforeEnter: requiredAuth,
    component: () => import("@B2B/views/EditMyProduct/EditMyProduct"),
  },
];

export default [
  // *===============================================---*
  // *--------- B2B MARKET -------------------------------*
  // *===============================================---*
  {
    path: "/b2b",
    name: "b2b",
    beforeEnter: requiredAuth,
    component: () => import("@/layouts/Default"),
    children: b2bMarketRoutes("b2b"),
  },
];
