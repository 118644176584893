import { requiredAuth } from "@/router/routes/router-guards";

const managementRoutes = (prop, mode = false) => [
	{
		path: "create-post",
		name: prop + "_post.createpost",
		meta: {
			auth: true,
			name: "Đăng tin mới",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quản lý tin" },
				{ name: "Đăng tin mới" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/manage/posts/CreatePost"),
	},
	{
		path: "saved",
		name: prop + "_post.saved",
		meta: {
			auth: true,
			name: "Tin đã lưu",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quản lý tin" },
				{ name: "Tin đã lưu" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/manage/posts/PostsSaved"),
	},
	{
		path: "posts-pending",
		name: prop + "_post.pending",
		meta: {
			auth: true,
			name: "Chờ xét duyệt",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quản lý tin" },
				{ name: "Chờ xét duyệt" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/manage/posts/PostsPending"),
	},
	{
		path: "posts-on-sell",
		name: prop + "_post.onsell",
		meta: {
			auth: true,
			name: "Tin đã đăng",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quản lý tin" },
				{ name: "Tin đã đăng" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/manage/posts/PostsOnSell"),
	},
	//   price quatation
	{
		path: "price-quotation",
		name: prop + "_price.pricequotation",
		meta: {
			auth: true,
			name: "Báo giá",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Báo giá" },
			],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import("@General/views/manage/PriceQuotation/PriceQuotation"),
	},
	// payment setting

	{
		path: "history-transaction",
		name: prop + "_payment.transaction",
		meta: {
			auth: true,
			name: "Lịch sử hoạt động",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Giao dịch" },
				{ name: "Lịch sử hoạt động" },
			],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import("@/views/Settings/Payment/TransactionHistory/TransactionsHistory"),
	},
	{
		path: "promotions",
		name: prop + "_payment.promotions",
		meta: {
			auth: true,
			name: "Khuyến mãi",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Thanh toán" },
				{ name: "Khuyến mãi" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@/views/Settings/Payment/Promotions/Promotions"),
	},

	// Price Service list
	{
		path: "price-service",
		name: prop + "_service.priceservice",
		meta: {
			auth: true,
			name: "Bảng giá dịch vụ",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Dịch vụ" },
				{ name: "Bảng giá dịch vụ" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@/views/Settings/PriceService/PriceService"),
	},

	// Account Packages
	{
		path: "account-package",
		name: prop + "_service.accountpackage",
		meta: {
			auth: true,
			name: "Gói tài khoản",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Dịch vụ" },
				{ name: "Gói tài khoản" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@/views/Settings/AccountPackages/AccountPackages"),
	},
	// Advertising
	{
		path: "do-advertising",
		name: prop + "_advertising.invite",
		meta: {
			auth: true,
			name: "đăng ký quảng cáo",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quảng cáo" },
				{ name: "Đăng ký quảng cáo" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@/views/Settings/Advertisement/GetAds/GetAds.vue"),
	},
	{
		path: "list-advertising",
		name: prop + "_advertising.listAds",
		meta: {
			auth: true,
			name: "Danh sách",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quảng cáo" },
				{ name: "Danh sách" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@/views/Settings/Advertisement/ListAdvs/ListAds"),
	},
	{
		path: "statiscical",
		name: prop + "_advertising.statiscical",
		meta: {
			auth: true,
			name: "Thống kê",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quảng cáo" },
				{ name: "Thống kê" },
			],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import("@/views/Settings/Advertisement/Statistical/Statistical"),
	},
	{
		path: "transaction",
		name: prop + "_advertising.transaction",
		meta: {
			auth: true,
			name: "Giao dịch",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quảng cáo" },
				{ name: "Giao dịch" },
			],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import("@/views/Settings/Advertisement/Transaction/Transaction"),
	},
	// user manual

	{
		path: "user-manual",
		name: prop + "_manage.UserManual",
		meta: {
			auth: true,
			name: "Hướng dẫn sử dụng",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Hướng dẫn sử dụng" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@/views/Settings/UserManual/UserManual"),
	},

	// *===============================================---*
	// *--------- BUSINESS DASHBOARD ---------------------*
	// *===============================================---*
	{
		path: "profile-management",
		name: "profile.profile-management",
		meta: {
			auth: true,
			name: "Quản Lý Hồ Sơ",
			breadcrumb: [
				{ name: "Quản lý tài khoản", link: prop + ".intro" },
				{ name: "Quản Lý Hồ Sơ" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/Profile/ProfileManagement"),
	},
];

export { managementRoutes };
