import { firebaseStorage } from "@/firebase/config";
import { ref } from "vue";
import { fileValidation } from "@/constants/fileValidation";
import { v4 as uuidv4 } from "uuid";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";
import { computed } from "vue";
import store from "@/store/index";

const validateFile = (file) => {
	const { max_size, mime_type } = fileValidation;
	if (mime_type.includes(file.type) && file.size <= max_size) {
		return true;
	}
	return false;
};

const uploadExtraFile = async (folder = "zalofeed", file, extraFile) => {
	const extraFilePath = ref(null);
	extraFilePath.value = `/${folder}/${folder}/${file.name}`;
	const storageRef = firebaseStorage.ref(extraFilePath.value);
	const totalUploadFiles = computed(
		() => store.getters["upload_progress/totalUploadFiles"]
	);
	const progress = Math.floor(100 / totalUploadFiles.value);
	try {
		const res = await storageRef.put(file);
		extraFile.url = await res.ref.getDownloadURL();
		store.commit("upload_progress/SET_PROGRESS", progress);
	} catch (err) {
		extraFilePath.value = null;
		const line = getErrorLine();
		sendErrorToSlack("error", err, line);
	}
	const result = { ...extraFile };
	delete result.file;

	return result;
};

const uploadMultipleExtraFile = async (files) => {
	let resultFiles = {};
	let folder = "";
	for (const key in files) {
		const extraFile = files[key];
		if (extraFile.cats.length > 0) {
			folder = "private";
		} else {
			folder = "public";
		}
		const res = await uploadExtraFile(folder, extraFile.file, extraFile);
		if (res) {
			const id = uuidv4();
			resultFiles[id] = res;
		}
	}

	return resultFiles;
};

const uploadFile = async (folder, userId, file) => {
	const filePath = ref(null);
	const url = ref(null);
	const totalUploadFiles = computed(
		() => store.getters["upload_progress/totalUploadFiles"]
	);
	const progress = Math.floor(100 / totalUploadFiles.value);
	filePath.value = `${userId}/${folder}/${file.name}`;
	const storageRef = firebaseStorage.ref(filePath.value);
	try {
		const res = await storageRef.put(file);
		url.value = await res.ref.getDownloadURL();
		store.commit("upload_progress/SET_PROGRESS", progress);
	} catch (err) {
		filePath.value = null;
		url.value = null;
		const line = getErrorLine();
		sendErrorToSlack("error", err, line);
	}

	return url.value;
};

const uploadMultipleFiles = async (array, folder, userId) => {
	const res = await Promise.all(
		array.map((file) => uploadFile(folder, userId, file))
	);
	return res;
};

export {
	uploadFile,
	uploadMultipleFiles,
	validateFile,
	uploadExtraFile,
	uploadMultipleExtraFile,
};
