import { firebaseStore, firebaseAuth } from "@/firebase/config";
import { checkUserToken } from "@/composables/userToken/user-token";
import { snapshots } from "@General/store/";

export default {
  namespaced: true,
  state() {
    return {
      userPoint: null,
      userPointListener: null,
      userNotifications: [],
      userNotificationsListener: null,
      ...snapshots.state(),
    };
  },
  getters: {
    lastPostByType: (state) => {
      if (state.currentPostType === "pending") {
        return state.postsPending[state.postsPending.length - 1];
      }
      return state.postsOnSell[state.postsOnSell.length - 1];
    },
    ...snapshots.getters,
  },
  mutations: {
    ...snapshots.mutations,
    SET_NOTIFICATIONS(state, _notifications) {
      state.userNotifications = _notifications;
    },
    SET_NOTIFICATIONS_LISTENER(state, listener) {
      state.userNotificationsListener = listener;
    },
    SET_USER_POINT(state, point) {
      state.userPoint = point;
    },
    SET_USER_POINT_LISTENER(state, listener) {
      state.userPointListener = listener;
    },
    REMOVE_SNAPSHOT_LISTENER(state) {
      state.postsPending =
        state.postsOnSell =
        state.userPoint =
        state.currentPostType =
        state.newBusiness =
        null;
      state.newPosts = state.userNotifications = [];
      if (state.postsOnSellListener) {
        state.postsOnSellListener();
        state.postsOnSellListener = null;
      }
      if (state.postsPendingListener) {
        state.postsPendingListener();
        state.postsPendingListener = null;
      }
      if (state.userNotificationsListener) {
        state.userNotificationsListener();
        state.userNotificationsListener = null;
      }
      if (state.userPointListener) {
        state.userPointListener();
        state.userPointListener = null;
      }
      if (state.newBusinessListener) {
        state.newBusinessListener();
        state.newBusinessListener = null;
      }
    },
  },
  actions: {
    ...snapshots.actions,
    async getUserNotifications({ commit }) {
      await checkUserToken();
      let notificationsListener = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("notifications")
        .orderBy("timestamp", "desc")
        .limit(3)
        .onSnapshot((snap) => {
          let notifications = [];
          notifications = [
            ...snap.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
          console.log(notifications);
          commit("SET_NOTIFICATIONS", notifications);
        });
      commit("SET_NOTIFICATIONS_LISTENER", notificationsListener);
    },
    async getUserPoint({ commit }) {
      await checkUserToken();
      let userPointListener = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("preferences")
        .doc("wallet")
        .onSnapshot((snap) => {
          let point = 0;
          point = snap.data().point;
          commit("SET_USER_POINT", point);
        });
      commit("SET_USER_POINT_LISTENER", userPointListener);
    },
  },
};
