// use in views/SetupProfile
// setup tabs
const SET_UP_TABS = "tabs";
const LIST_STEP = "list_step";
// step 1 : participating role options
const PROFILE_PARTICIPATING_ROLE = "participating_role";
// step 2 : options when search business by business number
const PROFILE_OPTIONS = "checking_options";
// step 3 : accommodation role options
const ACCOMMODATION_ROLE = "accommodation_role";
// AccountItems (step 1)
const BUSINESS_KEY = "business";
const PERSON_KEY = "person";
const STATE_ORGANIZATION_KEY = "state_organization";
const ORGANIZATIONs_ASSOCIATIONS_KEY = "organizations_associations";
const EMBASSY_KEY = "embassy";
const AIR_KEY = "air";
const NEWS_KEY = "news";
const TECH_KEY = "teach";
const TRAVEL_INSURANCE_KEY = "travel_insurance";
const INTERNATIONAL_KEY = "international";
const SOCIAL_LINK = "social_link";
// array check picker
const authorizePicker = ["đại diện"];
const agentPicker = ["đại lý", "bán buôn"];
const ownerPicker = ["chủ", "sở hữu"];
// helper functions
const checkAssetType = (_picker) => {
  let picker = "owner";
  let lowerText = _picker.toLowerCase();
  authorizePicker.forEach((item) => {
    if (lowerText.includes(item)) {
      picker = "authorize";
    }
  });
  agentPicker.forEach((item) => {
    if (lowerText.includes(item)) {
      picker = "agent";
    }
  });
  ownerPicker.forEach((item) => {
    if (lowerText.includes(item)) {
      picker = "owner";
    }
  });
  return picker;
};

const setupProfile = {
  // social links in profile
  //  { name: "vr", src: require("@/assets/images/icon/3d.svg") },

  [SOCIAL_LINK]: [
    { name: "facebook", src: require("@/assets/images/icon/facebook.png") },
    { name: "twitter", src: require("@/assets/images/icon/twitter.png") },
    { name: "youtube", src: require("@/assets/images/icon/youtube.png") },
    { name: "linkedin", src: require("@/assets/images/icon/linkedin.png") },
    { name: "website", src: require("@/assets/images/icon/website.png") },
  ],
  // tabs setup profile
  [SET_UP_TABS]: [
    {
      name: "role",
      icon: "business",
    },
    {
      name: "checkInfo",
      icon: "manage_search",
    },
    {
      name: "legalInfo",
      icon: "feed",
    },
    {
      name: "personalInfo",
      icon: "person",
    },
  ],
  // setup steps
  [LIST_STEP]: [
    "participating-role",
    "check-info",
    "legal-info",
    "personal-info",
  ],
  // step 1
  [PROFILE_PARTICIPATING_ROLE]: {
    [BUSINESS_KEY]: {
      name: "Doanh nghiệp",
      role: "business",
      icon: "business",
      available: true,
      descAcc: [
        "Lữ hành quốc tế",
        "Lữ hành nội địa",
        "Cơ sở lưu trú",
        "Phòng vé",
        "Nhà hàng",
        "Đại lý du lịch",
      ],
      descAction: [
        "Quảng bá sản phẩm dịch vụ mới",
        "Mở rộng đối tác khách hàng",
        "Xây dựng kênh phân phối dịch vụ",
        "Tham gia hoạt động cộng đồng du lịch",
        "Tuyển dụng nhân sự",
        "Cập nhật thông tin ngành du lịch",
      ],
    },
    [PERSON_KEY]: {
      name: "Cá nhân",
      role: "person",
      icon: "person",
      available: false,
      descAcc: [
        "HDV quốc tế Inbound",
        "HDV quốc tế Outbound",
        "HDV nội địa Tour Leader",
        "MC Sales du lịch Điều hành",
      ],
      descAction: [
        "Tìm việc toàn thời gian",
        "Tìm việc bán thời gian",
        "Tìm việc theo hợp đồng",
        "Tham gia cộng đồng chuyên gia",
        "Cập nhật tình trạng điểm đến",
        "Cập nhật tin tức ngành",
        "Tham gia sự kiện du lịch",
      ],
    },
    [STATE_ORGANIZATION_KEY]: {
      name: "Tổ chức nhà nước",
      role: "state_organization",
      icon: "assured_workload",
      available: false,
      descAcc: [
        "Tổng cục du lịch",
        "Sở du lịch",
        "Trung tâm xúc tiến DL",
        "Quản lý điểm đến",
      ],
      descAction: [
        "Thông báo hoạt động ngành",
        "Thông báo các hoạt động xúc tiến du lịch tại địa phương",
        "Cập nhật tình trạng điểm đến",
        "Phổ biến pháp luật và các hoạt động ngành",
      ],
    },
    [ORGANIZATIONs_ASSOCIATIONS_KEY]: {
      name: "Tổ chức / Hiệp hội",
      role: "organizations_associations",
      icon: "supervisor_account",
      available: true,
      descAcc: [
        "Hiệp hội du lịch",
        "Chi hội du lịch",
        "Clb du lịch",
        "Liên minh du lịch",
        "Tổ chức liên quan du lịch",
      ],
      descAction: [
        "Mở rộng hệ thống thành viên",
        "Tăng cường các hoạt động",
        "Tổ chức sự kiện, hội thảo, quảng bá",
      ],
    },
    [EMBASSY_KEY]: {
      name: "Đại sứ quán",
      role: "embassy",
      icon: "account_balance",
      available: false,
      descAcc: ["Đại sứ quán các nước", "Lãnh sự quán", "Đại diện quốc gia"],
      descAction: [
        "Tương tác với các doanh nghiệp",
        "Xác thực các doanh nghiệp",
        "Thông báo các hoạt động của quốc gia",
      ],
    },
    [AIR_KEY]: {
      name: "Hàng không",
      role: "air",
      icon: "flight_takeoff",
      available: false,
      descAcc: ["Hãng hàng không", "Đại diện hãng", "VPĐD hãng"],
      descAction: [
        "Thông báo các hoạt động",
        "Xác nhận các đại lý F1",
        "Xúc tiến quảng bá",
      ],
    },
    [NEWS_KEY]: {
      name: "Báo chí",
      role: "news",
      icon: "newspaper",
      available: false,
      descAcc: [
        "Báo chí du lịch",
        "Tạp chí du lịch",
        "Báo điện tử",
        "Truyền thông sự kiện",
      ],
      descAction: [
        "Cập nhật thông tin ngành",
        "Cung cấp dịch vụ báo chí",
        "Tham gia các hoạt động sự kiện trong ngành",
      ],
    },
    [TECH_KEY]: {
      name: "Đào tạo",
      role: "teach",
      icon: "cast_for_education",
      available: false,
      descAcc: [
        "Trường đại học",
        "Trung tâm nghề",
        "Tổ chức chứng chỉ du lịch",
      ],
      descAction: [
        "Tổ chức các chương trình đào tạo",
        "Phát triển học viên",
        "Tổ chức sự kiện hội thảo",
      ],
    },
    [TRAVEL_INSURANCE_KEY]: {
      name: "Bảo hiểm du lịch",
      role: "travel_insurance",
      icon: "demography",
      available: false,
      descAcc: ["Các hãng bảo hiểm du lịch"],
      descAction: ["Cung cấp dịch vụ bảo hiểm du lịch cho doanh nghiệp"],
    },
    [INTERNATIONAL_KEY]: {
      name: "Tổ chức quốc tế",
      role: "international",
      icon: "public",
      available: false,
      descAcc: [
        "Các tổ chức quốc tế ngoài Việt Nam",
        "Đại diện hãng",
        "Văn phòng đại diện",
      ],
      descAction: ["Xúc tiến quảng bá du lịch", "Kết nối đối tác"],
    },
  },
  // step 3
  [ACCOMMODATION_ROLE]: [
    {
      key: "owner",
      value: "Chủ sở hữu",
    },
    {
      key: "representative",
      value: "Đại diện kinh doanh",
    },
    {
      key: "agency",
      value: "Đại lý/Bán buôn",
    },
  ],
  // step 2
  [PROFILE_OPTIONS]: [
    {
      action: "staff",
      icon: "group_add",
      name: "Tôi là nhân sự doanh nghiệp này",
      disable: true,
    },
    {
      action: "affiliate",
      icon: "group_add",
      name: "Tôi là cộng tác viên doanh nghiệp này",
      disable: true,
    },
    {
      action: "create-new",
      icon: "add_business",
      name: "Tạo mới hồ sơ doanh nghiệp",
      disable: false,
    },
  ],
};

export {
  setupProfile,
  SET_UP_TABS,
  LIST_STEP,
  PROFILE_PARTICIPATING_ROLE,
  PROFILE_OPTIONS,
  ACCOMMODATION_ROLE,
  checkAssetType,
  SOCIAL_LINK,
  ORGANIZATIONs_ASSOCIATIONS_KEY,
};
