import { checkAccountTest } from "./router-guards";
import { otaChildRoute as routes } from "@General/router/";

const otaChildRoute = (prop, mode = false) => [...routes(prop, mode)];

export default [
  // *===============================================---*
  // *--------- request -------------------------------*
  // *===============================================---*
  {
    path: "/ota_management",
    name: "ota_management",
    beforeEnter: checkAccountTest,
    component: () => import("@/layouts/OTA"),
    children: otaChildRoute("ota_management"),
  },
];
