import { firebaseStore } from "@/firebase/config";

const state = () => {
	return {
		listData: null,
		detailItem: null,
		infoPage: null,
		listPartnerCompany: null,
		isChange: false,
	};
};

const mutations = {
	getListDataProductListingMutation(state, payload) {
		state.listData = payload;
	},
	loadMoreProductListingMutation(state, payload) {
		state.listData.push(...payload);
	},
	getDetailItem(state, payload) {
		state.detailItem = payload;
	},
	getInfoDetailPage(state, payload) {
		state.infoPage = payload;
	},
	getPartnerCompany(state, payload) {
		state.listPartnerCompany = payload;
	},
	changeStatusFilterMutation(state) {
		state.isChange = !state.isChange
	}
};
const getters = {
	LastDataProduct(state) {
		const lastKey = state.listData[state.listData?.length - 1];
		return lastKey;
	},
};

const actions = {
	// Danh sách sản phẩm
	async getListDataProductListingAction({ commit }, { key, loading, filter }) {
		let db = await firebaseStore.collection("sellPosts")
		if (key) {
			db = db.where("typePost", "==", key);
		}
		if (filter) {
			if (filter.hotel_tour?.length > 0) {
				db = db.where("form_stay", "==", [filter.hotel_tour]);
			}
			if (filter.transfer_tour?.length > 0) {
				db = db.where("form_transportation", "==", [filter.transfer_tour]);
			}
			if (filter.place?.length > 0) {
				db = db.where("hashTags", "array-contains", filter.place);
			}
			if (filter.days?.length > 0) {
				db = db.where("days", "==", Number(filter.days));
			}
			if (filter.nights?.length > 0) {
				db = db.where("nights", "==", Number(filter.nights));
			}
		}
		db.limit(10)
			.get()
			.then((data) => {
				const arr = data.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				commit("getListDataProductListingMutation", arr);
				loading();
			})
			.catch((err) => console.log(err));
	},
	// Thông tin công ty
	async getDetailPage({ commit }, { pageId, loading }) {
		firebaseStore
			.collection("pages")
			.doc(pageId)
			.get()
			.then((data) => {
				commit("getInfoDetailPage", data.data());
				loading();
			})
			.catch((err) => console.log(err));
	},
	// Load more
	async loadMoreProductListingAction(
		{ commit, getters },
		{ key, filter, loading, empty }
	) {
		let db = firebaseStore.collection("sellPosts");
		if (key) {
			db = db.where("typePost", "==", key);
		}
		if (filter) {
			if (filter.hotel_tour?.length > 0) {
				db = db.where("form_stay", "==", [filter.hotel_tour]);
			}
			if (filter.transfer_tour?.length > 0) {
				db = db.where("form_transportation", "==", [filter.transfer_tour]);
			}
			if (filter.place?.length > 0) {
				db = db.where("hashTags", "array-contains", filter.place);
			}
			if (filter.days?.length > 0) {
				db = db.where("days", "==", Number(filter.days));
			}
			if (filter.nights?.length > 0) {
				db = db.where("nights", "==", Number(filter.nights));
			}
		}
		firebaseStore.collection("sellPosts")
			.orderBy("dateCreated", "desc")
			.startAfter(getters.LastDataProduct.dateCreated)
			.limit(5)
			.get()
			.then((data) => {
				const arr = data.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				if (arr?.length == 0) {
					empty()
				} else {
					commit("loadMoreProductListingMutation", arr);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				loading();
			})
	},
	// Chi tiết sản phẩm
	async getDetailProductListingAction({ commit, dispatch }, { id, loading }) {
		firebaseStore
			.collection("sellPosts")
			.doc(id)
			.get()
			.then((data) => {
				commit("getDetailItem", data.data());
				dispatch("getDetailPage", { pageId: data.data().pageId, loading });
				dispatch("getPartnerCompany", { pageId: data.data().pageId });
			});
	},
	// Chi tiết sản phẩm
	async getPartnerCompany({ commit }, { pageId }) {
		firebaseStore
			.collection("pages")
			.doc(pageId)
			.collection("recommends")
			.get()
			.then((data) => {
				const arr = data.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				commit("getPartnerCompany", arr);
			});
	},
	// Filter Product
	async filterListDataProductListingAction({ commit }, { key, loading, filter }) {
		let db = firebaseStore.collection("sellPosts")
		if (key) {
			db = db.where("typePost", "==", key);
		}
		if (filter) {
			if (filter.hotel_tour?.length > 0) {
				db = db.where("form_stay", "==", [filter.hotel_tour]);
			}
			if (filter.transfer_tour?.length > 0) {
				db = db.where("form_transportation", "==", [filter.transfer_tour]);
			}
			if (filter.place?.length > 0) {
				db = db.where("hashTags", "array-contains", filter.place);
			}
			if (filter.days?.length > 0) {
				db = db.where("days", "==", Number(filter.days));
			}
			if (filter.nights?.length > 0) {
				db = db.where("nights", "==", Number(filter.nights));
			}
		}
		db.limit(10)
			.get()
			.then((data) => {
				const arr = data.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				commit("getListDataProductListingMutation", arr);

			})
			.catch((err) => console.log(err))
			.finally(() => {
				loading();
			})
	},
	// Change status
	changeStatusFilterAction({ commit }) {
		commit('changeStatusFilterMutation')
	}
};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
