const DEPARTURE_DATE = "date";
const DEPARTURE_DAILY = "daily";
const DEPARTURE_WEEKLY = "weekly";
const DEPARTURE_MONTHLY = "monthly";

const DEPARTURE_OPTIONS = {
	[DEPARTURE_DATE]: "Ngày cụ thể",
	[DEPARTURE_DAILY]: "Khởi hành hàng ngày",
	[DEPARTURE_WEEKLY]: "Khởi hành hàng tuần",
	[DEPARTURE_MONTHLY]: "Khởi hành hàng tháng",
};

export {
	DEPARTURE_DATE,
	DEPARTURE_DAILY,
	DEPARTURE_WEEKLY,
	DEPARTURE_MONTHLY,
	DEPARTURE_OPTIONS,
};
