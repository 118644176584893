import { firebaseStore, firebaseAuth } from "@/firebase/config";
import { checkUserToken } from "@/composables/userToken/user-token";
export default {
	namespaced: true,
	state() {
		return {
			newPosts: [],
			postsSubscriber: null,
			postsPending: null,
			postsPendingListener: null,
			postsOnSell: null,
			postsOnSellListener: null,
			currentPostType: null,
			newBusiness: null,
			newBusinessListener: null,
			endPoint: false,
		};
	},
	getters: {
		lastPostByType: (state) => {
			if (state.currentPostType === "pending") {
				return state.postsPending[state.postsPending.length - 1];
			}
			return state.postsOnSell[state.postsOnSell.length - 1];
		},
	},
	mutations: {
		SET_END_POINT(state, status) {
			state.endPoint = status;
		},
		SET_NEW_POST(state, _newPost) {
			state.newPosts = [_newPost];
		},
		REMOVE_POSTS(state) {
			state.newPosts = [];
		},
		SET_POST_LISTENER(state, listener) {
			state.postsSubscriber = listener;
		},
		UNSUBSCRIBE_POST_LISTENER(state) {
			if (state.postsSubscriber) {
				state.postsSubscriber();
				state.postsSubscriber = null;
			}
		},
		SET_CURRENT_POST_TYPE(state, type) {
			state.currentPostType = type;
		},
		SET_MORE_POSTS(state, posts) {
			if (state.currentPostType === "pending") {
				state.postsPending = [...state.postsPending, ...posts];
			} else {
				state.postsOnSell = [...state.postsOnSell, ...posts];
			}
		},
		SET_POSTS_PENDING(state, listPostPending) {
			state.postsPending = listPostPending;
		},
		SET_POSTS_ON_SELL(state, listPostOnSell) {
			state.postsOnSell = listPostOnSell;
		},
		SET_POSTS_PENDING_LISTENER(state, listener) {
			state.postsPendingListener = listener;
		},
		SET_POSTS_ON_SELL_LISTENER(state, listener) {
			state.postsOnSellListener = listener;
		},
		// NEW BUSINESS
		SET_NEW_BUSINESS(state, { businessData, listener }) {
			state.newBusiness = businessData;
			state.newBusinessListener = listener;
		},
	},
	actions: {
		async getRealtimePost({ commit }) {
			const timeVisit = new Date().getTime();
			let postsSubscriber = firebaseStore
				.collection("zalofeed")
				.orderBy("updateTime", "desc")
				.limit(1)
				.onSnapshot((doc) => {
					let post = [
						...doc.docs.map((doc) => {
							return {
								...doc.data(),
								id: doc.id,
							};
						}),
					][0];
					if (post.updateTime && post.updateTime > timeVisit) {
						commit("SET_NEW_POST", post);
					}
				});
			commit("SET_POST_LISTENER", postsSubscriber);
		},
		async getPostsPending({ commit }) {
			await checkUserToken();
			let postsSubscriber = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("socialPostsPending")
				.orderBy("updateTime", "desc")
				.limit(10)
				.onSnapshot((snap) => {
					let posts = [];
					posts = [
						...snap.docs.map((doc) => {
							return {
								...doc.data(),
								id: doc.id,
							};
						}),
					];
					commit("SET_POSTS_PENDING", posts);
				});
			commit("SET_POSTS_PENDING_LISTENER", postsSubscriber);
		},
		async getPostsOnSell({ commit }) {
			await checkUserToken();
			let postsSubscriber = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("socialPostsOnSell")
				.orderBy("updateTime", "desc")
				.limit(10)
				.onSnapshot((snap) => {
					let posts = [];
					posts = [
						...snap.docs.map((doc) => {
							return {
								...doc.data(),
								id: doc.id,
							};
						}),
					];
					commit("SET_POSTS_ON_SELL", posts);
				});
			commit("SET_POSTS_ON_SELL_LISTENER", postsSubscriber);
		},
		async loadMorePosts({ commit, state, getters }) {
			await checkUserToken();
			let collection;
			if (state.currentPostType === "pending") {
				collection = "socialPostsPending";
			} else {
				collection = "socialPostsOnSell";
			}
			let lastDocSnapshot = getters.lastPostByType;
			let posts = [];
			const res = await firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection(collection)
				.orderBy("updateTime", "desc")
				.startAfter(lastDocSnapshot.updateTime)
				.limit(5)
				.get();
			if (res.size) {
				posts = res.docs.map((doc) => {
					return {
						...doc.data(),
						id: doc.id,
					};
				});
				commit("SET_MORE_POSTS", posts);
			} else {
				commit("SET_END_POINT", true);
			}
		},
		// NEW BUSINESS
		async getNewBusiness({ commit }) {
			await checkUserToken();
			let newBusiness = [];
			const businessData = firebaseStore
				.collection("pages")
				.orderBy("timestamp", "desc")
				.limit(3)
				.onSnapshot((snap) => {
					newBusiness = [
						...snap.docs.map((doc) => {
							return {
								...doc.data(),
								id: doc.id,
							};
						}),
					];
					commit("SET_NEW_BUSINESS", {
						businessData: newBusiness,
						listener: businessData,
					});
				});
		},
	},
};
