import { firebaseStore } from "@/firebase/config";
import { checkUserToken } from "@/composables/userToken/user-token";
import { firebaseAuth } from "@/firebase/config";
import { HIDDEN_POST_EXPIRATION_DAY } from "@/constants/DefaultData";
import { handleError } from "@/composables/handle_error/HandleError";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";

export default {
  namespaced: true,
  state() {
    return {
      zaloFeed: null,
      isFiltering: false,
      endPoint: false,
      contactEmail: null,
      contactPhone: null,
      postIdRemove: null,
      postDetails: null,
      postVerification: null,
      mode: "newest",
      videoId: null,
      listPlayer: [],
      hiddenPosts: null,
    };
  },
  getters: {
    lastDocSnapshot: (state) =>
      [...state.zaloFeed].sort((a, b) =>
        a.updateTime < b.updateTime ? 1 : a.updateTime > b.updateTime ? -1 : 0
      )[state.zaloFeed.length - 1],
  },
  mutations: {
    SET_HIDDEN_POSTS(state, payload) {
      state.hiddenPosts = { ...(state.hiddenPosts ?? {}), ...payload };
    },
    DELETE_HIDDEN_POST_ID(state, id) {
      delete state.hiddenPosts[id];
    },
    SET_MODE(state, payload) {
      state.mode = payload;
    },
    SET_FEED(state, { posts, isFiltering }) {
      state.zaloFeed = posts;
      state.isFiltering = isFiltering;
      state.endPoint = false;
    },
    SET_MORE_FEEDS(state, data) {
      state.zaloFeed = [...state.zaloFeed, ...data];
    },
    SET_END_POINT(state, _endPointStatus) {
      state.endPoint = _endPointStatus;
    },
    SET_POST_CONTACT(state, { email, phoneNumber }) {
      state.contactEmail = email;
      state.contactPhone = phoneNumber;
    },
    SET_NEW_PLAYER(state, newPlayer) {
      if (!state.listPlayer.map((player) => player.id).includes(newPlayer.id)) {
        state.listPlayer.push(newPlayer);
      }
    },
    SET_POST_ID_REMOVE(state, _postId) {
      state.postIdRemove = _postId;
    },
    SET_POST_DETAILS(state, post) {
      state.postDetails = post;
    },
    SET_POST_REQUEST_VERIFICATION(state, _post) {
      state.postVerification = _post;
    },
    SET_YOUTUBE_VIDEO_ID(state, id) {
      state.videoId = id;
      state.listPlayer.forEach((pl) => {
        pl.player.pauseVideo();
        if (pl.id == id) {
          pl.player.playVideo();
        }
      });
    },
    REMOVE_YOUTUBE_PLAYER(state) {
      state.videoId = null;
      state.listPlayer = [];
    },
    REMOVE_REQUEST_VERIFICATION(state) {
      state.postVerification = null;
    },
    REMOVE_HIDDEN_POSTS(state) {
      state.hiddenPosts = null;
    },
    REMOVE_MARKET_FEED_DATA_BY_MODE(state) {
      state.zaloFeed =
        state.contactEmail =
        state.contactPhone =
        state.postIdRemove =
        state.postDetails =
        state.postVerification =
        state.isVerified =
        state.endPoint =
          false;
      state.videoId = null;
      state.listPlayer = [];
    },
    REMOVE_MARKET_FEED_DATA(state) {
      state.zaloFeed =
        state.contactEmail =
        state.contactPhone =
        state.postIdRemove =
        state.postDetails =
        state.postVerification =
        state.isVerified =
        state.videoId =
          null;
      state.endPoint = false;
      state.isFiltering = false;
      state.mode = "newest";
      state.listPlayer = [];
    },
  },
  actions: {
    async prioritizePosts({ commit }, posts) {
      const currentUserPost = [];
      const resArray = [];
      posts.forEach((post) => {
        if (post.userId && post.userId === firebaseAuth.currentUser.uid) {
          currentUserPost.push(post);
        } else {
          resArray.push(post);
        }
      });
      return [...currentUserPost, ...resArray];
    },
    deleteKeyExpired({ commit, state }) {
      if (firebaseAuth.currentUser.uid) {
        let hidden_posts = state.hiddenPosts;
        if (Object.keys(hidden_posts).length) {
          for (const id in hidden_posts) {
            if (
              Date.now() - hidden_posts[id] >
              HIDDEN_POST_EXPIRATION_DAY * 24 * 60 * 60
            ) {
              commit("DELETE_HIDDEN_POST_ID", id);
            }
          }
        }
      }
    },
    async filterHiddenPosts({ commit, dispatch, state }, docsData) {
      let hidden_posts = state.hiddenPosts;
      if (Object.keys(hidden_posts).length) {
        let availablePosts = await docsData.docs.filter(
          (doc) => !hidden_posts[doc.id]
        );
        return availablePosts;
      }
      return docsData.docs;
    },
    async updateHiddenPosts({ commit, state, dispatch }) {
      try {
        await dispatch("deleteKeyExpired");
        await firebaseStore
          .collection("userRef")
          .doc(firebaseAuth.currentUser.uid)
          .update({ hidden_posts: state.hiddenPosts });
        return true;
      } catch (error) {
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
        handleError(error.message);
        return false;
      }
    },
    async getFeeds({ state, commit, dispatch }, hourCriteria) {
      await checkUserToken();
      let posts = [];
      let response = null;
      // if (!hourCriteria) {
      //   hourCriteria = Math.floor(Date.now() / 1000 + (1 * 60 * 60))
      // }

      switch (state.mode) {
        case "newest":
          response = firebaseStore
            .collection("zalofeed")
            // .where("verified", "==", true)
            .orderBy("updateTime", "desc")
            .limit(10);
          break;
        case "promotion":
          response = firebaseStore
            .collection("zalofeed")
            .where("is_great_deal", "==", true)
            .orderBy("updateTime", "desc")
            .limit(10);
          break;
        case "lastMinute":
          response = firebaseStore
            .collection("zalofeed")
            // .where("last_minutes_expired_at", "<=", hourCriteria)
            .orderBy("last_minutes_expired_at", "desc")
            .limit(10);
          break;
        default:
          break;
      }

      try {
        let docsData = await response.get();
        let availableDocs = await dispatch("filterHiddenPosts", docsData);
        posts = [
          ...(await Promise.all(
            availableDocs.map(async (doc, index) => {
              const postContacts = await firebaseStore
                .collection("zalofeed")
                .doc(doc.id)
                .collection("preferences")
                .doc("contacts")
                .get();

              return {
                id: doc.id,
                ...doc.data(),
                ...postContacts.data(),
              };
            })
          )),
        ];
        if (state.mode === "newest") {
          posts = await dispatch("prioritizePosts", posts);
        }
        commit("SET_FEED", {
          posts,
          isFiltering: false,
        });
      } catch (error) {
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
        commit("SET_FEED", {
          posts: [],
          isFiltering: false,
        });
      }
    },
    async loadMoreFeeds({ commit, getters, state, dispatch }) {
      await checkUserToken();
      let posts = [];
      let lastDocSnapshot = getters.lastDocSnapshot;
      if (lastDocSnapshot) {
        const response = await firebaseStore
          .collection("zalofeed")
          .where("verified", "==", true)
          .orderBy("updateTime", "desc")
          .startAfter(lastDocSnapshot.updateTime)
          .limit(5)
          .get();
        let availableDocs = await dispatch("filterHiddenPosts", response);

        posts = [
          ...(await Promise.all(
            availableDocs.map(async (doc, index) => {
              const postContacts = await firebaseStore
                .collection("zalofeed")
                .doc(doc.id)
                .collection("preferences")
                .doc("contacts")
                .get();

              return {
                id: doc.id,
                ...doc.data(),
                ...postContacts.data(),
              };
            })
          )),
        ];

        if (posts.length > 0) {
          posts = await dispatch("prioritizePosts", posts);
          commit("SET_MORE_FEEDS", posts);
        } else {
          commit("SET_END_POINT", true);
          return false;
        }
      }
    },
    async filterFeeds({ commit, state, dispatch }, options) {
      await checkUserToken();
      let posts = [];
      const response = await firebaseStore
        .collection("zalofeed")
        .where(`hashTagsProduct.${options.category}`, "==", true)
        .where(
          "hashTags",
          "array-contains",
          options.hashTags
            ? options.hashTags
            : options.category.includes("outbound")
            ? "all_country"
            : "ChIJXx5qc016FTERvmL-4smwO7A"
        )
        .where("verified", "==", true)
        .orderBy("updateTime", "desc")
        .limit(10)
        .get();

      if (response) {
        let availableDocs = await dispatch("filterHiddenPosts", response);
        posts = [
          ...(await Promise.all(
            availableDocs.map(async (doc, index) => {
              const postContacts = await firebaseStore
                .collection("zalofeed")
                .doc(doc.id)
                .collection("preferences")
                .doc("contacts")
                .get();

              return {
                id: doc.id,
                ...doc.data(),
                ...postContacts.data(),
              };
            })
          )),
        ];
        posts = await dispatch("prioritizePosts", posts);
        commit("SET_END_POINT", false);
        commit("SET_FEED", { posts, isFiltering: true });
      } else {
        return false;
      }
    },
    async loadMoreFilterFeeds({ commit, getters, dispatch }, options) {
      await checkUserToken();
      let posts = [];
      let lastDocSnapshot = getters.lastDocSnapshot;
      const response = await firebaseStore
        .collection("zalofeed")
        .where(`hashTagsProduct.${options.category}`, "==", true)
        .where(
          "hashTags",
          "array-contains",
          options.hashTags
            ? options.hashTags
            : options.category.includes("outbound")
            ? "all_country"
            : "ChIJXx5qc016FTERvmL-4smwO7A"
        )
        .where("verified", "==", true)
        .orderBy("updateTime", "desc")
        .startAfter(lastDocSnapshot.updateTime)
        .limit(5)
        .get();

      if (response) {
        let availableDocs = await dispatch("filterHiddenPosts", response);
        posts = [
          ...(await Promise.all(
            availableDocs.map(async (doc, index) => {
              const postContacts = await firebaseStore
                .collection("zalofeed")
                .doc(doc.id)
                .collection("preferences")
                .doc("contacts")
                .get();

              return {
                id: doc.id,
                ...doc.data(),
                ...postContacts.data(),
              };
            })
          )),
        ];
        if (posts.length > 0) {
          posts = await dispatch("prioritizePosts", posts);
          commit("SET_MORE_FEEDS", posts);
        } else {
          commit("SET_END_POINT", true);
        }
      } else {
        return false;
      }
    },
    async addNewPost({ commit }, post) {
      await checkUserToken();
      await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("socialPostsPending")
        .add(post);
    },
    async removePost({ commit, state, dispatch }, options) {
      await checkUserToken();
      await firebaseStore
        .collection("zalofeed")
        .doc(state.postIdRemove.postId)
        .delete();
      commit("SET_POST_ID_REMOVE", null);
      if (options) {
        if (state.isFiltering) {
          await dispatch("filterFeeds", options);
        } else {
          await dispatch("getFeeds");
        }
      }
    },
    async getFeedsByPopularKeywords(
      { commit, dispatch },
      { category, hashtags }
    ) {
      await checkUserToken();
      let posts = [];
      const response = await firebaseStore
        .collection("zalofeed")
        .where(`hashTagsProduct.${category}`, "==", true)
        .where("hashTags", "array-contains", hashtags)
        .where("verified", "==", true)
        .orderBy("updateTime", "desc")
        .limit(10)
        .get();

      if (response) {
        let docsData = await dispatch("filterHiddenPosts", response);
        posts = [
          ...(await Promise.all(
            docsData.map(async (doc, index) => {
              const postContacts = await firebaseStore
                .collection("zalofeed")
                .doc(doc.id)
                .collection("preferences")
                .doc("contacts")
                .get();

              return {
                id: doc.id,
                ...doc.data(),
                ...postContacts.data(),
              };
            })
          )),
        ];
        posts = await dispatch("prioritizePosts", posts);
        commit("SET_FEED", {
          posts,
          isFiltering: true,
        });
      }
    },
  },
};
