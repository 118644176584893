import { firebaseStore } from "@/firebase/config";
import { firebaseAuth } from "@/firebase/config";
import { checkUserToken } from "@/composables/userToken/user-token";
export default {
  namespaced: true,
  state() {
    return {
      personalFeed: null,
      endPoint: false,
    };
  },
  getters: {
    lastDocSnapshot: (state) =>
      state.personalFeed[state.personalFeed.length - 1] || {},
  },
  mutations: {
    SET_FEED(state, { myListPosts }) {
      state.personalFeed = myListPosts;
    },
    SET_MORE_FEEDS(state, _moreFeeds) {
      state.personalFeed = [...state.personalFeed, ..._moreFeeds];
    },
    SET_END_POINT(state, _endPointStatus) {
      state.endPoint = _endPointStatus;
    },
    REMOVE_FEED_DATA(state) {
      state.personalFeed = null;
      state.endPoint = false;
    },
    LIKE_FEED(state, index) {
      state.personalFeed[index].likescount += 1;
    },
    UNLIKE_FEED(state, index) {
      state.personalFeed[index].likescount -= 1;
    },
  },
  actions: {
    async getFeeds({ commit }, { option, pageId }) {
      await checkUserToken();
      let myListPosts = [];
      let query;
      if (option === "personal") {
        query = firebaseStore
          .collection("feedBusiness")
          .doc(firebaseAuth.currentUser.uid)
          .collection("posts");
      } else if (option === "page") {
        query = firebaseStore
          .collection("pages")
          .doc(pageId)
          .collection("businessPosts");
      }
      const response = await query.orderBy("timestamp", "desc").limit(5).get();

      if (response) {
        let listPostsId = [
          ...response.docs.map((doc) => {
            return {
              ...doc.data(),
            };
          }),
        ].map((doc) => doc.postId);

        if (listPostsId.length > 0) {
          const postRes = await firebaseStore
            .collection("businessPosts")
            .where("postId", "in", listPostsId)
            .orderBy("timestamp", "desc")
            .get();
          myListPosts = [
            ...postRes.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }),
          ];
        }

        commit("SET_FEED", { myListPosts });
      }
    },

    async loadMoreFeeds({ commit, getters, state }, { option, pageId }) {
      await checkUserToken();
      let myListPosts = [];
      let lastDocSnapshot = getters.lastDocSnapshot;
      let query;
      if (option === "personal") {
        query = firebaseStore
          .collection("feedBusiness")
          .doc(firebaseAuth.currentUser.uid)
          .collection("posts");
      } else if (option === "page") {
        query = firebaseStore
          .collection("pages")
          .doc(pageId)
          .collection("businessPosts");
      }
      if (lastDocSnapshot) {
        const response = await query
          .orderBy("timestamp", "desc")
          .startAfter(lastDocSnapshot.timestamp)
          .limit(3)
          .get();

        if (response) {
          let listPostsId = [
            ...response.docs.map((doc) => {
              return {
                ...doc.data(),
              };
            }),
          ].map((doc) => doc.postId);

          if (listPostsId.length > 0) {
            const postRes = await firebaseStore
              .collection("businessPosts")
              .where("postId", "in", listPostsId)
              .orderBy("timestamp", "desc")
              .startAfter(lastDocSnapshot.timestamp)
              .limit(3)
              .get();
            myListPosts = [
              ...postRes.docs.map((doc) => {
                return {
                  id: doc.id,
                  ...doc.data(),
                };
              }),
            ];
          }
          if (myListPosts.length > 0) {
            commit("SET_MORE_FEEDS", myListPosts);
          } else {
            commit("SET_END_POINT", true);
          }
        }
      }
    },
  },
};
