const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
const errorImage = (e) => {
  e.target.src = require("@/assets/images/error/errorImage.png");
  e.target.classList.add("error-image");
};
const errorAvatar = (e) => {
  e.target.src = require("@/assets/images/user/default-avatar.png");
};
const errorCover = (e) => {
  e.target.src =
    "https://thumbs.dreamstime.com/b/travel-background-banner-items-traveling-flights-tickets-passport-money-sunglasses-colored-rest-vacation-concept-170421886.jpg";
};
const convertProvinceName = (name) => {
  let arrName = name.split(",");
  let convertName = "";
  arrName = arrName.filter((name) => name.trim() != "Việt Nam").join(",");
  if (arrName.length > 10) {
    convertName = arrName.split(",");
    return [convertName[0]].filter(Boolean).join(",");
  }
  return arrName;
};

const checkPostTextContent = (textContent) => {
  const errorTextContent = [".", "Content not available yet"];
  if (errorTextContent.includes(textContent)) {
    return false;
  }
  return true;
};

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const replaceStringPrefix = (string, prefix) => {
  // eslint-disable-next-line no-useless-escape
  let newString = string.replace(/  +/g, " ").replace(/\s+|[,\/]/g, prefix);
  return newString;
};

const splitEscapeSequence = (paragraph) => {
  return paragraph.replace(/\n+/g, "\n").split("\n");
};
const checkPhoneNumber = (phoneNumber) => {
  if (phoneNumber[0] !== "0") {
    if (phoneNumber[0] === "+") {
      return phoneNumber;
    }
    return `+${phoneNumber}`;
  }
  return phoneNumber;
};

const setGridImages = (arrayLength) => {
  let gridLayout = "";
  switch (arrayLength) {
    case 1:
      gridLayout = "grid-single";
      break;
    case 2:
      gridLayout = "grid-double-images";
      break;
    case 3:
    default:
      gridLayout = "grid-multiple-images";
      break;
  }
  return gridLayout;
};
const createRandomString = (length = 8) => {
  let string = "";
  for (let i = 0; i < length; i++) {
    string += characters.charAt(Math.round(Math.random() * characters.length));
  }

  return string;
};
// convert file
const createObjectURL = (file) => {
  return URL.createObjectURL(file);
};
// convert file size to mb
const convertFileSize = (fileSize) => {
  return (fileSize / (1024 * 1024)).toFixed(2);
};

const getExpirationTime = (day) => {
  return day * 24 * 60 * 60 * 1000;
};

const removeTextSign = (str) => {
  str = str.replace(/\s+/g, " ");
  str = str.trim();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );
  return str;
};

const convertLink = (url) => {
  if (!url.includes("http")) {
    url = "https://" + url;
  }
  return url;
};

export {
  errorImage,
  convertProvinceName,
  checkPostTextContent,
  setGridImages,
  errorAvatar,
  errorCover,
  splitEscapeSequence,
  checkPhoneNumber,
  createRandomString,
  createObjectURL,
  convertFileSize,
  getExpirationTime,
  replaceStringPrefix,
  removeTextSign,
  convertLink,
  debounce,
};
