import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";
import { firebaseStore, firebaseAuth } from "@/firebase/config";

export default {
  namespaced: true,
  state() {
    return {
      feedPersonal: null,
      feedBusiness: null,
    };
  },
  getters: {},
  mutations: {
    SET_FEED_CONFIG(state, payload) {
      state.feedPersonal = payload.personal;
      state.feedBusiness = payload.business;
    },
    SET_FOLLOW_STATE(state, { index, status }) {
      state.feedPersonal.pagesHighlight[index].isFollowing = status;
    },
    RESET_STATE(state) {
      state.feedPersonal = null;
      state.feedBusiness = null;
    },
  },
  actions: {
    async getFeedConfigs({ commit }) {
      const res = await firebaseStore.collection("boxInfo").doc("config").get();
      const { feedPersonal, feedCompany } = res.data();
      // get page promote & pages highlight in personal feed
      const {
        pagePromote: pagePromotePersonal,
        pagesHighlight: pagesHighlightPersonal,
      } = feedPersonal;
      // get page promote & pages highlight in business feed
      const {
        pagePromote: pagePromoteCompany,
        pagesHighlight: pagesHighlightCompany,
      } = feedCompany;

      // query page promote [personal feed]
      const queryPagePromotePersonal = firebaseStore
        .collection("pages")
        .doc(pagePromotePersonal);
      // query pages highlight [personal feed]
      const queryPagesHighlightPersonal = Promise.all(
        pagesHighlightPersonal.map(async (doc, index) => {
          const getPageData = firebaseStore.collection("pages").doc(doc).get();
          const checkFollowPage = firebaseStore
            .collection("userRef")
            .doc(firebaseAuth.currentUser.uid)
            .collection("pageBusinessFollowing")
            .doc(doc)
            .get();
          const [page, isFollowing] = await Promise.all([
            getPageData,
            checkFollowPage,
          ]);

          return {
            id: page.id,
            ...page.data(),
            isFollowing: isFollowing.exists,
          };
        })
      );
      // query pages highlight [business feed]
      const queryPagePromoteCompany = firebaseStore
        .collection("pages")
        .doc(pagePromoteCompany);
      // query pages highlight [business feed]
      const queryPagesHighlightCompany = Promise.all(
        pagesHighlightCompany.map(async (doc, index) => {
          const page = await firebaseStore.collection("pages").doc(doc).get();

          return {
            id: page.id,
            ...page.data(),
          };
        })
      );

      const [
        pagePromotePersonalData,
        pagesHighlightPersonalData,
        pagePromoteCompanyData,
        pagesHighlightCompanyData,
      ] = await Promise.all([
        queryPagePromotePersonal.get(),
        queryPagesHighlightPersonal,
        queryPagePromoteCompany.get(),
        queryPagesHighlightCompany,
      ]);

      commit("SET_FEED_CONFIG", {
        personal: {
          pagePromote: pagePromotePersonalData.data() ?? {},
          pagesHighlight: pagesHighlightPersonalData ?? [],
        },
        business: {
          pagePromote: pagePromoteCompanyData.data() ?? {},
          pagesHighlight: pagesHighlightCompanyData ?? [],
        },
      });
    },
  },
};
