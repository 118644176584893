import axios from "axios";
const apiPostScores = process.env.VUE_APP_URL_BACKEND + "/posts/";
import { VIEWS_TYPE } from "@/constants/PostScore";

const sendPostScore = async (post, type) => {
  await axios.post(
    apiPostScores +
      (type === VIEWS_TYPE
        ? "ad_statistics_views"
        : "ad_statistics_interactions"),
    post,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

const sendPostScoreOutlive = async (post, type) => {
  const option = {
    method: "POST",
    body: JSON.stringify(post),
    headers: {
      'Content-Type': 'application/json'
    },
    keepalive: true
  };

  await fetch(apiPostScores +
    (type === VIEWS_TYPE
      ? "ad_statistics_views"
      : "ad_statistics_interactions"),
    option);
}

export { sendPostScore, sendPostScoreOutlive };
