import public_share from "@/app_modules/General/store/public_share/public";

export default {
  namespaced: true,
  state() {
    return {
      ...public_share.state(),
    };
  },
  getters: { ...public_share.getters },
  mutations: {
    ...public_share.mutations,
  },
  actions: {
    ...public_share.actions,
  },
};
