<template>
  <div>
    <div class="modal-header">
      <h5 class="modal-title fw-bold text-medium">Yêu cầu xác thực tin bán</h5>
      <button
        type="button"
        class="btn-close"
        @click="$emit('closeModal')"
        aria-label="Close"
      ></button
      ><!--v-if-->
    </div>
    <div class="modal-body" v-if="postRequest">
      <div class="row">
        <div class="col-12" v-if="postRequest.chanel_name">
          <p>
            <span
              class="bg-soft-primary text-small text-primary me-2 px-2 py-1 rounded-2"
              >Channel</span
            >
            <strong>: {{ postRequest.chanel_name }}</strong>
          </p>
        </div>
        <div class="col-12" v-if="postRequest.poster_name">
          <p>
            <span
              class="bg-soft-primary text-small text-primary me-2 px-2 py-1 rounded-2"
              >Người đăng</span
            >
            <strong>: {{ postRequest.poster_name }}</strong>
          </p>
        </div>
        <div class="col-12 mt-2" v-if="postRequest.poster_name">
          <p class="fw-bold mb-1">Nội dung</p>
          <textarea
            name=""
            id=""
            class="form-control form-hover"
            rows="6"
            placeholder="Nội dung yêu cầu"
            v-model="formMessage"
          ></textarea>
        </div>
        <div class="text-light-red mt-2 text-small" v-if="errorMessage">
          *{{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-light" @click="$emit('closeModal')">
        Hủy</button
      ><button
        @click="sendRequest"
        type="button"
        class="btn text-white bg-primary border-0"
        :disabled="isPending || !formMessage"
        :class="{ 'button-size': isPending }"
      >
        <span v-if="!isPending">Gửi yêu cầu</span>
        <circle-loader v-else />
      </button>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import { ref, computed, inject } from "vue";
  import { handleError } from "@/composables/handle_error/HandleError";
  import { sendVerificationRequest } from "@General/composables/API/PrivateAPI/VerificationRequest.js";
  import { INTERACTIONS_TYPE, ACTION_VERIFICATION } from "@/constants/PostScore";
  import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";

  export default {
    emits: ["closeModal", "verificationRequestSent"],
    props: {
      category: {
        type: String,
        required: false,
        default: "",
      },
      hashTags: {
        type: String,
        required: false,
        default: "",
      },
    },
    setup(props, { emit }) {
      const isPending = ref(false);
      const store = useStore();
      const errorMessage = ref(null);
      const postRequest = computed(
        () => store.state.marketSocial.postVerification
      );
      const swal = inject("$swal");
      const formMessage = ref("");
      const sendRequest = async () => {
        errorMessage.value = null;
        isPending.value = true;
        try {
          const post = {
            ...postRequest.value,
            message: formMessage.value,
          };
          await sendVerificationRequest(post);
          emit("closeModal");
          swal.fire("Gửi yêu cầu xác thực tin bán thành công", "", "success");
          store.commit("calculator_score_market/SET_POSTS_SCORE", {
            type: INTERACTIONS_TYPE,
            postId: postRequest.value.post_key,
            action: {
              [ACTION_VERIFICATION]: Math.round(new Date().getTime() / 1000),
            },
          });
          store.commit("marketSocial/REMOVE_REQUEST_VERIFICATION");
        } catch (error) {
          handleError(error.message);
          const line = getErrorLine();
          sendErrorToSlack("error", error, line);
          errorMessage.value =
            "Không thể gửi yêu cầu lúc này. Vui lòng thử lại sau";
        } finally {
          isPending.value = false;
        }
      };

      return {
        sendRequest,
        isPending,
        postRequest,
        formMessage,
        errorMessage,
      };
    },
  };
</script>

<style></style>
