import { requiredAuth } from "@/router/routes/router-guards";

const biddingRoutes = (prop, mode = false) => [
	// Tạo gói thầu
	{
		path: "create",
		name: prop + ".create",
		meta: {
			auth: true,
			name: "Tạo thầu",
			breadcrumb: [
				{
					name: "Tạo thầu",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding/BiddingListing/BiddingCreate"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	// Mời thầu Thư mời thầu – Request For Tenders (RFT)
	// là một lời mời chính thức để các nhà cung cấp gửi một hồ sơ dự thầu
	// để cung cấp sản phẩm hoặc dịch vụ. RFT có thể được phân phối cho
	// các nhà thầu tiềm năng thông qua dịch vụ đấu thầu, cho phép doanh nghiệp nhận
	//  và tìm kiếm đấu thầu trực tiếp từ một loạt các nguồn công khai và riêng tư.
	// Một số dịch vụ đấu thầu thậm chí phân chia các loại hình kinh doanh theo cách riêng,
	// theo mã CPV (Common Procurement Vocabulary), giúp
	// doanh nghiệp tìm RFT cụ thể cho những gì có thể cung cấp
	{
		path: "RFQs",
		name: prop + ".RFQs",
		meta: {
			auth: true,
			name: "Đáu thầu",
			breadcrumb: [
				{
					name: "Đáu thầu",
				},
				{
					name: "Gói thầu",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/RFQs/RFQs"),
	},
	{
		path: "quotations",
		name: prop + ".quotations",
		meta: {
			auth: true,
			name: "Báo giá",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
				{
					name: "Báo giá",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Quotations/Quotations"),
	},
	// Danh sách đấu thầu
	{
		path: "",
		name: prop + ".listing",
		meta: {
			auth: true,
			name: "Đấu thầu",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding/BiddingListing/BiddingListing"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	// Chi tiết đấu thầu
	{
		path: "detail/:id",
		name: prop + ".bidding-detail",
		meta: {
			auth: true,
			name: "Chi tiết thầu",
			breadcrumb: [
				{
					name: "Chi tiết thầu",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding/BiddingListing/BiddingDetail"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	// Giao dịch mua
	{
		path: "buyer",
		name: prop + ".buyer",
		meta: {
			auth: true,
			name: "Giao dịch mua",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
				{
					name: "Giao dịch mua",
				},
			],
		},
		children: [
			{
				path: "finished",
				name: prop + ".finished",
				meta: {
					auth: true,
					name: "Nhà thầu",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Nhà thầu",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyCreated/Finished"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "happening",
				name: prop + ".happening",
				meta: {
					auth: true,
					name: "Đang diễn ra",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Đang diễn ra",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyCreated/Happening"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
		],
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding/MyCreated/MyCreated"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	{
		path: "buyer/:idTransaction",
		name: prop + ".bidding-view",
		meta: {
			auth: true,
			name: "BiddingView",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
				{
					name: "BiddingView",
				},
			],
		},
		children: [
			{
				path: "b-waiting",
				name: prop + ".b-waiting",
				meta: {
					auth: true,
					name: "Yêu cầu tham gia",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Yêu cầu tham gia",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBiddingDetail/Waiting"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "b-passed",
				name: prop + ".b-passed",
				meta: {
					auth: true,
					name: "Trúng thầu",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Trúng thầu",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBiddingDetail/Passed"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "b-quoted",
				name: prop + ".b-quoted",
				meta: {
					auth: true,
					name: "Đã báo giá",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Đã báo giá",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBiddingDetail/Quoted"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "not-quoted",
				name: prop + ".not-quoted",
				meta: {
					auth: true,
					name: "Chưa báo giá",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Chưa báo giá",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBiddingDetail/NotQuoted"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
		],
		beforeEnter: requiredAuth,
		component: () =>
			import("@B2B/views/Bidding/MyBiddingDetail/MyBiddingDetail"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	{
		path: "buyer/:idTransaction/chat/:idChat",
		name: prop + ".price-quote",
		meta: {
			auth: true,
			name: "Trao đổi",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
				{
					name: "Trao đổi",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding/RequestBuyer/Chatting"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	// BUYER
	{
		path: "seller",
		name: prop + ".seller",
		meta: {
			auth: true,
			name: "Giao dịch bán",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
				{
					name: "Giao dịch bán",
				},
			],
		},
		children: [
			{
				path: "waiting",
				name: prop + ".waiting",
				meta: {
					auth: true,
					name: "Chờ xác nhận",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Chờ xác nhận",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBidding/Waiting"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "quoted",
				name: prop + ".quoted",
				meta: {
					auth: true,
					name: "Đã báo giá",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Đã báo giá",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBidding/Quoted"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "failed",
				name: prop + ".failed",
				meta: {
					auth: true,
					name: "Trượt thầu",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Trượt thầu",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBidding/Failed"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "passed",
				name: prop + ".passed",
				meta: {
					auth: true,
					name: "Trúng thầu",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Trúng thầu",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBidding/Passed"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
			{
				path: "s-not-quoted",
				name: prop + ".s-not-quoted",
				meta: {
					auth: true,
					name: "Chưa báo giá",
					breadcrumb: [
						{
							name: "Đấu thầu",
						},
						{
							name: "Chưa báo giá",
						},
					],
				},
				component: () => import("@B2B/views/Bidding/MyBidding/NotQuoted"),
				// component: () =>
				// 	import("@/components/custom/pages/LockPage/LockPage.vue"),
			},
		],
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding/MyBidding/MyBidding"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
	{
		path: "seller/:idTransaction/chat/:idChat",
		name: prop + ".customer-price-quote",
		meta: {
			auth: true,
			name: "Trao đổi",
			breadcrumb: [
				{
					name: "Đấu thầu",
				},
				{
					name: "Trao đổi",
				},
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@B2B/views/Bidding//RequestSeller/Chatting"),
		// component: () => import("@/components/custom/pages/LockPage/LockPage.vue"),
	},
];

export default [
	// *===============================================---*
	// *--------- B2B MARKET -------------------------------*
	// *===============================================---*
	{
		path: "/bidding",
		name: "bidding",
		beforeEnter: requiredAuth,
		component: () => import("@/layouts/Default"),
		children: biddingRoutes("bidding"),
	},
];
