import { firebaseAuth, firebaseStore } from "@/firebase/config";
import { v4 as uuid } from "uuid";
import { createRandomString } from "@/composables/helper/helper";
import { STATUS_PENDING } from "@B2B/constants/b2b-config";

export default {
	namespaced: true,
	state() {
		return {
			productInfo: null,
		};
	},
	getters: {},
	mutations: {
		SET_PRODUCT_INFO(state, payload) {
			state.productInfo = payload;
		},
		REMOVE_DATA(state) {
			state.productInfo = null;
		},
	},
	actions: {
		// CREATE POST
		async createPost({ commit, state, dispatch }, payload) {
			const id = `${uuid()}-${createRandomString(3)}`;
			const db = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("sellPosts");
			// get tour services
			const { form_stay, form_transportation } = state.productInfo;
			// post data
			const sellPost = {
				postId: id,
				...payload,
				...state.price,
				...state.productInfo,
				hashTagServices: [...form_stay, ...form_transportation],
				hashTags: [
					...payload.hashTags,
					...[...form_stay, ...form_transportation],
				],
			};
			console.log(sellPost);
			await db.doc(id).set(sellPost, { merge: true });
			await db
				.doc(id)
				.set({ ...sellPost, status: STATUS_PENDING }, { merge: true });
		},
	},
};
