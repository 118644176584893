// get list my product
export const GET_LIST_MY_PRODUCT = "GET_LIST_MY_PRODUCT";
export const FILTER_LIST_MY_PRODUCT = "FILTER_LIST_MY_PRODUCT";
export const SET_CAN_LOAD_MORE_LIST_PRODUCT = "SET_CAN_LOAD_MORE_LIST_PRODUCT";
export const SET_SEARCH_IN_LIST_PRODUCT_SELLER =
    "SET_SEARCH_IN_LIST_PRODUCT_SELLER";
// get list sell post
export const GET_DETAIL_PRODUCT = "GET_DETAIL_PRODUCT";
export const GET_LIST_SELL_POST = "GET_LIST_SELL_POST";
export const SET_CAN_LOAD_MORE_SELL_POST = "SET_CAN_LOAD_MORE_SELL_POST";
// get list buy post
export const GET_LIST_BUY_POST = "GET_LIST_BUY_POST";
export const SET_CAN_LOAD_MORE_BUY_POST = "SET_CAN_LOAD_MORE_BUY_POST";
export const DESTROY = "DESTROY";
