import { requiredAuth } from "./router-guards";
const formChildRoute = (prop, mode = false) => [
  {
    path: "suggestion",
    name: prop + ".layout",
    meta: {
      auth: true,
      name: "Góp ý sản phẩm",
      breadcrumb: [{ name: "Góp ý sản phẩm" }],
    },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/Suggestion"),
  },
];
export default [
  // *===============================================---*
  // *--------- FORMS -------------------------------*
  // *===============================================---*
  {
    path: "/",
    name: "form",
    component: () => import("@/layouts/Default"),
    meta: { auth: true },
    children: formChildRoute("form"),
    beforeEnter: requiredAuth,
  },
];
