import { marketSocial } from "@General/store/";

export default {
  namespaced: true,
  state() {
    return {
      ...marketSocial.state(),
    };
  },
  getters: {
    ...marketSocial.getters,
  },
  mutations: {
    ...marketSocial.mutations,
  },
  actions: {
    ...marketSocial.actions,
  },
};
