const guideChildRoutes = (prop, mode = false) => [
  {
    path: "term-of-use",
    name: prop + ".termofuse",
    meta: { name: "Điều khoản sử dụng" },
    component: () => import("@/views/policy/TermOfUse"),
  },
  {
    path: "privacy",
    name: prop + ".privacy",
    meta: { name: "Chính sách bảo mật" },
    component: () => import("@/views/policy/PrivacyPolicy"),
  },
  {
    path: "membership-agreement",
    name: prop + ".membershipagreement",
    meta: { name: "Thỏa thuận thành viên" },
    component: () => import("@/views/policy/MembershipAgreement"),
  },
  {
    path: "referral-code",
    name: prop + "_doc.referralcode",
    meta: { name: "Mã giới thiệu" },
    component: () => import("@/views/guide/ReferralCode/ReferralCode"),
  },
  {
    path: "",
    name: prop,
    redirect: { name: prop + ".termofuse" },
  },
];
export default [
  // *===============================================---*
  // *--------- guide -------------------------------*
  // *===============================================---*
  {
    path: "/guide",
    name: "guide",
    component: () => import("@/layouts/Guide"),
    children: guideChildRoutes("guide"),
  },
];
