import { requiredAuth } from "./router-guards";
const userChildRoute = (prop, mode = false) => [];
export default [
  // *===============================================---*
  // *--------- USERS -------------------------------*
  // *===============================================---*
  {
    path: "/user",
    name: "user",
    component: () => import("@/layouts/Default"),
    meta: { auth: true },
    children: userChildRoute("user"),
    beforeEnter: requiredAuth,
  },
];
