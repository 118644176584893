import { requiredAuth } from "./router-guards";
import { postChildRoutes as routes } from "@General/router/";

const postChildRoutes = (prop, mode = false) => [...routes(prop, mode)];

export default [
  // *===============================================---*
  // *--------- STORE -------------------------------*
  // *===============================================---*
  {
    path: "/post-details",
    name: "post-details",
    component: () => import("@/layouts/Default"),
    children: postChildRoutes("post"),
    beforeEnter: requiredAuth,
  },
];
