import { requiredAuth } from "@/router/routes/router-guards";

const socialChildRoutes = (prop, mode) => [
	{
		path: "",
		name: prop + ".list",
		meta: { auth: true, name: "Bảng Tin" },
		beforeEnter: requiredAuth,
		component: () => import("@General/views/Apps/Social/SocialApp"),
		children: [
			{
				path: "",
				name: prop + ".personal",
				meta: {
					auth: true,
					name: "Bảng tin cá nhân",
				},
				beforeEnter: requiredAuth,
				component: () =>
					import("@General/views/Apps/Social/feeds/PersonalFeed"),
			},
			{
				path: "agency-feed",
				name: prop + ".agency",
				meta: {
					auth: true,
					name: "Kênh phân phối",
				},
				beforeEnter: requiredAuth,
				component: () => import("@General/views/Apps/Social/feeds/AgencyFeed"),
				redirect: { name: prop + ".agency-feed" },
				children: [
					{
						path: "",
						name: prop + ".agency-feed",
						meta: {
							auth: true,
							name: "Nhà cung cấp",
						},
						beforeEnter: requiredAuth,
						component: () =>
							import("@General/views/Apps/Social/feeds/AgencyFeed/MyFeed"),
					},
					{
						path: "your-groups",
						name: prop + ".agency.your-groups",
						meta: {
							auth: true,
							name: "Nhà cung cấp",
						},
						beforeEnter: requiredAuth,
						component: () =>
							import("@General/views/Apps/Social/feeds/YourGroup"),
					},
				],
			},
			{
				path: "news-feed",
				name: prop + ".news",
				meta: {
					auth: true,
					name: "Kênh tin tức",
				},
				beforeEnter: requiredAuth,
				component: () => import("@General/views/Apps/Social/feeds/NewsFeed"),
			},
		],
		redirect: { name: "social.personal" },
	},
	{
		path: "notification",
		name: prop + ".notification",
		meta: {
			auth: true,
			name: "Thông báo",
			breadcrumb: [{ name: "Thông báo" }],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import("@General/views/SocailMain/Notifications/Notification"),
	},
	{
		path: "follow",
		name: prop + ".follow",
		meta: {
			auth: true,
			name: "Đang theo dõi",
			breadcrumb: [{ name: "Đang theo dõi" }],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/SocailMain/Follow/Follow"),
	},
	{
		path: "business-connect",
		name: "business" + ".business-connect",
		meta: {
			auth: true,
			name: "Kết Nối Doanh Nghiệp",
			breadcrumb: [{ name: "Kết Nối Doanh Nghiệp" }],
		},
		children: [
			{
				path: "following",
				name: "business" + ".following",
				meta: {
					auth: true,
					name: "Đối Tác Theo Dõi",
					breadcrumb: [{ name: "Kết Nối Doanh Nghiệp" }, { name: "Đối Tác Theo Dõi" }],
				},
				component: () => import('@/components/custom/posts/EnterpriseDatabase/CompanyFollow'),
			},
		],
		beforeEnter: requiredAuth,
		component: () =>
			import("@/components/custom/posts/EnterpriseDatabase/EnterpriseDatabase"),
	},
	// {
	// 	path: "business-connect/:id",
	// 	name: prop + ".business-details",
	// 	meta: { auth: true, name: "Thông tin doanh nghiệp" },
	// 	redirect: { name: "profile.profile-management" },
	// 	beforeEnter: requiredAuth,
	// 	component: () => import("@General/views/Pages/BusinessDetails"),
	// },
];

export { socialChildRoutes };
