import { Client } from "typesense";
const defaultKey = "YGBwSxr5s74TWArd398ekZtFrG5SCWXo";
const apiError = ["error"];

// Create a new client
const client = new Client({
  nodes: [
    {
      host: "qbflty9ac3hpmw0up.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
  apiKey: "",
  connectionTimeoutSeconds: 2,
});

const checkApiKeySearch = (key) => {
  if (key && !apiError.includes(key)) {
    client.apiCall.apiKey = key;
  } else {
    client.apiCall.apiKey = defaultKey;
  }
};

export { client, checkApiKeySearch };
