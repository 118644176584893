import { firebaseAuth, firebaseStore } from "@/firebase/config";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { createRandomString } from "@/composables/helper/helper";
import { DEPARTURE_DATE } from "@B2B/constants/b2b-grouptour";
import { CONFIG_DATE, STATUS_PENDING } from "@B2B/constants/b2b-config";
import _ from "lodash";

export default {
	namespaced: true,
	state() {
		return {
			productInfo: null,
			schedule: null,
			specificSchedules: null,
			price: null,
		};
	},
	getters: {},
	mutations: {
		SET_PRODUCT_INFO(state, payload) {
			state.productInfo = payload;
		},
		SET_SCHEDULE(state, payload) {
			state.schedule = payload;
		},
		SET_SPECIFIC_SCHEDULES(state, payload) {
			state.specificSchedules = payload
				? [...(state.specificSchedules ?? []), payload]
				: null;
		},
		SET_PRODUCT_PRICE(state, payload) {
			state.price = payload;
		},
		REMOVE_DATA(state) {
			state.price = null;
			state.productInfo = null;
			state.schedule = null;
			state.specificSchedules = null;
		},
	},
	actions: {
		// Check schedule data length
		checkSchedulesDataLength({ state }) {
			const { tour_type } = state.productInfo;
			if (tour_type === DEPARTURE_DATE) {
				if (
					Array.isArray(state.specificSchedules) &&
					state.specificSchedules.length
				) {
					return true;
				}
				return false;
			} else {
				if (state.schedule) {
					return true;
				}
				return false;
			}
		},
		// GENERATE SCHEDULES
		async generateDate({ state }, { id, tour_type }) {
			const db = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("sellPosts")
				.doc(id)
				.collection("schedules");
			const batch = firebaseStore.batch();
			// generate specific date & schedule
			if (
				tour_type === DEPARTURE_DATE &&
				Array.isArray(state.specificSchedules) &&
				state.specificSchedules.length
			) {
				const listDates = [];
				// batch set ref schedule items
				state.specificSchedules.map((schedule) => {
					const { date } = schedule;
					listDates.push(date);
					const scheduleId = `${uuid()}-${createRandomString(3)}`;
					const ref = db.doc(scheduleId);
					batch.set(
						ref,
						{ ...schedule, id: scheduleId, sell_post_id: id },
						{ merge: true }
					);
				});
				await batch.commit();
				return {
					listDates,
					mapSchedules: [
						...(state.specificSchedules.length <= 5
							? state.specificSchedules
							: _.takeRight(state.specificSchedules, 5)),
					],
				};
			}
			// generate list dates & schedules
			const { raw_date, price, listed_price, quantity } = state.schedule;
			const listDates = [];
			const listSchedules = [];
			for (let i = 0; i < CONFIG_DATE[tour_type].loop_times; i++) {
				const scheduleId = `${uuid()}-${createRandomString(3)}`;
				const date = moment(raw_date + CONFIG_DATE[tour_type].range * i).format(
					"DD/MM/YY"
				);
				listSchedules.push({
					sell_post_id: id,
					id: scheduleId,
					date: date,
					raw_date: raw_date + CONFIG_DATE[tour_type].range * i,
					quantity: quantity ?? 0,
					price,
					listed_price,
					ctnHolding: 0,
					ctnRemaining: 0,
					ctnConfirmed: 0,
				});
				listDates.push(date);
			}
			// batch set ref schedule items
			listSchedules.map((schedule) => {
				const ref = db.doc(schedule.id);
				batch.set(ref, schedule, { merge: true });
			});
			await batch.commit();
			return {
				listDates,
				mapSchedules: [
					...(listSchedules.length <= 5
						? listSchedules
						: _.takeRight(listSchedules, 5)),
				],
			};
		},
		// CREATE POST
		async createPost({ commit, state, dispatch }, payload) {
			const id = `${uuid()}-${createRandomString(3)}`;
			const db = firebaseStore
				.collection("userRef")
				.doc(firebaseAuth.currentUser.uid)
				.collection("sellPosts");
			// get tour services
			const { form_stay, form_transportation } = state.productInfo;
			// get tour type
			const { tour_type } = state.productInfo;
			// get array schedule dates
			const { listDates, mapSchedules } = await dispatch("generateDate", {
				id,
				tour_type,
			});
			// post data
			const sellPost = {
				postId: id,
				...payload,
				...state.price,
				...state.productInfo,
				hashTagServices: [...form_stay, ...form_transportation],
				hashTags: [
					...payload.hashTags,
					...[...form_stay, ...form_transportation],
				],
				schedule_dates: listDates,
				mapSchedules: mapSchedules,
			};
			await db.doc(id).set(sellPost, { merge: true });
			await db
				.doc(id)
				.set({ ...sellPost, status: STATUS_PENDING }, { merge: true });
		},
	},
};
