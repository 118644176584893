<template>
  <div class="delete-comment-modal">
    <div
      class="delete-comment-modal--header d-flex justify-content-center align-items-center"
    >
      <h4>Xoá bình luận?</h4>
      <button class="btn" @click="$emit('closeModal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="delete-comment-modal--body d-flex align-items-center">
      <p>{{ content }}</p>
    </div>
    <div
      class="delete-comment-modal--footer d-flex justify-content-end align-items-center"
    >
      <button class="btn" @click="$emit('closeModal')">Không</button>
      <button class="btn" @click="deleteComment">Xoá</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {
        type: String,
        required: false,
        default: "Bạn có chắc chắn muốn xoá bình luận này không?",
      },
    },
    name: "ModalDeleteComment",
    setup(props, { emit }) {
      const deleteComment = () => {
        emit("deletedCompleted");
      };

      return {
        deleteComment,
      };
    },
  };
</script>

<style scoped lang="scss">
  .delete-comment-modal {
    &--header {
      padding: 1rem 0.5rem;
      border-bottom: 1px solid $lightGrayHover;

      & h4 {
        font-weight: 600;
      }

      & button {
        position: absolute;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 50%;
        padding: 8px 10px;

        &:hover {
          background-color: $lightGrayHover;
          border: none;
        }
      }
    }

    &--body {
      padding: 0.5rem 1.5rem 1rem;

      & p {
        margin: 0;
      }
    }

    &--footer {
      padding: 0.5rem 1.5rem;

      & button {
        margin-left: 10px;
        border: none;

        &:hover,
        &:focus {
          background-color: $lightGrayHover;
          border: none;
        }

        &:last-child {
          background-color: #555770;
          color: #ffffff;

          &:hover,
          &:focus {
            background-color: darken($color: #555770, $amount: 15%);
          }
        }
      }
    }
  }
</style>
