import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";

export default {
  namespaced: true,
  state() {
    return {
      e3: null,
    };
  },
  getters: {
    userIdentity: (state) => {
      return state.e3;
    },
  },
  mutations: {
    SET_E3_IDENTITY(state, identity) {
      state.e3 = identity;
    },
  },
  actions: {},
};
