import {
  firebaseStore
} from "@/firebase/config";

import {
  STATUS_FINISH,
} from "@General/constants/ReqStatuses"

export default {
  async getAdsPost({
    commit,
    dispatch
  }) {
    const adsPostIds = await dispatch('getAdsPostIds');
    if (!adsPostIds) {
      return;
    } //end if

    const randId = adsPostIds[Math.floor(Math.random() * adsPostIds.length)];
    const socialPost = await dispatch('getSocialPost', randId);
    if (!socialPost) {
      return;
    } //end if

    commit("SET_PROCESS", STATUS_FINISH);
    commit("SET_ADS_POST", {
      data: socialPost,
      id: randId
    });
  },

  async getAdsPostIds({
    commit
  }) {
    const res = await firebaseStore
      .collection("boxInfo")
      .doc('socialPost');

    if (!res) {
      commit("SET_PROCESS");
      return null;
    } //end if

    const doc = await res.get();
    if (!doc.exists) {
      commit("SET_PROCESS");
      return null;
    } //end if

    const adsPostIds = doc.data().adsPost;
    if (!adsPostIds || !adsPostIds.length) {
      commit("SET_PROCESS");
      return null;
    } //end if

    return adsPostIds;
  },

  async getSocialPost({
    commit
  }, socialPostId) {
    const res = await firebaseStore
      .collection("zalofeed")
      .doc(socialPostId);

    const socialPost = await res.get();
    if (!socialPost.exists) {
      commit("SET_PROCESS");
      return null;
    } //end if

    return socialPost.data();
  }
};
