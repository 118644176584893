import {
	DEPARTURE_DAILY,
	DEPARTURE_MONTHLY,
	DEPARTURE_WEEKLY,
} from "@B2B/constants/b2b-grouptour";

const dayInWeek = {
	1: "Thứ 2",
	2: "Thứ 3",
	3: "Thứ 4",
	4: "Thứ 5",
	5: "Thứ 6",
	6: "Thứ 7",
	0: "Chủ nhật",
};

const STATUS_DRAFT = "draft";
const STATUS_PENDING = "pending";
const STATUS_ON_SELL = "onSell";
const STATUS_STOP = "stop";
const STATUS_CANCELED = "canceled";
const STATUS_EXPIRED = "expired";

const MAX_DAYS_GENERATE = 90;
const CONFIG_DATE = {
	[DEPARTURE_DAILY]: {
		range: 24 * 60 * 60 * 1000,
		steps: 1,
		loop_times: MAX_DAYS_GENERATE,
	},
	[DEPARTURE_WEEKLY]: {
		range: 7 * 24 * 60 * 60 * 1000,
		steps: 7,
		loop_times: Math.floor(MAX_DAYS_GENERATE / 7),
	},
	[DEPARTURE_MONTHLY]: {
		range: 30 * 24 * 60 * 60 * 1000,
		steps: 30,
		loop_times: Math.floor(MAX_DAYS_GENERATE / 30),
	},
};

export {
	dayInWeek,
	CONFIG_DATE,
	STATUS_DRAFT,
	STATUS_PENDING,
	STATUS_ON_SELL,
	STATUS_STOP,
	STATUS_CANCELED,
	STATUS_EXPIRED,
};
