<template>
  <div class="send-suggestion-modal">
    <div
      class="send-suggestion-modal--header d-flex justify-content-center align-items-center"
    >
      <h4>{{ headerText }}</h4>
      <button class="btn" @click="closeModal">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="send-suggestion-modal--body d-flex align-items-center">
      <p>
        {{ bodyText }}
        <br/>
        <span class="d-block">Xin chân thành cảm ơn!</span>
      </p>
    </div>
    <div
      class="send-suggestion-modal--footer d-flex justify-content-end align-items-center"
    >
      <button class="btn" @click="closeModal">OK</button>
    </div>
  </div>
</template>

<script>
  export default {
  name: "ModalSendSuggestion",
  props: {
    headerText: { type: String, required: true, default: "Title"},
    bodyText: { type: String, required: true, default: "Body"}
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    }

    return {
      closeModal
    }
  },
};
</script>

<style lang="scss" scoped>
  .send-suggestion-modal {
    &--header {
      padding: 1rem 0.5rem;
      border-bottom: 1px solid $lightGrayHover;

      & h4 {
        font-weight: 600;
      }

      & button {
        position: absolute;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 50%;
        padding: 8px 10px;

        &:hover {
          background-color: $lightGrayHover;
          border: none;
        }
      }
    }

    &--body {
      padding: 0.5rem 1.5rem;

      & p {
        margin: 0;
      }
    }

    &--footer {
      padding: 0.5rem 1.5rem;

      & button {
        margin-left: 10px;
        border: none;

        &:hover,
        &:focus {
          background-color: $lightGrayHover;
          border: none;
        }

        &:last-child {
          background-color: #555770;
          color: #ffffff;

          &:hover,
          &:focus {
            background-color: darken($color: #555770, $amount: 15%);
          }
        }
      }
    }
  }
</style>