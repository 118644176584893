const appChildRoute = (prop, mode = false) => [
  {
    path: ":target/:slug/:id",
    name: prop + ".public",
    meta: {
      name: "Chia sẻ",
    },
    component: () => import("@/views/share/SharePost"),
  },
];

export default [
  // *===============================================---*
  // *--------- APPS -------------------------------*
  // *===============================================---*
  {
    path: "/public",
    name: "public",
    component: () => import("@/layouts/Public"),
    children: appChildRoute("public"),
  },
];
