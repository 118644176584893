import { VIEWS_TYPE, INTERACTIONS_TYPE } from "@/constants/PostScore";
import { firebaseAuth } from "@/firebase/config";
import {
  sendPostScore,
  sendPostScoreOutlive,
} from "@General/composables/API/PrivateAPI/SendPostScore";
export default {
  namespaced: true,
  state() {
    return {
      [VIEWS_TYPE]: null,
      [INTERACTIONS_TYPE]: null,
    };
  },
  getters: {},
  mutations: {
    SET_POSTS_SCORE(state, { type, postId, action }) {
      if (state[type]?.posts) {
        if (state[type].posts[postId]) {
          state[type].posts[postId] = {
            ...state[type].posts[postId],
            ...action,
          };
        } else {
          state[type].posts[postId] = {
            ...action,
          };
        }
      } else {
        state[type] = {
          posts: {
            [postId]: {
              ...action,
            },
          },
        };
      }
      state[type] = {
        ...state[type],
        user_key: firebaseAuth.currentUser.uid,
      };
    },
    RESET_POST_SCORE(state) {
      state[VIEWS_TYPE] = null;
      state[INTERACTIONS_TYPE] = null;
    },
  },
  actions: {
    async sendPostScore({ commit, state }) {
      if (state[VIEWS_TYPE]) {
        await sendPostScore(state[VIEWS_TYPE], VIEWS_TYPE);
      }
      if (state[INTERACTIONS_TYPE]) {
        await sendPostScore(state[INTERACTIONS_TYPE], INTERACTIONS_TYPE);
      }
      commit("RESET_POST_SCORE");
    },
    sendPostScoreOutlive({ state, commit }) {
      if (state[VIEWS_TYPE]) {
        sendPostScoreOutlive(state[VIEWS_TYPE], VIEWS_TYPE);
      }
      if (state[INTERACTIONS_TYPE]) {
        sendPostScoreOutlive(state[INTERACTIONS_TYPE], INTERACTIONS_TYPE);
      }
      commit("RESET_POST_SCORE");
    },
  },
};
