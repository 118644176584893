import { firebaseStore } from "@/firebase/config";

const FOLLOW_KEY = "follow";
const MEMBERS_KEY = "members";
const AGENCY_KEY = "agency";

const limitGet = 10;
const limitLoadMore = 10;

const OPTIONAL_MODE = "optional";
const ALL_FOLLOWERS = "all_followers";
const PUBLIC_MODE = "public";

const METHOD_PAGE = {
	[FOLLOW_KEY]: {
		collection: "followPosts",
		create: (pageId, post, postId) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("followPosts")
				.doc(postId)
				.set(post, { merge: true }),
		label: "Theo dõi",
	},
	[AGENCY_KEY]: {
		collection: "agencyPosts",
		get: (pageId) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("agency")
				.orderBy("timestamp", "desc")
				.limit(limitGet)
				.get(),
		load_more: (pageId, lastDoc) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("agency")
				.orderBy("timestamp", "desc")
				.startAfter(lastDoc.timestamp)
				.limit(limitLoadMore)
				.get(),
		create: (pageId, post, postId) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("agencyPosts")
				.doc(postId)
				.set(post, { merge: true }),
		label: "Đăng ký",
	},
	[MEMBERS_KEY]: {
		collection: "memberPosts",
		get: (pageId) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("orgMember")
				.orderBy("timestamp", "desc")
				.limit(limitGet)
				.get(),
		load_more: (pageId, lastDoc) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("orgMember")
				.orderBy("timestamp", "desc")
				.startAfter(lastDoc.timestamp)
				.limit(limitLoadMore)
				.get(),
		create: (pageId, post, postId) =>
			firebaseStore
				.collection("pages")
				.doc(pageId)
				.collection("memberPosts")
				.doc(postId)
				.set(post, { merge: true }),
		label: "Đăng ký",
	},
};

export {
	FOLLOW_KEY,
	MEMBERS_KEY,
	AGENCY_KEY,
	METHOD_PAGE,
	limitGet,
	limitLoadMore,
	OPTIONAL_MODE,
	ALL_FOLLOWERS,
	PUBLIC_MODE,
};
