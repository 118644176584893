import business from "@/app_modules/General/store/business/business";

export default {
  namespaced: true,

  state() {
    return {
      ...business.state(),
    };
  },
  getters: {
    ...business.getters,
  },
  mutations: {
    ...business.mutations,
  },
  actions: {
    ...business.actions,
  },
};
