import { defineAsyncComponent } from "vue";

const registerGlobalComponent = (app) => {
  // Global Components
  //   app.component(
  //     "iq-card",
  //     require("./components/custom/cards/iq-card").default
  //   );
  app.component(
    "iq-card",
    defineAsyncComponent(() => import("@/components/custom/cards/iq-card"))
  );
  app.component(
    "loader",
    defineAsyncComponent(() => import("@/components/custom/loader/Loader"))
  );
  app.component(
    "alert",
    defineAsyncComponent(() => import("@/components/bootstrap/alert/alert"))
  );
  app.component(
    "carousel",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/carousel/carousel")
    )
  );
  app.component(
    "carouselItems",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/carousel/carouselItems")
    )
  );
  app.component(
    "toast",
    defineAsyncComponent(() => import("@/components/bootstrap/toasts/toast"))
  );
  app.component(
    "toast-header",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/toasts/toast-header")
    )
  );
  app.component(
    "toast-body",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/toasts/toast-body")
    )
  );
  app.component(
    "progressbar",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/Progressbar/progressbar")
    )
  );
  app.component(
    "popover",
    defineAsyncComponent(() => import("@/components/bootstrap/popover/popover"))
  );
  app.component(
    "tooltip",
    defineAsyncComponent(() => import("@/components/bootstrap/tooltip/tooltip"))
  );
  // app.component('Lottie', defineAsyncComponent(() => import('@/components/socialapp/lottie/Lottie'))
  app.component(
    "tab-nav",
    defineAsyncComponent(() => import("@/components/bootstrap/tab/tab-nav"))
  );
  app.component(
    "tab-nav-items",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/tab/tab-nav-items")
    )
  );
  app.component(
    "tab-content",
    defineAsyncComponent(() => import("@/components/bootstrap/tab/tab-content"))
  );
  app.component(
    "tab-content-item",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/tab/tab-content-item")
    )
  );
  app.component(
    "dropdown",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/dropdown/dropdown")
    )
  );
  app.component(
    "dropdown-items",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/dropdown/dropdown-items")
    )
  );
  app.component(
    "collapse",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/collapse/collapse")
    )
  );
  app.component(
    "collapse-content",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/collapse/collapse-content")
    )
  );
  app.component(
    "modal",
    defineAsyncComponent(() => import("@/components/bootstrap/modal/modal"))
  );
  app.component(
    "model-header",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/modal/model-header")
    )
  );
  app.component(
    "model-body",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/modal/model-body")
    )
  );
  app.component(
    "model-footer",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/modal/model-footer")
    )
  );
  app.component(
    "offcanvas",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/offcanvas/offcanvas")
    )
  );
  app.component(
    "offcanvas-header",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/offcanvas/offcanvas-header")
    )
  );
  app.component(
    "offcanvas-body",
    defineAsyncComponent(() =>
      import("@/components/bootstrap/offcanvas/offcanvas-body")
    )
  );
  app.component(
    "auth-layout",
    defineAsyncComponent(() =>
      import("@/components/custom/auth/AuthLayout.vue")
    )
  );
  app.component(
    "input-group",
    defineAsyncComponent(() =>
      import("@/components/custom/form/InputGroup.vue")
    )
  );
  app.component(
    "default-logo",
    defineAsyncComponent(() => import("@/components/custom/logo/DefaultLogo"))
  );
  app.component(
    "primary-button",
    defineAsyncComponent(() =>
      import("@/components/custom/buttons/PrimaryButton")
    )
  );
  app.component(
    "logo-white",
    defineAsyncComponent(() => import("@/components/custom/logo/LogoWhite"))
  );
  app.component(
    "crop-logo",
    defineAsyncComponent(() => import("@/components/custom/logo/CropLogo"))
  );
  app.component(
    "logo-text",
    defineAsyncComponent(() => import("@/components/custom/logo/LogoText"))
  );
  app.component(
    "circle-loader",
    defineAsyncComponent(() =>
      import("@/components/custom/animate/CircleLoader")
    )
  );
  app.component(
    "lock-page",
    defineAsyncComponent(() =>
      import("@/components/custom/pages/LockPage/LockPage")
    )
  );
  app.component(
    "no-results-found",
    defineAsyncComponent(() =>
      import("@/components/custom/icon/NoResultsFound")
    )
  );
  app.component(
    "loading-icon",
    defineAsyncComponent(() => import("@/components/custom/icon/Loading"))
  );
  app.component(
    "error-file",
    defineAsyncComponent(() => import("@/components/custom/icon/ErrorFile"))
  );
  app.component(
    "not-verified",
    defineAsyncComponent(() => import("@/components/custom/badge/NotVerified"))
  );
  app.component(
    "is-verified",
    defineAsyncComponent(() => import("@/components/custom/badge/IsVerified"))
  );
  app.component(
    "demo-post",
    defineAsyncComponent(() => import("@/components/custom/posts/DemoPost"))
  );
  app.component(
    "new-notification-dot",
    defineAsyncComponent(() =>
      import("@/components/custom/icon/NewNotificationDot")
    )
  );
  app.component(
    "previous-back",
    defineAsyncComponent(() => import("@/components/custom/icon/PreviousBack"))
  );
  app.component(
    "Breadcrumb",
    defineAsyncComponent(() =>
      import("@/components/custom/Breadcrumb/Breadcrumb")
    )
  );
  app.component(
    "image-skeleton",
    defineAsyncComponent(() =>
      import("@/components/custom/loading/ImageSkeleton")
    )
  );
};

export default registerGlobalComponent;
