const listBuyPost = (state) => {
    return state.listBuyPost;
};

const listSellPost = (state) => {
    return state.listSellPost;
};

const listMyProduct = (state) => {
    return state.listMyProduct;
};

const detailProduct = (state) => {
    return state.detailProduct;
};

const searchInListProductSeller = (state) => {
    return state.searchInListProductSeller;
};

const canLoadMoreListProduct = (state) => {
    return state.canLoadMoreListProduct;
};

const canLoadMoreSellPost = (state) => {
    return state.canLoadMoreSellPost;
};

const canLoadMoreBuyPost = (state) => {
    return state.canLoadMoreBuyPost;
};

export default {
    listBuyPost,
    listSellPost,
    listMyProduct,
    detailProduct,
    searchInListProductSeller,
    canLoadMoreListProduct,
    canLoadMoreSellPost,
    canLoadMoreBuyPost,
};
