import { requiredAuth } from "./router-guards";
import { socialChildRoutes } from "@General/router/";

const childRoutes = (prop, mode) => [
  ...socialChildRoutes(prop, mode),
  {
    path: "accountsetting",
    name: prop + ".accountsetting",
    meta: { auth: true, name: "Account Setting" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/User/AccountSetting"),
  },
  {
    path: "privacysetting",
    name: prop + ".privacy-setting",
    meta: { auth: true, name: "Privacy Setting" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/User/PrivacySetting"),
  },
  {
    path: "privacypolicy",
    name: prop + ".privacy",
    meta: { auth: true, name: "Privacy Policy" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/PrivacyPolicy"),
  },
  {
    path: "termofservice",
    name: prop + ".termofservice",
    meta: { auth: true, name: "Terms Of Use" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/Pages/TermsOfService"),
  },
];

export default [
  // *===============================================---*
  // *--------- SOCIAL -------------------------------*
  // *===============================================---*
  {
    path: "/",
    name: "social",
    beforeEnter: requiredAuth,
    component: () => import("@/layouts/Default"),
    children: childRoutes("social"),
  },
];
