<template>
  <div
    class="modal custom-modal fade show"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-modal="true"
    role="dialog"
    style="
      display: block;
      padding-left: 0px;
      padding-right: 0px;
      z-index: 9999999;
    "
  >
    <div
      class="modal-dialog"
      :class="{ 'modal-dialog-centered': position === 'center' }"
    >
      <div class="modal-content" style="z-index: 1051">
        <component
          v-if="!useSlot"
          :category="category"
          :hashTags="hashTags"
          :alertText="alertText"
          :is="modal"
          @closeModal="$emit('closeModal')"
          @deletedCompleted="$emit('deletedCompleted')"
        ></component>
        <slot v-else />
      </div>
    </div>
    <div class="modal-backdrop fade show" @click="$emit('closeModal')"></div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import ContactForm from "@General/components/custom/modal/ContactForm/ContactForm.vue";
import ModalDelete from "@General/components/custom/modal/ModalDelete/ModalDelete.vue";
import VerificationRequest from "@General/components/custom/modal/VerificationRequest/VerificationRequest.vue";
import ErrorFileVue from "../icon/ErrorFile.vue";
import ModalDeleteComment from "@General/components/custom/modal/ModalDeleteComment/ModalDeleteComment.vue";
import ModalSendSuggestion from "./ModalSendSuggestion/ModalSendSuggestion.vue";
import ModalQuote from "@General/components/custom/modal/ModalQuote/ModalQuote.vue";

export default {
  components: {
    ContactForm,
    ModalDelete,
    VerificationRequest,
    ErrorFileVue,
    ModalDeleteComment,
    ModalSendSuggestion,
    ModalQuote,
  },
  props: {
    modal: {
      type: String,
      required: false,
    },
    category: {
      type: String,
      required: false,
      default: "",
    },
    hashTags: {
      type: String,
      required: false,
      default: "",
    },
    alertText: {
      type: String,
      required: false,
      default: "",
    },
    position: {
      type: String,
      required: false,
      default: "center",
    },
    useSlot: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  setup() {
    onMounted(() => {
      document.body.classList.add("no-scrolling");
    });
    onUnmounted(() => {
      document.body.classList.remove("no-scrolling");
    });
  },
};
</script>

<style lang="scss" scoped>
.custom-modal {
  &::-webkit-scrollbar {
    display: none;
  }
}
.modal-content {
  -webkit-animation: scale-down 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
</style>
