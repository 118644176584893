import { firebaseStore, firebaseAuth } from "@/firebase/config";
import {
	PRODUCT_ON_SELL,
	PRODUCT_PENDING,
} from "@B2B/constants/b2b-my-products";

const state = () => {
	return {
		listProducts: null,
		listProductsPending: null,
		mode: null,
		endPoint: {},
	};
};

const mutations = {
	SET_LIST_PRODUCTS(state, payload) {
		state.listProducts = payload;
	},
	SET_PRODUCTS_PENDING(state, payload) {
		state.listProductsPending = payload;
	},
	SET_MORE_PRODUCTS(state, payload) {
		state.listProducts = [
			...(Array.isArray(state.listProducts) ? state.listProducts : []),
			...payload,
		];
	},
	SET_MORE_PRODUCTS_PENDING(state, payload) {
		state.listProductsPending = [
			...(Array.isArray(state.listProductsPending)
				? state.listProductsPending
				: []),
			...payload,
		];
	},

	SET_END_POINT(state, status) {
		if (state.mode) {
			state.endPoint[state.mode] = status;
			return;
		}
		state.endPoint = {};
	},
	SET_MODE(state, payload) {
		state.mode = payload;
	},
	REMOVE_STORE(state) {
		state.listProducts = null;
		state.listProductsPending = null;
		state.mode = null;
		state.endPoint = {};
	},
};

const getters = {
	lastProduct: (state) => {
		switch (state.mode) {
			case PRODUCT_ON_SELL:
				return state.listProducts[state.listProducts.length - 1];
			case PRODUCT_PENDING:
				return state.listProductsPending[state.listProductsPending.length - 1];
			default:
				return {};
		}
	},
};

const actions = {
	// Danh sách sản phẩm
	async getListProducts({ commit }, { category, limit }) {
		let products = [];
		let db = firebaseStore
			.collection("userRef")
			.doc(firebaseAuth.currentUser.uid)
			.collection("sellPostsOnSell");
		if (category) {
			db = db.where("typePost", "==", category);
		}
		// .orderBy("dateCreated", "desc")
		const res = await db.limit(limit).get();
		if (res.size > 0) {
			products = [
				...res.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				}),
			];
			if (products.length < limit) {
				commit("SET_END_POINT", true);
			}
		}
		commit("SET_LIST_PRODUCTS", products);
	},

	// Danh sách sản phẩm
	async getListProductsPending({ commit }, limit) {
		let products = [];
		let db = firebaseStore
			.collection("userRef")
			.doc(firebaseAuth.currentUser.uid)
			.collection("sellPostsPending");
		const res = await db.limit(limit).orderBy("dateCreated", "desc").get();
		if (res.size > 0) {
			products = [
				...res.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				}),
			];
			if (products.length < limit) {
				commit("SET_END_POINT", true);
			}
		}
		commit("SET_PRODUCTS_PENDING", products);
	},
	// load more products
	async getMoreProductsOnSell({ commit, getters }, { category, limit }) {
		let products = [];
		const lastProduct = getters.lastProduct;
		let db = firebaseStore
			.collection("userRef")
			.doc(firebaseAuth.currentUser.uid)
			.collection("sellPostsOnSell");
		if (category) {
			db = db.where("typePost", "==", category);
		}
		const res = await db
			.limit(limit)
			.orderBy("dateCreated", "desc")
			.startAfter(lastProduct.dateCreated)
			.get();
		if (res.size > 0) {
			products = [
				...res.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				}),
			];
			if (products.length < limit) {
				commit("SET_END_POINT", true);
			}
		}
		commit("SET_MORE_PRODUCTS", products);
	},
	// load more products pending
	async getMoreProductsPending({ commit, getters }, limit) {
		let products = [];
		const lastProduct = getters.lastProduct;
		let db = firebaseStore
			.collection("userRef")
			.doc(firebaseAuth.currentUser.uid)
			.collection("sellPostsPending");
		const res = await db
			.limit(limit)
			.orderBy("dateCreated", "desc")
			.startAfter(lastProduct.dateCreated)
			.get();
		if (res.size > 0) {
			products = [
				...res.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				}),
			];
			if (products.length < limit) {
				commit("SET_END_POINT", true);
			}
		}
		commit("SET_MORE_PRODUCTS_PENDING", products);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
