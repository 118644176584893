<template>
  <div>
    <div class="modal-header">
      <h5 class="modal-title fw-bold">Xác nhận xóa</h5>
      <button
        type="button"
        class="btn-close"
        @click="$emit('closeModal')"
        aria-label="Close"
      ></button
      ><!--v-if-->
    </div>
    <div class="modal-body">
      <p>
        {{ content }}
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" @click="$emit('closeModal')">
        Hủy</button
      ><button
        @click="removePost"
        type="button"
        class="btn text-white bg-error-only border-0"
        :disabled="isPending"
        :class="{ 'button-size': isPending }"
      >
        <span v-if="!isPending">Xóa tin bán</span>
        <circle-loader v-else />
      </button>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import { ref } from "vue";
  import { handleError } from "@/composables/handle_error/HandleError";
  import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";

  export default {
    emits: ["closeModal", "deletedCompleted"],
    props: {
      content: {
        type: String,
        required: false,
        default:
          "Một khi bạn đồng ý, tin bán này sẽ không còn tồn tại nữa và bạn sẽ không thể hoàn tác hành động này.",
      },
      category: {
        type: String,
        required: false,
        default: "",
      },
      hashTags: {
        type: String,
        required: false,
        default: "",
      },
    },
    setup(props, { emit }) {
      const isPending = ref(false);
      const store = useStore();
      const removePost = async () => {
        isPending.value = true;
        try {
          await store.dispatch("marketSocial/removePost", {
            category: props.category,
            hashTags: props.hashTags,
          });
          emit("closeModal");
          emit("deletedCompleted");
        } catch (error) {
          handleError(error.message);
          const line = getErrorLine();
          sendErrorToSlack("error", error, line);
        } finally {
          isPending.value = false;
        }
      };

      return { removePost, isPending };
    },
  };
</script>

<style></style>
