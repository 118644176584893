<template>
  <div>
    <div class="modal-header">
      <h5 class="modal-title fw-bold text-medium">Thông báo</h5>
      <span @click="$emit('closeModal')" class="cursor-pointer">
        <i class="fas fa-times text-large"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="form-notification">
        <div class="new-tab-image text-center">
          <img
            src="@/assets/images/icon/adblock.svg"
            alt="New tab icon"
            class="avatar-100"
          />
        </div>
        <div class="content text-center mt-3">
          <h4 class="text-medium fw-bold">Đã phát hiện chặn quảng cáo</h4>
          <p class="mb-0 mt-2">
            Vui lòng tắt chặn quảng cáo trên website của chúng tôi để có trải
            nghiệm tốt nhất.
          </p>
        </div>
        <div class="d-flex justify-content-center mt-3 align-items-center">
          <button class="btn btn-primary" @click="$emit('closeModal')">
            Đã hiểu
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style></style>
