import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

const firebaseConfig = {
  apiKey: "AIzaSyAwOVjSeTuW2X3_bNqzX70gNc_C5jdtBIM",
  authDomain: "tc-tourguide.firebaseapp.com",
  databaseURL: "https://tc-tourguide.firebaseio.com",
  projectId: "tc-tourguide",
  storageBucket: "tc-tourguide.appspot.com",
  messagingSenderId: "60317737911",
  appId: "1:60317737911:web:51e88baebc8e1a4611fe32",
  measurementId: "G-CYK38Z4ZK5",
};

// init firebase
firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth();

//init firestore service
const firebaseStore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
//init firestorage service
const firebaseStorage = firebase.storage();
const firebaseMessaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
const firebaseFunction = firebase.functions();

export {
  firebase,
  firebaseStore,
  timestamp,
  firebaseAuth,
  firebaseStorage,
  firebaseMessaging,
  firebaseFunction,
};
