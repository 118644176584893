import { requiredAuth } from "@/router/routes/router-guards";

const dashboardChildRoutes = (prop, mode) => [
	{
		path: "",
		name: prop + ".staff-management",
		meta: {
			auth: true,
			name: "Quản Lý Nhân Viên",
			breadcrumb: [
				{ name: "Quản Lý Doanh Nghiệp" },
				{ name: "Quản Lý Nhân Viên" },
			],
		},
		beforeEnter: requiredAuth,
		components: {
			business_dashboard: () =>
				import(
					"@General/components/custom/business_page/Dashboard/StaffManagement/ListStaff.vue"
				),
		},
	},
	{
		path: "collaborator-management",
		name: prop + ".collaborator-management",
		meta: {
			auth: true,
			name: "Quản Lý CTV",
			breadcrumb: [{ name: "Quản Lý Doanh Nghiệp" }, { name: "Quản Lý CTV" }],
		},
		beforeEnter: requiredAuth,
		components: {
			business_dashboard: () =>
				import(
					"@General/components/custom/business_page/Dashboard/CollaboratorManagement/ListCollaborator.vue"
				),
		},
	},
	{
		path: "agency-management",
		name: prop + ".agency-management",
		meta: {
			auth: true,
			name: "Quản Lý Đại Lý",
			breadcrumb: [
				{ name: "Quản Lý Doanh Nghiệp" },
				{ name: "Quản Lý Đại Lý" },
			],
		},
		beforeEnter: requiredAuth,
		components: {
			business_dashboard: () =>
				import(
					"@General/components/custom/business_page/Dashboard/AgencyManagement/ListAgency.vue"
				),
		},
	},
	{
		path: "member-management",
		name: prop + ".member-management",
		meta: {
			auth: true,
			name: "Quản Lý Thành viên",
			breadcrumb: [
				{ name: "Quản Lý Hiệp hội" },
				{ name: "Quản Lý Thành viên" },
			],
		},
		beforeEnter: requiredAuth,
		components: {
			business_dashboard: () =>
				import(
					"@General/components/custom/business_page/Dashboard/MemberManagement/ListMember.vue"
				),
		},
	},
];
const profileChildRoutes = (prop, mode) => [
	// hồ sơ user khác
	{
		path: ":id",
		name: prop + ".business-profile-container",
		meta: {
			auth: true,
			name: "Hồ sơ doanh nghiệp",
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/Profile/BusinessProfileContainer"),
	},
	// hồ sơ công ty
	{
		path: "business-profile/:id?",
		name: prop + ".business-profile",
		meta: { auth: true, name: "Hồ Sơ Doanh Nghiệp" },
		beforeEnter: requiredAuth,
		component: () => import("@General/views/Profile/BusinessProfile"),
	},
	// {
	// 	path: "profile-management",
	// 	name: prop + ".profile-management",
	// 	meta: {
	// 		auth: true,
	// 		name: "Quản Lý Hồ Sơ",
	// 		breadcrumb: [{ name: "Quản Lý Hồ Sơ" }],
	// 	},
	// 	beforeEnter: requiredAuth,
	// 	component: () => import("@General/views/Profile/ProfileManagement"),
	// },
	{
		path: "create-profile",
		name: prop + ".create-profile",
		meta: {
			auth: true,
			name: "Tạo Hồ Sơ",
			breadcrumb: [
				{ name: "Quản Lý Hồ Sơ", link: prop + ".profile-management" },
				{ name: "Tạo Hồ Sơ" },
			],
		},
		beforeEnter: requiredAuth,
		component: () => import("@General/views/ProfileSetup/CreateProfile"),
	},
	{
		path: "verify-profile",
		name: prop + ".verify-profile",
		meta: {
			auth: true,
			name: "Xác Thực Hồ Sơ",
			breadcrumb: [
				{ name: "Quản Lý Hồ Sơ", link: prop + ".profile-management" },
				{ name: "Tạo Hồ Sơ", link: prop + ".create-profile" },
				{ name: "Xác Thực Hồ Sơ" },
			],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import("@General/views/ProfileSetup/VerifyProfile/VerifyProfile"),
	},
	{
		path: "update-legal-info",
		name: prop + ".update-legal-info",
		meta: {
			auth: true,
			name: "Cập nhật thông tin pháp lý",
			breadcrumb: [
				{ name: "Hồ sơ doanh nghiệp", link: prop + ".business-profile" },
				{ name: "Cập nhật thông tin pháp lý" },
			],
		},
		beforeEnter: requiredAuth,
		component: () =>
			import(
				"@General/components/custom/business_page/Update/About/LegalInfo/PageLegal"
			),
	},

	// *===============================================---*
	// *--------- BUSINESS DASHBOARD ---------------------*
	// *===============================================---*
	{
		path: "business-dashboard",
		name: prop + ".business-dashboard",
		meta: {
			auth: true,
			name: "Quản Lý Doanh Nghiệp",
			breadcrumb: [
				{ name: "Quản Lý Hồ Sơ", link: prop + ".profile-management" },
				{ name: "Quản Lý Doanh Nghiệp" },
			],
		},
		components: {
			business: () =>
				import(
					"@General/components/custom/business_page/Dashboard/BusinessDashboard.vue"
				),
		},
		children: dashboardChildRoutes("business-dashboard"),
		beforeEnter: requiredAuth,
		redirect: { name: "business-dashboard.staff-management" },
	},
];

export { /*dashboardChildRoutes,*/ profileChildRoutes };
