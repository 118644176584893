const state = {
    listBuyPost: [],
    listMyProduct: [],
    listSellPost: [],
    detailProduct: {},
    searchInListProductSeller: {
        categoryType: "",
        searchKey: "",
    },
    canLoadMoreListProduct: true,
    canLoadMoreSellPost: true,
    canLoadMoreBuyPost: true,
};

export default {
    default: () => {
        return JSON.parse(JSON.stringify(state));
    },
};
