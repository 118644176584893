import { user_activities } from "@General/store/";

export default {
  namespaced: true,
  state() {
    return {
      ...user_activities.state(),
    };
  },
  getters: { ...user_activities.getters },
  mutations: {
    ...user_activities.mutations,
  },
  actions: {
    ...user_activities.actions,
  },
};
