import { requiredAuth } from "./router-guards";
import { managementChildRoutes } from "@General/router/";

const managementRoutes = (prop, mode = false) => [
  // intro
  {
    path: "",
    name: prop + ".intro",
    meta: { auth: true, name: "Quản lý tài khoản" },
    beforeEnter: requiredAuth,
    component: () => import("@/views/manage/Intro"),
    redirect: { name: "profile.profile-management" },
  },
  ...managementChildRoutes(prop, mode),
];

export default [
  // *===============================================---*
  // *--------- MANAGER -------------------------------*
  // *===============================================---*
  {
    path: "/manage",
    name: "manage",
    component: () => import("@/layouts/Manage"),
    children: managementRoutes("manage"),
    beforeEnter: requiredAuth,
  },
];
