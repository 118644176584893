import { firebaseStore, firebaseAuth } from "@/firebase/config";
import * as MUTATIONS_TYPE from "./types";
import { ref } from "vue";

// List product
const lastVisibleDocListProduct = ref(null);
const limitListProduct = ref(10);

// Sell post
const lastVisibleDocSellPost = ref(null);
const limitSellPost = ref(10);

// Sell post
const lastVisibleDocBuyPost = ref(null);
const limitBuyPost = ref(10);

/**
 * get list buy post
 * @param commit
 * @param data
 */
const getListBuyPost = async ({ commit }, { loading }) => {
    await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("buyPosts")
        .orderBy("dateUpDate", "desc")
        .limit(limitBuyPost.value)
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocBuyPost.value = data.docs[dataLength - 1];
            if (dataLength < limitBuyPost.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_BUY_POST, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_BUY_POST, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.GET_LIST_BUY_POST, arr);
            loading();
        })
        .catch((err) => console.log(err));
};

// Load more buy post
const loadMoreBuyPost = async ({ commit }) => {
    firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("buyPosts")
        .orderBy("dateUpDate", "desc")
        .startAfter(lastVisibleDocBuyPost.value)
        .limit(limitBuyPost.value)
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocBuyPost.value = data.docs[dataLength - 1];
            if (dataLength < limitBuyPost.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_BUY_POST, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_BUY_POST, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.GET_LIST_BUY_POST, arr);
        });
};

/**
 * get list my product
 * @param commit
 * @param data
 */
const getListMyProduct = async ({ commit }, { key, loading }) => {
    let userSellPostCollection = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("sellPosts");
    if (key) {
        userSellPostCollection = userSellPostCollection.where(
            "typePost",
            "==",
            key
        );
    }
    userSellPostCollection
        .limit(limitListProduct.value)
        .orderBy("timestamp", "desc")
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocListProduct.value = data.docs[dataLength - 1];
            if (dataLength < limitListProduct.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.GET_LIST_MY_PRODUCT, arr);
            loading();
        })
        .catch((err) => console.log(err));
};
/**
 * filter list my product
 * @param commit
 * @param data
 */
const filterListProduct = async ({ commit }, { key, loading }) => {
    let userSellPostCollection = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("sellPosts");
    if (key) {
        userSellPostCollection = userSellPostCollection.where(
            "typePost",
            "==",
            key
        );
    }
    userSellPostCollection
        .limit(limitListProduct.value)
        .orderBy("timestamp", "desc")
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocListProduct.value = data.docs[dataLength - 1];
            if (dataLength < limitListProduct.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.FILTER_LIST_MY_PRODUCT, arr);
            loading();
        })
        .catch((err) => console.log(err));
};
// Load more list product
const loadMoreListProduct = async ({ commit }, { key }) => {
    let userSellPostCollection = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("sellPosts");
    if (key) {
        userSellPostCollection = userSellPostCollection.where(
            "typePost",
            "==",
            key
        );
    }
    userSellPostCollection
        .orderBy("timestamp", "desc")
        .startAfter(lastVisibleDocListProduct.value)
        .limit(limitListProduct.value)
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocListProduct.value = data.docs[dataLength - 1];
            if (dataLength < limitListProduct.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.GET_LIST_MY_PRODUCT, arr);
        });
};

/**
 * get list sell post
 * @param commit
 * @param data
 */
const getListSellPost = async ({ commit, dispatch }, { postId, loading }) => {
    firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("sellPosts")
        .doc(postId)
        .collection("buyPosts")
        .orderBy("dateUpDate", "desc")
        .limit(limitSellPost.value)
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocSellPost.value = data.docs[dataLength - 1];
            if (dataLength < limitSellPost.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_SELL_POST, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_SELL_POST, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.GET_LIST_SELL_POST, arr);
            loading();
        });
};

// Load more sell post
const loadMoreSellPost = async ({ commit }, { postId }) => {
    firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("sellPosts")
        .doc(postId)
        .collection("buyPosts")
        .orderBy("dateUpDate", "desc")
        .startAfter(lastVisibleDocSellPost.value)
        .limit(limitSellPost.value)
        .get()
        .then((data) => {
            let dataLength = data.docs.length;
            lastVisibleDocSellPost.value = data.docs[dataLength - 1];
            if (dataLength < limitSellPost.value) {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_SELL_POST, false);
            } else {
                commit(MUTATIONS_TYPE.SET_CAN_LOAD_MORE_SELL_POST, true);
            }
            const arr = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            commit(MUTATIONS_TYPE.GET_LIST_SELL_POST, arr);
        });
};

/**
 * get detail product
 * @param commit
 * @param data
 */
const getDetailProduct = async ({ commit }, { postId, loading }) => {
    await firebaseStore
        .collection("sellPosts")
        .doc(postId)
        .get()
        .then((data) => {
            commit(MUTATIONS_TYPE.GET_DETAIL_PRODUCT, data.data());
            loading();
        })
        .catch((err) => console.log(err));
};

/**
 * set search in list product seller
 * @param commit
 * @param data
 */
const setSearchInListProductSeller = async (
    { commit },
    { searchKey, categoryType }
) => {
    commit(MUTATIONS_TYPE.SET_SEARCH_IN_LIST_PRODUCT_SELLER, {
        searchKey,
        categoryType,
    });
};

/**
 * destroy
 * @param commit
 * @param data
 */
const destroy = async ({ commit }) => {
    commit(MUTATIONS_TYPE.DESTROY);
};

export default {
    destroy,
    // get list product
    getListMyProduct,
    filterListProduct,
    loadMoreListProduct,
    setSearchInListProductSeller,
    // get sell post
    loadMoreSellPost,
    getListSellPost,
    getDetailProduct,
    // get buy post
    getListBuyPost,
    loadMoreBuyPost,
};
