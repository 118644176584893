import { client } from "@/typesense";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";
export default {
  namespaced: true,
  state() {
    return {
      searchResults: null,
      isActive: false,
    };
  },
  getters: {},
  mutations: {
    SET_MODAL_ACTIVE(state, status) {
      state.isActive = status;
    },
    SET_SEARCH_RESULTS(state, data) {
      state.searchResults = data;
    },
    RESET_STATE(state) {
      state.isActive = false;
      state.searchResults = null;
    },
  },
  actions: {
    async searchPages({ commit }, payload) {
      const searchParams = {
        q: payload,
        query_by:
          "companyName,pageName,businessNumber,companyAddress,categoryId",
      };
      let pages = [];
      try {
        const res = await client
          .collections("businessPage")
          .documents()
          .search(searchParams);
        pages = res["hits"].map((item) => item.document);
      } catch (error) {
        pages = [];
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
      }
      return pages;
    },
    async searchPosts({ commit }, payload) {
      const searchParams = {
        q: payload,
        query_by: "msgTextContent,msgName,channel",
        sort_by: "updateTime:desc",
      };
      let posts = [];
      try {
        const res = await client
          .collections("zalofeed")
          .documents()
          .search(searchParams);
        posts = res["hits"].map((item) => item.document);
      } catch (error) {
        posts = [];
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
      }
      return posts;
    },
    async searchData({ commit, dispatch }, payload) {
      let res = await Promise.all([
        dispatch("searchPosts", payload),
        dispatch("searchPages", payload),
      ]);
      commit("SET_SEARCH_RESULTS", res);
    },
  },
};
