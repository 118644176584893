import { firebaseAuth, firebaseStore } from "@/firebase/config";
import { handleError } from "@/composables/handle_error/HandleError";
import { sendUserRegisterData } from "@General/composables/API/PrivateAPI/sendUserActivities";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";
import { checkUserToken } from "@/composables/userToken/user-token";
import { checkApiKeySearch } from "@/typesense";

export default {
  namespaced: true,
  state() {
    return {
      userRef: null,
      postsSaved: null,
      userDecentralization: false,
      listIdPostsSaved: null,
      referralCode: null,
      interests: null,
      endPoint: false,
      isAccountTest: false,
    };
  },
  getters: {
    lastPostSaved: (state) => state.postsSaved[state.postsSaved.length - 1],
  },
  mutations: {
    SET_ACCOUNT_TEST(state, payload) {
      state.isAccountTest = payload;
    },
    SET_USER(state, _user) {
      state.userRef = _user;
    },
    REMOVE_CURRENT_USER(state) {
      state.userDecentralization =
        state.isAccountTest =
        state.isGettingUser =
          false;
      state.userRef =
        state.postsSaved =
        state.postsPending =
        state.postsOnSell =
        state.listIdPostsSaved =
        state.referralCode =
          null;
    },
    SET_USER_SETUP_STATUS(state, _setupProfile) {
      state.userRef.setupProfile = _setupProfile;
    },
    SET_REFERRAL_CODE(state, payload) {
      state.referCode = payload;
    },
    SET_POSTS_SAVED(state, listPostSaved) {
      state.postsSaved = listPostSaved;
    },
    SET_USER_DECENTRALIZATION(state, _decentralization) {
      state.userDecentralization = _decentralization;
    },
    SET_LIST_ID_POSTS_SAVED(state, listId) {
      state.listIdPostsSaved = listId;
    },
    SET_USER_INTEREST(state, payload) {
      state.interests = payload;
    },
    SET_MORE_POSTS_SAVED(state, posts) {
      state.postsSaved = [...state.postsSaved, ...posts];
    },
    SET_END_POINT(state, status) {
      state.endPoint = status;
    },
  },
  actions: {
    // sign up action
    async signUp({ commit }, { email, password, referCode, refSavePost }) {
      const response = await firebaseAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      if (response) {
        await firebaseStore
          .collection("refercodes")
          .doc(referCode ? referCode : "travelconnect")
          .collection("users")
          .doc(firebaseAuth.currentUser.uid)
          .set({ userId: firebaseAuth.currentUser.uid });
        await firebaseAuth.currentUser.sendEmailVerification();
        if (refSavePost) {
          await firebaseStore
            .collection("userRef")
            .doc(firebaseAuth.currentUser.uid)
            .collection("socialPostSaved")
            .add({
              collection: "zalofeed",
              id: refSavePost,
              savedTime: new Date().getTime(),
            });
        }
        // post api
        const registerData = {
          key: response.user.uid,
          email: email,
          registered_at: new Date().getTime(),
        };
        await sendUserRegisterData(registerData);
        commit("SET_REFERRAL_CODE", null);
      }
    },
    // sign in action
    async signIn({ dispatch }, { email, password }) {
      const res = await firebaseAuth.signInWithEmailAndPassword(
        email,
        password
      );
      if (res) {
        if (firebaseAuth.currentUser.emailVerified) {
          await dispatch("getUserProfileData", firebaseAuth.currentUser.uid);
          return true;
        } else {
          return false;
        }
      }
    },
    // update ad expiration
    async updateAdExpiration({ commit }) {
      try {
        await firebaseStore
          .collection("userRef")
          .doc(firebaseAuth.currentUser.uid)
          .update({ ad_active: new Date().getTime() });
        return true;
      } catch (error) {
        handleError(error.message);
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
        return false;
      }
    },
    // update user interests
    async updateUserInterests({ commit, state }) {
      const userDoc = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid);
      await userDoc.update({ interests: state.interests });
    },
    // sign out action
    async signOut() {
      await firebaseAuth.signOut();
    },
    // reset password
    async sendEmailResetPassword({ commit }, email) {
      try {
        await firebaseAuth.sendPasswordResetEmail(email);
        return true;
      } catch (error) {
        const line = getErrorLine();
        sendErrorToSlack("error", error, line);
        return false;
      }
    },
    // get user data
    async getUserProfileData({ commit, state, dispatch }, id) {
      const res = await firebaseStore.collection("userRef").doc(id).get();
      if (!res.exists) {
        return false;
      } else {
        const userPrefer = firebaseStore
          .collection("userRef")
          .doc(id)
          .collection("preferences");
        const [userPermission, userMembership, userContacts] =
          await Promise.all([
            userPrefer.doc("permission").get(),
            userPrefer.doc("membership").get(),
            userPrefer.doc("contacts").get(),
            dispatch("getIdPostsSaved"),
          ]);
        let companyInfo;
        const userMembershipData = userMembership.data();
        if (userMembershipData && userMembershipData.companyId) {
          companyInfo = await firebaseStore
            .collection("pages")
            .doc(userMembershipData.companyId)
            .get();
        }
        commit("SET_USER", {
          id: res.id,
          ...res.data(),
          ...userPermission.data(),
          ...userMembership.data(),
          ...userContacts.data(),
          ...(companyInfo?.data() ? companyInfo.data() : {}),
        });
        checkApiKeySearch(state.userRef.apiKeySearch);
      }
    },
    // update user profile
    async updateUserProfile({ commit, dispatch }, dataUpdate) {
      const userDoc = firebaseStore.collection("userRef").doc(dataUpdate.id);
      if (dataUpdate.data.phoneNumber) {
        await userDoc
          .collection("preferences")
          .doc("contacts")
          .update({ phone: dataUpdate.data.phoneNumber });
        delete dataUpdate.data.phoneNumber;
      }
      await userDoc.update(dataUpdate.data);
      await dispatch("updateUserInterests");
      // post api
      const registerData = {
        key: dataUpdate.id,
        verified_at: new Date().getTime(),
      };
      await sendUserRegisterData(registerData);
      commit("SET_USER_SETUP_STATUS", dataUpdate.data.setupProfile);
    },
    async updateUserInfo({ commit, dispatch }, dataUpdate) {
      const userDoc = firebaseStore.collection("userRef").doc(dataUpdate.id);
      if (dataUpdate.data.phoneNumber) {
        await userDoc
          .collection("preferences")
          .doc("contacts")
          .update({ phone: dataUpdate.data.phoneNumber });
        dispatch("getUserProfileData", dataUpdate.id);
        delete dataUpdate.data.phoneNumber;
      }
      await userDoc.update(dataUpdate.data);
    },
    // check account type
    async checkUserDecentralization({ commit }) {
      let userDecentralization = null;
      const userCollection = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("preferences");

      let res = await userCollection.doc("membership").get();
      if (!res.exists) {
        res = await userCollection.doc("account").get();
      }
      userDecentralization = res.data().verified;
      if (userDecentralization !== null) {
        commit("SET_USER_DECENTRALIZATION", userDecentralization);
      }
    },
    // update user avatar
    async updateAvatar({ commit }, avatarURL) {
      const userDoc = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid);
      await userDoc.update({ photoUrl: avatarURL });
    },
    async getPostsData({ commit }, collection) {
      let myListPostSaved = [];
      const res = await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection(collection)
        .orderBy("savedTime", "desc")
        .limit(10)
        .get();
      if (res.size > 0) {
        myListPostSaved = [
          ...res.docs.map((doc, index) => {
            return {
              docId: doc.id,
              ...doc.data(),
            };
          }),
        ];
      }
      return myListPostSaved;
    },
    // get posts saved
    async getPostsSaved({ commit, dispatch }) {
      await checkUserToken();
      let res = await dispatch("getPostsData", "socialPostSaved");
      let posts = [];
      if (res.length > 0) {
        posts = await Promise.all(
          res.map(async (doc) => {
            const resDoc = await firebaseStore
              .collection(doc.collection)
              .doc(doc.id)
              .get();

            if (resDoc.exists) {
              let postContacts;
              if (doc.collection === "zalofeed") {
                postContacts = await firebaseStore
                  .collection(doc.collection)
                  .doc(doc.id)
                  .collection("preferences")
                  .doc("contacts")
                  .get();
              }
              return {
                docId: doc.docId,
                savedTime: doc.savedTime,
                id: doc.id,
                ...resDoc.data(),
                ...(postContacts ? postContacts.data() : []),
              };
            }
          })
        );
        if (posts.length > 0) {
          commit("SET_POSTS_SAVED", posts);
        }
      }
    },
    // load more posts saved
    async loadMorePostsSaved({ commit, getters }) {
      await checkUserToken();
      let lastDocSnapshot = getters.lastPostSaved;
      let myListPostSaved = [];
      let posts = [];
      const res = await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("socialPostSaved")
        .orderBy("savedTime", "desc")
        .startAfter(lastDocSnapshot.savedTime)
        .limit(5)
        .get();
      if (res.size) {
        myListPostSaved = [
          ...res.docs.map((doc, index) => {
            return {
              docId: doc.id,
              ...doc.data(),
            };
          }),
        ];
        posts = await Promise.all(
          myListPostSaved.map(async (doc) => {
            const resDoc = await firebaseStore
              .collection(doc.collection)
              .doc(doc.id)
              .get();

            if (resDoc.exists) {
              let postContacts;
              if (doc.collection === "zalofeed") {
                postContacts = await firebaseStore
                  .collection(doc.collection)
                  .doc(doc.id)
                  .collection("preferences")
                  .doc("contacts")
                  .get();
              }
              return {
                docId: doc.docId,
                savedTime: doc.savedTime,
                id: doc.id,
                ...resDoc.data(),
                ...(postContacts ? postContacts.data() : []),
              };
            }
          })
        );
        commit("SET_MORE_POSTS_SAVED", posts);
      } else {
        commit("SET_END_POINT", true);
      }
    },
    // save post action
    async savePost({ commit, dispatch }, post) {
      await checkUserToken();
      const response = firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("socialPostSaved");

      let dataPost = await dispatch("getPostsData", "socialPostSaved");
      if (!dataPost.map((doc) => doc.id).includes(post.id)) {
        await response.add({ ...post, savedTime: new Date().getTime() });
        await dispatch("getIdPostsSaved");
      }
    },
    // remove post saved
    async removePostSaved({ commit, dispatch }, { id, collection, mutation }) {
      await checkUserToken();
      await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("socialPostSaved")
        .doc(id)
        .delete();
      await dispatch("setPostsData", {
        collection: collection,
        mutation: mutation,
      });
      await dispatch("getIdPostsSaved");
    },
    // get list id posts saved
    async getIdPostsSaved({ commit }) {
      await checkUserToken();
      let listId = [];
      const res = await firebaseStore
        .collection("userRef")
        .doc(firebaseAuth.currentUser.uid)
        .collection("socialPostSaved")
        .get();
      if (res) {
        listId = [
          ...res.docs.map((doc) => {
            return {
              docId: doc.id,
              ...doc.data(),
            };
          }),
        ];
        commit("SET_LIST_ID_POSTS_SAVED", listId);
      }
    },
  },
};
