<template>
  <custom-modal
    :useSlot="true"
    v-if="isNewTabOpened || isShowTimeOutModal || isShowAdBlockDetected"
  >
    <new-tab-open v-if="isNewTabOpened" />
    <modal-time-out
      @closeTimeOutModal="closeTimeOutModal"
      v-if="isShowTimeOutModal"
    />
    <ad-block-detected
      v-if="isShowAdBlockDetected"
      @closeModal="isShowAdBlockDetected = false"
    />
  </custom-modal>
  <router-view @toggleSidebar="toggleSidebar" v-else />
  <transition name="slide-top" appear mode="out-in">
    <back-to-top v-if="isShowBackToTop" />
  </transition>
  <LiveChatWidget
    v-if="user"
    license="15547296"
    visibility="minimized"
    :customerName="user.displayName"
    :customerEmail="user.email"
  />
</template>
<script>
import { ref, onMounted, onBeforeMount, watch, computed } from "vue";
import CustomModal from "@/components/custom/modal/CustomModal.vue";
import NewTabOpen from "@/components/custom/modal/NewTabOpen/NewTabOpen.vue";
import ModalTimeOut from "@/components/custom/modal/ModalTimeOut/ModalTimeOut.vue";
import AdBlockDetected from "@/components/custom/modal/AdBlock/AdBlockDetected.vue";
import { v4 as uuidv4 } from "uuid";
import { activityWatcher } from "@/composables/helper/useInteraction";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-vue";
import BackToTop from "@/components/custom/custom/BackToTop.vue";
import { user } from "@/composables/helper/useStore";

export default {
  name: "App",
  mounted() {},
  components: {
    CustomModal,
    NewTabOpen,
    ModalTimeOut,
    BackToTop,
    AdBlockDetected,
    LiveChatWidget,
  },
  setup() {
    const isShowBackToTop = ref(false);
    const isNewTabOpened = ref(false);
    const isShowTimeOutModal = ref(false);
    const isShowAdBlockDetected = ref(false);
    const pageId = ref(null);
    const route = useRoute();
    const store = useStore();
    const sidebarType = computed(() => store.getters["setting/sidebar_type"]);
    const routeList = ["social.list", "manage.intro"];
    const closeTimeOutModal = () => {
      isShowTimeOutModal.value = false;
    };

    // [STYLE HUBSPOT]
    const styleHubSpot = (isOpenSidebar) => {
      const hubSpotWidget = document.querySelector(".widget-align-right");
      if (hubSpotWidget) {
        const hubSpotStyle = {
          width: hubSpotWidget.offsetWidth,
          height: hubSpotWidget.offsetHeight,
          z_index: 1112,
        };
        let { width, height, z_index } = hubSpotStyle;
        isOpenSidebar ? (z_index = 98) : (z_index = 1112);
        hubSpotWidget.style = `width : ${width}px!important;height : ${height}px!important; z-index : ${z_index}!important;`;
      }
    };

    onBeforeMount(() => {
      if (!window.location.pathname.includes("public")) {
        pageId.value = uuidv4();
        localStorage.setItem("pageId", JSON.stringify(pageId.value));
      }
    });

    onMounted(() => {
      // [ DETECT AD BLOCK ]
      let fakeAd = document.createElement("div");
      fakeAd.className =
        "textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox";

      fakeAd.style.height = "1px";

      document.body.appendChild(fakeAd);

      let x_width = fakeAd.offsetHeight;

      if (!x_width) {
        isShowAdBlockDetected.value = true;
      }

      // [ TOGGLE SHOW/HIDE BACK TO TOP ]
      window.addEventListener("scroll", () => {
        if (
          (document.body.scrollTop > 300 ||
            document.documentElement.scrollTop > 300) &&
          !route.name.includes("auth")
        ) {
          isShowBackToTop.value = true;
          styleHubSpot();
        } else {
          isShowBackToTop.value = false;
          styleHubSpot();
        }
      });

      // [ MAKE USER AUTO LOGOUT IF USER DIDN'T INTERACT  WITH WEBSITE ]
      if (!window.location.pathname.includes("public")) {
        activityWatcher(isShowTimeOutModal);
        window.onstorage = () => {
          const newPageId = JSON.parse(window.localStorage.getItem("pageId"));
          if (newPageId !== pageId.value) {
            isNewTabOpened.value = true;
            store.commit("auth/REMOVE_CURRENT_USER");
            store.commit("snapshots/REMOVE_SNAPSHOT_LISTENER");
          }
        };
      }
    });

    // watch route
    watch(route, (to, from) => {
      if (window.innerWidth <= 991 && !routeList.includes(route.name)) {
        const overlay = document.querySelector(".main-content .overlay");
        // Code Here
        if (!sidebarType.value.includes("sidebar-mini")) {
          store.dispatch("setting/sidebar_type", [
            ...sidebarType.value,
            "sidebar-mini",
          ]);
          if (overlay) {
            overlay.classList.remove("d-block");
            document.body.classList.remove("no-scrolling");
            styleHubSpot(false);
          }
        }
      }
    });
    const toggleSidebar = () => {
      const overlay = document.querySelector(".main-content .overlay");

      // Code Here
      if (sidebarType.value.includes("sidebar-mini")) {
        if (window.innerWidth <= 991) {
          document.body.classList.add("no-scrolling");
        }
        store.dispatch(
          "setting/sidebar_type",
          sidebarType.value.filter((item) => item !== "sidebar-mini")
        );
        if (overlay) {
          overlay.classList.add("d-block");
          styleHubSpot(true);
        }
      } else {
        if (window.innerWidth <= 991) {
          document.body.classList.remove("no-scrolling");
        }
        store.dispatch("setting/sidebar_type", [
          ...sidebarType.value,
          "sidebar-mini",
        ]);
        if (overlay) {
          overlay.classList.remove("d-block");
          styleHubSpot(false);
        }
      }
    };

    return {
      isNewTabOpened,
      isShowTimeOutModal,
      closeTimeOutModal,
      toggleSidebar,
      isShowBackToTop,
      isShowAdBlockDetected,
      user,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/socialv.scss";
@import "./assets/scss/customizer.scss";
@import url("./plugins/Leaflet/leaflet.css");
</style>
