const DEFAULT_CONTACT = "default_contact";
const PLACE_ID = "place_id";
const AD_EXPIRATION_TIME = 7;
const HIDDEN_POST_EXPIRATION_DAY = 30;
const AD_POST_SNAPSHOT_KEY = "market_post_snapshot";
const MENU_FEED = "menu_feed";
const GOOGLE_MAP_KEY = "AIzaSyDamK4_mP68TJzhL9qvUXuZdL_XzDboV1w";
const PAGE_OPTION = "page";
const POST_OPTION = "post";
const RESIZE_IMAGE_TIME = "4/20/2023";

const defaultData = {
  [DEFAULT_CONTACT]: {
    email: "namhoang@travelconnect.vn",
    phoneNumber: "0934597555",
  },
  [PLACE_ID]: {
    vnCode: "ChIJXx5qc016FTERvmL-4smwO7A",
  },
  [AD_POST_SNAPSHOT_KEY]: {
    limit: 1,
    key: AD_POST_SNAPSHOT_KEY,
    // timeout: 2 * 60 * 60 * 1000,
    timeout: 2 * 1000,
  },
  // 0 for personal , 1 for leftsidebar
  [MENU_FEED]: [
    {
      name: "Cá nhân",
      icon: "far fa-user",
      sidebar: "personal-sidebar",
      route: {
        name: "social.personal",
        params: {},
      },
    },
    // {
    //   name: "Công ty",
    //   icon: "far fa-building",
    //   sidebar: "business-sidebar",
    // },
    {
      name: "Kênh phân phối",
      icon: "fas fa-users",
      sidebar: "agency-sidebar",
      route: {
        name: "social.agency-feed",
        params: {},
      },
    },
    {
      name: "Tin tức",
      icon: "fas fa-globe-asia",
      sidebar: "news-sidebar",
      route: {
        name: "social.news",
        params: {},
      },
    },
    // {
    //   name: "Blacklist",
    //   icon: "fas fa-ban",
    //   sidebar: "black-list-sidebar",
    // },
  ],
};

export {
  defaultData,
  DEFAULT_CONTACT,
  PLACE_ID,
  AD_EXPIRATION_TIME,
  AD_POST_SNAPSHOT_KEY,
  MENU_FEED,
  GOOGLE_MAP_KEY,
  PAGE_OPTION,
  POST_OPTION,
  HIDDEN_POST_EXPIRATION_DAY,
  RESIZE_IMAGE_TIME,
};
