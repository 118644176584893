import * as MUTATIONS_TYPE from "./types";

/**
 * get list buy post
 * @param state
 */
const getListBuyPost = (state, data) => {
    state.listBuyPost.push(...data);
};

/**
 * get list sell post
 * @param state
 */
const getListSellPost = (state, data) => {
    state.listSellPost.push(...data);
};

/**
 * get list my product
 * @param state
 */
const getListMyProduct = (state, data) => {
    state.listMyProduct.push(...data);
};

/**
 * filter list my product
 * @param state
 */
const filterListProduct = (state, data) => {
    state.listMyProduct = data;
};

/**
 * get detail product
 * @param state
 */
const getDetailProduct = (state, data) => {
    state.detailProduct = data;
};

/**
 * set search in list product seller
 * @param state
 */
const setSearchInListProductSeller = (state, { searchKey, categoryType }) => {
    state.searchInListProductSeller.searchKey = searchKey;
    state.searchInListProductSeller.categoryType = categoryType;
};

/**
 * set can load more list product
 * @param state
 */
const setCanLoadMoreListProduct = (state, payload) => {
    state.canLoadMoreListProduct = payload;
};

/**
 * set can load more sell post
 * @param state
 */
const setCanLoadMoreSellPost = (state, payload) => {
    state.canLoadMoreSellPost = payload;
};

/**
 * set can load more buy post
 * @param state
 */
const setCanLoadMoreBuyPost = (state, payload) => {
    state.canLoadMoreBuyPost = payload;
};

/**
 * destroy
 * @param state
 */
const destroy = (state) => {
    state.listMyProduct = [];
    state.listSellPost = [];
    state.listBuyPost = [];
};

export default {
    [MUTATIONS_TYPE.DESTROY]: destroy,
    // get list product
    [MUTATIONS_TYPE.GET_LIST_MY_PRODUCT]: getListMyProduct,
    [MUTATIONS_TYPE.FILTER_LIST_MY_PRODUCT]: filterListProduct,
    [MUTATIONS_TYPE.SET_CAN_LOAD_MORE_LIST_PRODUCT]: setCanLoadMoreListProduct,
    [MUTATIONS_TYPE.SET_SEARCH_IN_LIST_PRODUCT_SELLER]:
        setSearchInListProductSeller,
    // get sell post
    [MUTATIONS_TYPE.GET_LIST_SELL_POST]: getListSellPost,
    [MUTATIONS_TYPE.GET_DETAIL_PRODUCT]: getDetailProduct,
    [MUTATIONS_TYPE.SET_CAN_LOAD_MORE_SELL_POST]: setCanLoadMoreSellPost,
    // get buy post
    [MUTATIONS_TYPE.GET_LIST_BUY_POST]: getListBuyPost,
    [MUTATIONS_TYPE.SET_CAN_LOAD_MORE_BUY_POST]: setCanLoadMoreBuyPost,
};
