import {
  STATUS_ERR
} from "@General/constants/ReqStatuses"

export default {
  SET_ADS_POST(state, data) {
    data.data.id = data.id
    state.ads_post.data = data.data;
  },
  SET_PROCESS(state, data = STATUS_ERR) {
    state.ads_post.status = data;
  },
  FLUSH(state) {
    state.ads_post = defaultSocialPost;
  },
};
