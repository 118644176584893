import {
  createApp
} from "vue";
import Vuex from "vuex";
import setting from "./setting/index";
import appearance_settings from "./appearance_settings/index";
import profile_management from "./profile_management/profile_management";
import auth from "./auth/auth";
import marketSocial from "./social/market";
import personalSocial from "./social/personal";
import public_share from "./public_share/public";
import business from "./business/business";
import recommend from "./business/recommend";
import business_post from "@/app_modules/General/store/business/business_post";
import snapshots from "./snapshots/snapshots";
import calculator_score_market from "./social/calculator_score_market";
import user_activities from "./user_activities/user_activities";
import upload_progress from "./upload_progress/upload_progress";
import search_data from "./search_data/search_data";
import e3 from "./e3/e3";
import {
  ads_posts
} from "@General/store";

import {
  request_customer,
  b2b_create_sell_post,
  b2b_accommodation,
  b2b_market,
  my_products,
  b2b_group_tour,
  b2b_land_tour,
  b2b_series_ticket,
} from "@B2B/store/index";
import feedConfig from "./configs/feed-config";

createApp(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    ads_posts,
    setting,
    profile_management,
    auth,
    marketSocial,
    personalSocial,
    appearance_settings,
    public_share,
    business,
    business_post,
    recommend,
    snapshots,
    calculator_score_market,
    user_activities,
    upload_progress,
    search_data,
    e3,
    request_customer,
    b2b_create_sell_post,
    b2b_accommodation,
    b2b_market,
    my_products,
    b2b_group_tour,
    b2b_land_tour,
    b2b_series_ticket,
    feedConfig,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug,
});
