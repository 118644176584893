import axios from "axios";
import { sendErrorToSlack, getErrorLine } from "@/composables/helper/useSlack";
const apiUrl = process.env.VUE_APP_URL_BACKEND + "/posts/verification_request";

const sendVerificationRequest = async (post) => {
  await axios.post(apiUrl, post, {
    headers: {
      "content-type": "application/json",
    },
  });
};

const apiSuggestionBoxUrl = process.env.VUE_APP_URL_BACKEND + "/suggestion_box";

const sendSuggestionBox = async (suggestion) => {
  try {
    const res = await axios.post(apiSuggestionBoxUrl, suggestion, {
      headers: {
        "content-type": "application/json",
      },
    });

    return res;
  } catch (error) {
    const line = getErrorLine();
    sendErrorToSlack("error", error, line);
  }
};

export { sendVerificationRequest, sendSuggestionBox };
